import React from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ContactForm = () => {
    const navigate = useNavigate();

    const handleSubmit = async (values, { resetForm, setSubmitting, setErrors }) => {
        try {
            const response = await axios.post('http://127.0.0.1:5000/registercustomers', values);

            if (response.status === 201) {
                alert("Customer Registered Successfully");
                resetForm(); // Clear the form fields after successful submission
                navigate("/contact"); // Navigate to customer listing
            } else {
                throw new Error("Something went wrong");
            }
        } catch (error) {
            console.error("Error response:", error.response);
            console.error("Request data:", values);

            // Handle specific database errors if available
            const errorMessage = error.response?.data?.message || "Database connection error. Please try again later.";
            alert(errorMessage); // Show user-friendly error
            setErrors({ submit: errorMessage });
        } finally {
            setSubmitting(false); // Allow the form to be submitted again
        }
    };

    return (
        <div className="right">
            <Formik
                initialValues={{
                    Name: "",
                    Email: "",
                    PhoneNumber: "",
                    Country: "India",
                    Quary: "",
                    Msg: ""
                }}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors }) => (
                    <Form className="contact-form">
                        <h2>Fill the Form</h2>
                        <div className="details">
                            <div className="form-group">
                                <label className="form-label">Name <span className="text-danger"> *</span></label>
                                <Field name="Name" type="text" placeholder="Name" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label className="form-label">Email <span className="text-danger"> *</span></label>
                                <Field name="Email" type="email" placeholder="Email" className="form-control" />
                            </div>
                        </div>
                        <div className="details mt-3">
                            <div className="form-group">
                                <label className="form-label">Phone Number <span className="text-danger"> *</span></label>
                                <Field name="PhoneNumber" type="text" placeholder="Enter Contact No." className="form-control" />
                            </div>
                            <div className="form-group">
                                <label className="form-label">Country <span className="text-danger"> *</span></label>
                                <input className="form-control" type="text" value="India" readOnly />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Why are you contacting us? <span className="text-danger"> *</span></label>
                            <Field as="select" name="Quary" className="form-select">
                                <option value="">Select an option</option>
                                <option value="Sales Query">Sales Query</option>
                                <option value="Order Related">Order Related</option>
                                <option value="Payment">Payment</option>
                                <option value="Feedback">Feedback</option>
                                <option value="Job">Job</option>
                                <option value="Other">Other</option>
                            </Field>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Message</label>
                            <Field as="textarea" name="Msg" className="form-control" rows="6" />
                        </div>

                        {errors.submit && <div className="error-message">{errors.submit}</div>}

                        <button type="submit" className="btn btn-success" disabled={isSubmitting}>
                            {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ContactForm;
