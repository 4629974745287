
import React, { useEffect } from 'react';
// import './blog.css';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import topImage from '/mnt/'; // Add this line to import the image
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

export function Blog() {

    
    const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is coming from the homepage
    const fromHome = sessionStorage.getItem('fromHome');

    if (fromHome) {
      // If they refreshed, redirect to the homepage
      navigate('/');
    } else {
      // Set a flag in sessionStorage to indicate the user has visited the product page
      sessionStorage.setItem('fromHome', 'true');
    }

    // Cleanup: Remove the flag when the component unmounts
    return () => {
      sessionStorage.removeItem('fromHome');
    };
  }, [navigate]);


    const isSmall = useMediaQuery({ query: '(max-width: 993px)' });
    const isXXLarge = useMediaQuery({ query: '(min-width: 994px)' });

    return (
        <div>
            <Helmet>
                <title>STEM WORLD | Blog</title>
            </Helmet>



            {isSmall && (
                <div>
                    <h1 className="text-center">Stem World Blog</h1>
                    <div className="m-3" style={{ textAlign: "justify" }}>
                        Discover the latest in STEM education through StemWorld's blog, where we explore innovative approaches and practical insights. Dive into topics that empower schools with STEM products and labs, helping educators enhance learning experiences and prepare students for future challenges.
                    </div>

                    <div className="card m-5" style={{ width: "18rem" }}>
                        <img src="samblabs.png" className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">How to Teach Robotics and AI Curriculum in ICSE Schools?</h5>
                            <p className="card-text" style={{ textAlign: "justify" }}>When hearing the term inquiry-based learning, many educators are apt to imagine the concept revolves around asking the student what they want to learn. While that can be part of inquiry-based learning, it’s really much more than that.</p>
                            <Link to="/samlabs">
                                <a href="#" className="btn btn-primary">Read more</a>
                            </Link>

                        </div>
                    </div>
                </div>
            )}

            {isXXLarge && (
                <div className="container">
                    <h1 className="text-center text-md-left">Stem World Blog</h1>
                    <div>
                        Discover the latest in STEM education through StemWorld's blog, where we explore innovative approaches and practical insights. Dive into topics that empower schools with STEM products and labs, helping educators enhance learning experiences and prepare students for future challenges.
                    </div>

                    <div className="card m-5" style={{ width: "18rem" }}>
                        <img src="samblabs.png" className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">How to Teach Robotics and AI Curriculum in ICSE Schools?</h5>
                            <p className="card-text" style={{ textAlign: "justify" }}>When hearing the term inquiry-based learning, many educators are apt to imagine the concept revolves around asking the student what they want to learn. While that can be part of inquiry-based learning, it’s really much more than that.</p>
                            <Link to="/samlabs">
                                <a href="#" className="btn btn-primary">Read more</a>
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            {/* <NewHeader /> */}

            {/* Top Image Section */}
            {/* <div className="top-image-container">
                <img src='https://digifloat.io/wp-content/uploads/2024/06/Free-Images-for-Blogs.jpg' alt="Top Banner" style={{ height: '422px', width: '100%' }} />
                <div className="top-image-caption">
                    <h2>The Best in Our STEM WORLD!</h2>
                    <p>Our Courses!</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eu dui non diam eleifend egestas.</p>
                    <Link
                        className="read-more-button">
                        Read more
                    </Link>
                </div>
            </div> */}




            <div>


                {/* <div className="card w-25 h-25 m-4">
                    <Link to="/samlabs">
                        <img src="samblabs.png" className="card-img-top h-25" alt="Card 2" />
                        <div className="card-header">
                            <h5>How to Teach Robotics and AI Curriculum in ICSE Schools?</h5>
                        </div>
                        <div className="card-body">
                            When hearing the term inquiry-based learning, many educators are apt to imagine the concept revolves around asking the student what they want to learn. While that can be part of inquiry-based learning, it’s really much more than that.
                        </div>
                        <div className="card-footer text-center">
                            Read more
                        </div>
                    </Link>
                </div> */}


                {/* <div className="card w-25 h-25 m-4">
                    <img src="card-1.png" className="card-img-top h-25" alt="Card 1" />
                    <div className="card-header">
                        <h5>How to Teach Robotics and AI Curriculum in ICSE Schools?</h5>
                    </div>
                    <div className="card-body">
                        New technologies are impacting consumer consumption and driving technological innovations in digital media, so if you want to remain relevant you must become comfortable with…
                    </div>
                    <div className="card-footer text-center">
                        Read more
                    </div>
                </div>


                <div className="card w-25 h-25 m-4">
                    <img src="card-3.png" className="card-img-top h-25" alt="Card 1" />
                    <div className="card-header">
                        <h5>How to Teach Robotics and AI Curriculum in ICSE Schools?</h5>
                    </div>
                    <div className="card-body">
                        New technologies are impacting consumer consumption and driving technological innovations in digital media, so if you want to remain relevant you must become comfortable with…
                    </div>
                    <div className="card-footer text-center">
                        Read more
                    </div>
                </div>


                <div className="card w-25 h-25 m-4">
                    <img src="card-4.png" className="card-img-top h-25" alt="Card 1" />
                    <div className="card-header">
                        <h5>How to Teach Robotics and AI Curriculum in ICSE Schools?</h5>
                    </div>
                    <div className="card-body">
                        New technologies are impacting consumer consumption and driving technological innovations in digital media, so if you want to remain relevant you must become comfortable with…
                    </div>
                    <div className="card-footer text-center">
                        Read more
                    </div>
                </div>


                <div className="card w-25 h-25 m-4">
                    <img src="card-5.jpg" className="card-img-top h-25" alt="Card 1" />
                    <div className="card-header">
                        <h5>How to Teach Robotics and AI Curriculum in ICSE Schools?</h5>
                    </div>
                    <div className="card-body">
                        New technologies are impacting consumer consumption and driving technological innovations in digital media, so if you want to remain relevant you must become comfortable with…
                    </div>
                    <div className="card-footer text-center">
                        Read more
                    </div>
                </div>


                <div className="card w-25 h-25 m-4">
                    <img src="card-6.jpg" className="card-img-top h-25" alt="Card 1" />
                    <div className="card-header">
                        <h5>How to Teach Robotics and AI Curriculum in ICSE Schools?</h5>
                    </div>
                    <div className="card-body">
                        New technologies are impacting consumer consumption and driving technological innovations in digital media, so if you want to remain relevant you must become comfortable with…
                    </div>
                    <div className="card-footer text-center">
                        Read more
                    </div>
                </div> */}



                {/* Repeat for other cards... */}
            </div>
        </div>
    );
}