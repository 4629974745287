import React from 'react'
import Header_ixl from '../Header_IXL/header_ixl'
import '../Class-1/class-1.css';

function Class10() {
    return (
        <div className='class_1'>
            <div className="container">

                <Header_ixl />

                <div className="main-content">
                    <div className="left-content">
                        <h3>India</h3>
                        <h4>Skills available for India class X maths Curriculum</h4>
                        <div className="description">
                            Objectives are in black and STEM WORLD maths skills are in dark green. Hold your mouse over the name of a skill to view a sample question. Click on the name of a skill to practise that skill.</div>
                    </div>

                    <div className="right-content">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
                            alt="India Flag"
                            className="india-flag"
                        />
                    </div>
                </div>

                <div style={{ display: "flex", width: "100%", marginTop: "20px", padding: "15px" }}>
                    <div style={{ width: "75%", background: "#f65314", padding: "20px" }}>
                        <h6>Showing alignment for: CISCE Class X</h6>
                        <p>National Council of Education Research and Training Syllabus :Class X</p>
                    </div>
                    <div style={{ width: "25%", background: "gray", padding: "20px" }}>
                        <h6>Actions</h6>
                        <p>Print curriculum</p>
                    </div>
                </div>
            </div>

            <div style={{ marginLeft: "110px" }}>
                <h3 style={{ color: "red" }}>10.I Number Systems</h3>
                <ul style={{ listStyle: "none" }}>
                    <li><h6>10.I.1 Real Numbers</h6>
                        <ul style={{ listStyle: "none" }}>
                            <li><h6>10.I.1.1 Euclid's division lemma, Fundamental Theorem of Arithmetic – statements after reviewing work done earlier and after illustrating and motivating through examples. Proofs of results – irrationality of √2, √3, √5, decimal expansions of rational numbers in terms of terminating/non-terminating recurring decimals.</h6>
                            </li>
                        </ul>
                    </li>





                    <h3 style={{ color: "red" }}>10.II Algebra</h3>

                    <h6>10.II.1 Polynomials</h6>
                    <li><h6>10.II.1.1 Zeros of a polynomial. Relationship between zeros and coefficients of a polynomial with particular reference to quadratic polynomials. Statement and simple problems on division algorithm for polynomials with real coefficients.</h6>
                        <ul style={{ color: "green" }}>
                            <li>Solve a quadratic equation using square roots (XI-I.6)</li>
                            <li>Write a polynomial from its roots (XI-J.8)</li>
                            <li>Find the roots of factorised polynomials (XI-J.9)</li>
                        </ul>
                    </li>

                    <h6>10.II.2 Pair of Linear Equations in Two Variables</h6>
                    <li><h6>10.II.2.1 Pair of linear equations in two variables. Geometric representation of different possibilities of solutions/inconsistency.</h6>

                        <ul style={{ color: "green" }}>
                            <li>Solve a pair of equations by graphing (X-E.2)</li>
                            <li>Solve a pair of equations by graphing: word problems (X-E.3)</li>
                            <li>Find the number of solutions to a pair of equations by graphing (X-E.4)</li>
                        </ul>


                        <li><h6>10.II.2.2 Algebraic conditions for number of solutions. Solution of pair of linear equations in two variables algebraically – by substitution, by elimination and by cross multiplication. Simple situational problems must be included. Simple problems on equations reducible to linear equations may be included.</h6>
                            <ul style={{ color: "green" }}>
                                <li>Is (x, y) a solution to the pair of equations? (X-E.1)</li>
                                <li>Solve a pair of equations by graphing: word problems (X-E.3)</li>
                                <li>Find the number of solutions to a pair of equations (X-E.5)</li>
                                <li>Solve a pair of equations using substitution (X-E.6)</li>
                                <li>Solve a pair of equations using substitution: word problems (X-E.7)</li>
                                <li>Solve a pair of equations using elimination (X-E.8)</li>
                                <li>Solve a pair of equations using elimination: word problems (X-E.9)</li>
                            </ul>
                        </li>

                        <h6>10.II.3 Quadratic Equations</h6>
                        <li><h6>10.II.3.1 Standard form of a quadratic equation ax² + bx + c = 0, (a ≠ 0). Solution of quadratic equations (only real roots) by factorization and by completing the square, i.e., by using quadratic formula. Relationship between discriminant and nature of roots.</h6>
                            <ul style={{ color: "green" }}>
                                <li>Characteristics of quadratic equations (X-J.1)</li>
                                <li>Complete a table: quadratic equations (X-J.2)</li>
                                <li>Solve a quadratic equation using square roots (X-J.3)</li>
                                <li>Solve a quadratic equation using the zero product property (X-J.4)</li>
                                <li>Solve a quadratic equation by factorising (X-J.5)</li>
                                <li>Complete the square (X-J.6)</li>
                                <li>Solve a quadratic equation by completing the square (X-J.7)</li>
                                <li>Solve a quadratic equation using the quadratic formula (X-J.8)</li>
                                <li>Graph a quadratic equation (X-J.10)</li>
                            </ul>
                            <h6>10.II.3.2 Problems related to day-to-day activities to be incorporated.</h6>
                        </li>

                        <h6>10.II.4 Arithmetic Progressions (AP)</h6>
                        <li><h6>10.II.4.1 Motivation for studying AP. Derivation of standard results of finding the nth term and sum of first n terms.</h6>
                            <ul style={{ color: "green" }}>
                                <li>Arithmetic sequences (X-G.1)</li>
                                <li>Evaluate variable expressions for arithmetic sequences (X-G.2)</li>
                                <li>Write variable expressions for arithmetic sequences (X-G.3)</li>
                            </ul>
                        </li>


                        <h3 style={{ color: "red" }}>10.III Trigonometry</h3>

                        <h6>10.III.1 Introduction to Trigonometry</h6>
                        <li><h6>10.III.1.1 Trigonometric ratios of an acute angle of a right-angled triangle. Proof of their existence (well defined); motivate the ratios, whichever are defined at 0° and 90°. Values (with proofs) of the trigonometric ratios of 30°, 45° and 60°. Relationships between the ratios.</h6>
                            <ul style={{ color: "green" }}>
                                <li>Trigonometric ratios: sin, cos and tan (X-S.1)</li>
                                <li>Trigonometric ratios: csc, sec and cot (X-S.2)</li>
                                <li>Find trigonometric functions of special angles (X-S.4)</li>
                                <li>Trigonometric ratios: find a side length (X-S.7)</li>
                                <li>Trigonometric ratios: find an angle measure (X-S.8)</li>
                                <li>Solve a right triangle (X-S.9)</li>
                            </ul>
                        </li>



                        <li><h6>10.III.1.2 Trigonometric Identities: Proof and applications of the identity sin² A + cos² A = 1. Only simple identities to be given. Trigonometric ratios of complementary angles.</h6>
                            <ul style={{ color: "green" }}>
                                <li>Trigonometric functions of complementary angles (X-S.3)</li>
                                <li>Find trigonometric ratios using the unit circle (XI-S.6)</li>

                            </ul>
                        </li>

                        <h6>10.III.2 Heights and Distances</h6>
                        <li><h6>10.III.2.1 Simple and believable problems on heights and distances. Problems should not involve more than two right triangles. Angles of elevation/depression should be only 30°, 45°, 60°.</h6>
                            <ul style={{ color: "green" }}>
                                <li>Find trigonometric functions of special angles (X-S.4)</li>

                            </ul>
                        </li>



                        <h3 style={{ color: "red" }}>10.IV Coordinate Geometry</h3>

                        <h6>10.IV.1 Lines (In two-dimensions)</h6>
                        <li><h6>10.IV.1.1 Review the concepts of coordinate geometry done earlier including graphs of linear equations. Awareness of geometrical representation of quadratic polynomials. Distance between two points and section formula (internal). Area of a triangle.</h6>
                            <ul style={{ color: "green" }}>
                                <li>Find the slope of a graph (X-D.2)</li>
                                <li>Find the slope from two points (X-D.3)</li>
                                <li>Slope-intercept form: graph an equation (X-D.6)</li>
                                <li>Standard form: graph an equation (X-D.15)</li>
                                <li>Graph a quadratic equation (X-J.10)</li>
                                <li>Area of triangles and quadrilaterals (X-M.3)</li>
                                <li>Area and perimeter in the coordinate plane I (X-M.4) </li>
                                <li>Area and perimeter in the coordinate plane II (X-M.5)</li>
                                <li>Write equations of parabolas in vertex form from graphs (XI-K.5)</li>
                            </ul>
                        </li>




                        <h3 style={{ color: "red" }}>10.V Geometry</h3>
                        <h6>10.V.1 Definitions, examples, counterexamples of similar triangles.</h6>
                        <li><h6>10.V.1.1 If a line is drawn parallel to one side of a triangle to intersect the other two sides in distinct points, the other two sides are divided in the same ratio.</h6>
                            <li><h6>10.V.1.2 If a line divides two sides of a triangle in the same ratio, the line is parallel to the third side..</h6>
                                <li><h6>10.V.1.3 If in two triangles, the corresponding angles are equal, their corresponding sides are proportional and the triangles are similar..</h6>
                                    <ul style={{ color: "green" }}>
                                        <li>Ratios in similar figures (X-P.2)</li>
                                        <li>Similarity statements (X-P.3)</li>
                                        <li>Side lengths and angle measures in similar figures (X-P.4)</li>
                                        <li>Similar triangles and indirect measurement (X-P.5)</li>
                                    </ul>
                                </li>


                                <li><h6>10.V.1.4 If the corresponding sides of two triangles are proportional, their corresponding angles are equal and the two triangles are similar..</h6>
                                    <ul style={{ color: "green" }}>
                                        <li>Ratios in similar figures (X-P.2)</li>
                                        <li>Similarity statements (X-P.3)</li>
                                        <li>Side lengths and angle measures in similar figures (X-P.4)</li>
                                        <li>Similar triangles and indirect measurement (X-P.5)</li>
                                    </ul>
                                    <li><h6>10.V.1.6 If a perpendicular is drawn from the vertex of the right angle to the hypotenuse, the triangles on each side of the perpendicular are similar to the whole triangle and to each other.</h6></li>
                                    <li><h6>10.V.1.7 The ratio of the areas of two similar triangles is equal to the ratio of the squares on their corresponding sides.</h6></li>
                                    <ul style={{ color: "green" }}>
                                        <li>Areas of similar figures (X-P.9)</li>
                                    </ul>
                                    <li><h6>10.V.1.8 In a right triangle, the square on the hypotenuse is equal to the sum of the squares on the other two sides.</h6></li>
                                    <ul style={{ color: "green" }}>
                                        <li>Pythagoras' Theorem (X-Q.1)</li>
                                    </ul>
                                    <li><h6>10.V.1.9 In a triangle, if the square on one side is equal to sum of the squares on the other two sides, the angles opposite to the first side is a right triangle.</h6></li>
                                    <ul style={{ color: "green" }}>
                                        <li>Converse of Pythagoras' theorem (X-Q.2)</li>
                                    </ul>


                                    <li><h6>10.V.2 Tangents to a circle motivated by chords drawn from points coming closer and closer to the point.</h6></li>
                                    <li><h6>10.V.2.1 The tangent at any point of a circle is perpendicular to the radius through the point of contact.</h6></li>
                                    <ul style={{ color: "green" }}>
                                        <li>Tangent lines (X-R.7)</li>
                                    </ul>

                                    <li><h6>10.V.2.2 The lengths of tangents drawn from an external point to a circle are equal</h6></li>
                                    <ul style={{ color: "green" }}>
                                        <li>Tangent lines (X-R.7)</li>
                                        <li>Perimeter of polygons with an inscribed circle (X-R.8)</li>
                                    </ul>

                                    <h3>10.V.3 Constructions</h3>
                                    <li><h6>10.V.3.1 Division of a line segment in a given ratio (internally).</h6></li>
                                    <li><h6>10.V.3.2 Tangent to a circle from a point outside it.</h6></li>
                                    <ul style={{ color: "green" }}>
                                        <li>Construct a tangent line to a circle (X-AA.1)</li>
                                    </ul>
                                    <li><h6>10.V.3.3 Construction of a triangle similar to a given triangle.</h6></li>





                                    <h3 style={{ color: "red" }}>10.VI Mensuration</h3>
                                    <h6>10.VI.1 Areas Related to Circles</h6>
                                    <li><h6>10.VI.1.1 Motivate the area of a circle; area of sectors and segments of a circle. Problems based on areas and perimeter/circumference of the above said plane figures. (In calculating area of segment of a circle, problems should be restricted to central angle of 60°, 90° and 120° only. Plane figures involving triangles, simple quadrilaterals and circle should be taken.)</h6>
                                        <ul style={{ color: "green" }}>
                                            <li>Area and circumference of circles (X-M.6)</li>
                                            <li>Central angles (X-R.2)</li>
                                            <li>Arc measure and arc length (X-R.3)</li>
                                            <li>Area of sectors (X-R.4)</li>
                                            <li>Circle measurements: mixed review (X-R.5)</li>

                                        </ul>
                                    </li>


                                    <h6>10.VI.2 Surface Areas and Volumes</h6>
                                    <li><h6>10.VI.2.1 Problems on finding surface areas and volumes of combinations of any two of the following: cubes, cuboids, spheres, hemispheres and right circular cylinders/cones. Frustum of a cone.</h6>
                                        <ul style={{ color: "green" }}>
                                            <li>Area of compound figures (X-M.7)</li>
                                            <li>Area between two shapes (X-M.8)</li>
                                            <li>Introduction to surface area and volume (X-T.1)</li>
                                            <li>Surface area of prisms and cylinders (X-T.2)</li>
                                            <li>Surface area of cones (X-T.3)</li>
                                            <li>Volume of prisms and cylinders (X-T.4)</li>
                                            <li>Volume of cones (X-T.5)</li>
                                            <li>Surface area and volume of spheres (X-T.6)</li>
                                            <li>Introduction to similar solids (X-T.7)</li>
                                            <li>Surface area and volume of similar solids (X-T.8)</li>
                                            <li>Surface area and volume review (X-T.9)</li>

                                        </ul>

                                        <li><h6>10.VI.2.2 Problems involving converting one type of metallic solid into another and other mixed problems. (Problems with combination of not more than two different solids be taken.)</h6></li>
                                        <ul style={{ color: "green" }}>
                                            <li>Weighted averages: word problems (X-V.3)</li>

                                        </ul>



                                        <h3 style={{ color: "red" }}>10.VII Statistics and Probability</h3>
                                        <h6>10.VII.1 Statistics</h6>
                                        <li><h6>10.VII.1.1 Mean, median and mode of grouped data (bimodal situation to be avoided). Cumulative frequency graph.</h6>
                                            <ul style={{ color: "green" }}>
                                                <li>Mean, median, mode and range (X-Y.1)</li>
                                            </ul>
                                        </li>
                                        <h6>10.VII.2 Probability</h6>
                                        <li><h6>10.VII.2.1 Classical definition of probability. Connection with probability as given in Class IX. Simple problems on single events, not using set notation.</h6>
                                            <ul style={{ color: "green" }}>
                                                <li>Theoretical probability (X-X.1)</li>
                                                <li>Experimental probability (X-X.2)</li>
                                                <li>Compound events: find the number of outcomes (X-X.3)</li>
                                                <li>Identify independent and dependent events (X-X.4)</li>
                                                <li>Probability of independent and dependent events (X-X.5)</li>
                                                <li>Probability of independent and dependent events (X-X.5)</li>
                                                <li>Counting principle (X-X.7)</li>

                                            </ul>
                                        </li>

                                    </li>

                                </li>
                            </li>
                        </li>
                    </li>
                </ul>
            </div>


        </div>
    )
}

export default Class10









