
import React from 'react';
//  import './class.css';

const Classii = () => {
    const categories = [
        {
            "title": "Counting and Number Patterns",
            "items": [
                { "label": "A.1", "description": "Counting review - up to 10" },
                { "label": "A.2", "description": "Count to fill a ten frame" },
                { "label": "A.3", "description": "Counting review - up to 20" },
                { "label": "A.4", "description": "Counting tens and ones - up to 30" },
                { "label": "A.5", "description": "Count on ten frames - up to 40" },
                { "label": "A.6", "description": "Counting - up to 100" },
                { "label": "A.7", "description": "Counting tens and ones - up to 99" },
                { "label": "A.8", "description": "Counting by twos, fives and tens with pictures" },
                { "label": "A.9", "description": "Counting by twos, fives and tens" },
                { "label": "A.10", "description": "Counting forward and backward" },
                { "label": "A.11", "description": "Number lines - up to 100" },
                { "label": "A.12", "description": "Hundred chart" },
                { "label": "A.13", "description": "Even or odd" },
                { "label": "A.14", "description": "Identify numbers as even or odd" },
                { "label": "A.15", "description": "Even or odd numbers on number lines" },
                { "label": "A.16", "description": "Which even or odd number comes before" },
                { "label": "A.17", "description": "Skip-counting patterns - with tables" },
                { "label": "A.18", "description": "Sequences - count up and down by 1, 2, 3, 5  " },
                { "label": "A.19", "description": "Sequences - count up and down by 100" },
                { "label": "A.20", "description": "Ordinal numbers" },
                { "label": "A.21", "description": "Writing numbers in words" }
            ]
        },
        {
            "title": "Place Values",
            "items": [
                { "label": "B.1", "description": "Place value models - tens and ones" },
                { "label": "B.2", "description": "Place value models - up to hundreds" },
                { "label": "B.3", "description": "Write tens and ones - up to 30" },
                { "label": "B.4", "description": "Write tens and ones - up to 100" }
            ]
        },
        {
            "title": "Understand Addition",
            "items": [
                { "label": "C.1", "description": "Add with pictures - sums up to 10" },
                { "label": "C.2", "description": "Addition sentences - sums up to 10" },
                { "label": "C.3", "description": "Addition sentences using number lines - sums up to 10" },
                { "label": "C.4", "description": "Adding zero" }
            ]
        },
        {
            "title": "Addition Skill Builders",
            "items": [
                { "label": "D.1", "description": "Adding 1" },
                { "label": "D.2", "description": "Adding 2" },
                { "label": "D.3", "description": "Adding 3" },
                { "label": "D.4", "description": "Adding 4" },
                { "label": "D.5", "description": "Adding 5" },
                { "label": "D.6", "description": "Adding 6" },
                { "label": "D.7", "description": "Adding 7" },
                { "label": "D.8", "description": "Adding 8" },
                { "label": "D.9", "description": "Adding 9" },
                { "label": "D.10", "description": "Adding 0" }
            ]
        },
        {
            "title": "Addition",
            "items": [
                { "label": "E.1", "description": "Addition facts - sums up to 10" },
                { "label": "E.2", "description": "Ways to make a number - addition sentences" },
                { "label": "E.3", "description": "Make a number using addition - sums up to 10" },
                { "label": "E.4", "description": "Complete the addition sentence - sums up to 10" },
                { "label": "E.5", "description": "Addition word problems - sums up to 10" },
                { "label": "E.6", "description": "Addition sentences for word problems - sums " },
                { "label": "E.7", "description": "Addition facts - sums up to 18" },
                { "label": "E.8", "description": "Addition sentences using number lines - sums "},
                { "label": "E.9", "description": "Addition word problems - sums up to 18" },
                { "label": "E.10", "description": "Addition sentences for word problems - sums " },
                { "label": "E.11", "description": "Addition facts - sums up to 20" },
                { "label": "E.12", "description": "Make a number using addition - sums up to 20" },
                { "label": "E.13", "description": "Addition sentences for word problems - sums " },
                { "label": "E.14", "description": "Related addition facts" },
                { "label": "E.15", "description": "Addition sentences: true or false?" },
                { "label": "E.16", "description": "Add a one-digit number to a two-digit number" },
                { "label": "E.17", "description": "Add a one-digit number to a two-digit number" }
            ]
        },
        {
            "title": "Addition Strategies",
            "items": [
                { "label": "F.1", "description": "Add doubles" },
                { "label": "F.2", "description": "Add using doubles plus one" },
                { "label": "F.3", "description": "Add using doubles minus one" },
                { "label": "F.4", "description": "Add three numbers - use doubles" },
                { "label": "F.5", "description": "Complete the addition sentence - make ten" },
                { "label": "F.6", "description": "Add three numbers - make ten" },
                { "label": "F.7", "description": "Add two multiples of ten" },
                { "label": "F.8", "description": "Add a multiple of ten" },
                { "label": "F.9", "description": "Add three numbers" },
                { "label": "F.10", "description": "Add three numbers - word problems" }
            ]
        },
        {
            "title": "Understand Subtraction",
            "items": [
                { "label": "G.1", "description": "Subtract with pictures - numbers up to 10" },
                { "label": "G.2", "description": "Subtraction sentences - numbers up to 10" },
                { "label": "G.3", "description": "Subtraction sentences using number lines - numbers up to 10" },
                { "label": "G.4", "description": "Subtract zero and all" }
            ]
        },
        {
            "title": "Subtraction Skill Builders",
            "items": [
                { "label": "H.1", description: "Subtracting 1" },
                { "label": "H.2", "description": "Subtracting 2" },
                { "label": "H.3", "description": "Subtracting 3" },
                { "label": "H.4", "description": "Subtracting 4" },
                { "label": "H.5", "description": "Subtracting 5" },
                { "label": "H.6", "description": "Subtracting 6" },
                { "label": "H.7", "description": "Subtracting 7" },
                { "label": "H.8", "description": "Subtracting 8" },
                { "label": "H.9", "description": "Subtracting 9" },
                { "label": "H.10", "description": "Subtracting 0" }
            ]
        },
        {
            "title": "Subtraction",
            "items": [
                { "label": "I.1", "description": "Subtraction facts - numbers up to 10" },
                { "label": "I.2", "description": "Ways to make a number - subtraction sentences" },
                { "label": "I.3", "description": "Complete the subtraction sentence - numbers " },
                { "label": "I.4", "description": "Subtraction word problems - numbers up to 10" },
                { "label": "I.5", "description": "Subtraction facts - numbers up to 18" },
                { "label": "I.6", "description": "Subtraction sentences using number lines " },
                { "label": "I.7", "description": "Subtraction word problems - numbers up to 18" },
                { "label": "I.8", "description": "Subtraction facts - numbers up to 20" },
                { "label": "I.9", "description": "Complete the subtraction sentence - numbers " },
                { "label": "I.10", "description": "Subtraction sentences for word problems -" },
                { "label": "I.11", "description": "Related subtraction facts" },
                { "label": "I.12", "description": "Subtraction sentences: true or false?" },
                { "label": "I.13", "description": "Subtract a one-digit number from a two-digit " },
                { "label": "I.14", "description": "Subtract a one-digit number from a two-digit " }
            ]
        },
        {
            "title": "Subtraction Strategies",
            "items": [
                { "label": "J.1", "description": "Subtract doubles" },
                { "label": "J.2", "description": "Subtract using doubles plus one" },
                { "label": "J.3", "description": "Subtract using doubles minus one" },
                { "label": "J.4", "description": "Complete the subtraction sentence - make ten" },
                { "label": "J.5", "description": "Subtract two multiples of ten" },
                { "label": "J.6", "description": "Subtract a multiple of ten" },
                { "label": "J.7", "description": "Subtract three numbers" },
                { "label": "J.8", "description": "Subtract three numbers - word problems" }
            ]
        },
        {
            "title": "Understanding Shapes and Patterns",
            "items": [
                { "label": "K.1", "description": "Recognize and name 2D shapes" },
                { "label": "K.2", "description": "Recognize and name 3D shapes" },
                { "label": "K.3", "description": "Attributes of shapes" },
                { "label": "K.4", "description": "Compare shapes" },
                { "label": "K.5", "description": "Shapes in the environment" },
                { "label": "K.6", "description": "Create shapes" },
                { "label": "K.7", "description": "Recognize patterns" },
                { "label": "K.8", "description": "Extend patterns" },
                { "label": "K.9", "description": "Create patterns" }
            ]
        }
    ];

    const menuItems = [
        { label: 'L', text: 'Lower kindergarten' },
        { label: 'U', text: 'Upper kindergarten' },
        { label: 'I', text: 'Level I' },
        { label: 'II', text: 'Level II' },
        { label: 'III', text: 'Level III' },
        { label: 'IV', text: 'Level IV' },
        { label: 'V', text: 'Level V' },
        { label: 'VI', text: 'Level VI' },
        { label: 'VII', text: 'Level VII' },
        { label: 'VIII', text: 'Level VIII' },
    ];

    return (
        <div className="maths-container">
            <aside className="menu">
                {menuItems.map((item, index) => (
                    <div
                        key={index}
                        className={`menu-item menu-item-${item.label}`}
                        data-text={item.text}
                    >
                        {item.label}
                    </div>
                ))}
            </aside>
            <div className="content-maths">
                {/* ... (keep the rest of the content as is) */}

                <div className="category intro">
                    <h3>Class-II maths</h3>
                    <p>Here is a list of all of the maths skills students learn in UKG! These skills are organised into categories, and you can move your mouse over any skill name to preview the skill. To start practising, just click on any link. STEMWORLD will track your score, and the questions will automatically increase in difficulty as you improve!</p>
                </div>
                <div className="category-list">
                    {categories.map((category, index) => (
                        <div key={index} className="category">
                            <h3>{category.title}</h3>
                            <ul>
                                {category.items.map((item, i) => (
                                    <li key={i}>
                                        <span className="item-label">{item.label}</span>
                                        <span className="item-description">{item.description}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Classii;