import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserData.css'; // Assuming you're using a separate CSS file for styles

export default function UserData() {
    const [usersData, setUsersData] = useState([]); // State to store user data
    const [loading, setLoading] = useState(true);   // State to show loading indicator
    const [error, setError] = useState(null);       // State to show error if API call fails

    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is coming from the homepage
        const fromHome = sessionStorage.getItem('fromHome');

        if (fromHome) {
            // If they refreshed, redirect to the homepage
            navigate('/');
        } else {
            // Set a flag in sessionStorage to indicate the user has visited the product page
            sessionStorage.setItem('fromHome', 'true');
        }

        // Cleanup: Remove the flag when the component unmounts
        return () => {
            sessionStorage.removeItem('fromHome');
        };
    }, [navigate]);

    // Fetch customer data from the server when the component mounts
    useEffect(() => {
        fetch("http://127.0.0.1:5000/customers")
            .then(response => response.json())
            .then(data => {
                setUsersData(data);  // Set the fetched data to state
                setLoading(false);   // Set loading to false once data is fetched
            })
            .catch(err => {
                setError(err.message);  // Set error message if API call fails
                setLoading(false);      // Set loading to false if there's an error
            });
    }, []); // Empty dependency array means this effect runs only once after component mounts

    if (loading) {
        return <h2 style={{ textAlign: 'center' }}>Loading...</h2>;
    }

    if (error) {
        return <h2 style={{ textAlign: 'center', color: 'red' }}>Error: {error}</h2>;
    }

    return (
        <>
         <h1 style={{ textAlign: 'center' }}>Users Data</h1>
            <table className='user-data-table'>
                <thead>
                    <tr>
                        <th>Serial.No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Why are you contacting us?</th>
                        <th>Message</th>
                    </tr>
                </thead>
                <tbody>
                    {usersData.length > 0 ? (
                        usersData.map((user, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td> {/* Display regular number for row number */}
                                <td>{user.Name}</td>
                                <td>{user.Email}</td>
                                <td>{user.PhoneNumber}</td>
                                <td>{user.Quary}</td>
                                <td>{user.Msg}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" style={{ textAlign: 'center' }}>No Users Found</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}
