
import React from 'react';
//  import './class.css';

const Classiii = () => {
    const categories = [
        {
            title: "Numbers and comparing",
            items: [
                { label: "A.1", description: "Even or odd" },
                { label: "A.2", description: "Even or odd: arithmetic rules" },
                { label: "A.3", description: "Skip-counting puzzles" },
                { label: "A.4", description: "Number sequences" },
                { label: "A.5", description: "Ordinal numbers to 100th" },
                { label: "A.6", description: "Write numbers in words" },
                { label: "A.7", description: "Comparing numbers" },
                { label: "A.8", description: "Which number is greatest/least?" },
                { label: "A.9", description: "Put numbers in order" }
            ]
        },
        {

            title: "Multiplication",
            items: [
                { label: "H.1", description: "Multiplication sentences" },
                { label: "H.2", description: "Multiply numbers ending in zeroes" },
                { label: "H.3", description: "Multiplication input/output tables" },
                { label: "H.4", description: "Multiplication word problems" },
                { label: "H.5", description: "Multiplication word problems: find the " },
                { label: "H.6", description: "Multiply one-digit numbers by two-digit " },
                { label: "H.7", description: "Multiply one-digit numbers by two-digit : " },
                { label: "H.8", description: "Multiply one-digit numbers by three-digit " },
                { label: "H.10", description: "Box multiplication" },
                { label: "H.11", description: "Lattice multiplication" }
            ]
        },
        {
            title: "Measurement",
            items: [
                { label: "P.1", description: "Measure using a centimetre ruler" },
                { label: "P.2", description: "Which metric unit of length is appropriate?" },
                { label: "P.3", description: "Compare and convert metric units of length" },
                { label: "P.4", description: "Conversion tables" },
                { label: "P.5", description: "Metric mixed units" },
                { label: "P.6", description: "Light and heavy" },
                { label: "P.7", description: "Holds more or less" },
                { label: "P.8", description: "Compare weight and capacity" },
                { label: "P.9", description: "Read a thermometer" },
                { label: "P.10", description: "Reasonable temperature" }
            ]
        },
        {
            title: "Place values",
            items: [
                { label: "B.1", description: "Place value models up to hundreds" },
                { label: "B.2", description: "Place value names up to hundreds" },
                { label: "B.3", description: "Place value names up to thousands" },
                { label: "B.4", description: "Value of a digit" },
                { label: "B.5", description: "Convert to/from a number" },
                { label: "B.6", description: "Convert between place values" },
                { label: "B.7", description: "Convert from expanded form" },
                { label: "B.8", description: "Convert between standard and expanded form" },
                { label: "B.9", description: "Place value word problems" }
            ]
        },
        {
            title: "Understand division",
            items: [
                { label: "I.1", description: "Divide by counting equal groups" },
                { label: "I.2", description: "Write division sentences for groups" },
                { label: "I.3", description: "Relate multiplication and division for groups" },
                { label: "I.4", description: "Write division sentences for arrays" },
                { label: "I.5", description: "Relate multiplication and division for arrays" }
            ]
        },
        {
            title: "Geometry",
            items: [
                { label: "Q.1", description: "Identify two-dimensional shapes" },
                { label: "Q.2", description: "Count and compare sides and vertices" },
                { label: "Q.3", description: "Identify three-dimensional shapes" },
                { label: "Q.4", description: "Count vertices, edges and faces" },
                { label: "Q.5", description: "Identify faces of three-dimensional shapes" },
                { label: "Q.6", description: "Is it a polygon?" },
                { label: "Q.7", description: "Reflection, rotation and translation" },
                { label: "Q.8", description: "Symmetry" },
                { label: "Q.9", description: "Maps" },
                { label: "Q.10", description: "Find the area of rectangles and squares" },
                { label: "Q.11", description: "Find the missing side length of a rectangle" }
            ]
        },
        {
            title: "Addition",
            items: [
                { label: "C.1", description: "Add two numbers up to three digits" },
                { label: "C.2", description: "Addition input/output tables - up to three digits" },
                { label: "C.3", description: "Add two numbers up to three digits - word " },
                { label: "C.4", description: "Complete the addition sentence - up to three " },
                { label: "C.5", description: "Balance addition equations - up to three digits" },
                { label: "C.6", description: "Add three or more numbers up to three digits " },
                { label: "C.7", description: "Add three or more numbers up to three digits -  " },
                { label: "C.8", description: "Addition patterns over increasing place values" },
                { label: "C.9", description: "Addition: fill in the missing digits" }
            ]
        },
        {
            title: "Division skill builders",
            items: [
                { label: "J.1", description: "Divide by 1" },
                { label: "J.2", description: "Divide by 2" },
                { label: "J.3", description: "Divide by 3" },
                { label: "J.4", description: "Divide by 4" },
                { label: "J.5", description: "Divide by 5" },
                { label: "J.6", description: "Divide by 6" },
                { label: "J.7", description: "Divide by 7" },
                { label: "J.8", description: "Divide by 8" },
                { label: "J.9", description: "Divide by 9" },
                { label: "J.10", description: "Divide by 10" }
            ]
        },
        {
            title: "Properties",
            items: [
                { label: "R.1", description: "Addition, subtraction, multiplication and division " },
                { label: "R.2", description: "Understanding parentheses" },
                { label: "R.3", description: "Properties of addition" },
                { label: "R.4", description: "Solve using properties of addition" },
                { label: "R.5", description: "Properties of multiplication" },
                { label: "R.6", description: "Solve using properties of multiplication" },
                { label: "R.7", description: "Distributive property: find the missing factor" },
                { label: "R.8", description: "Multiply using the distributive property" },
                { label: "R.9", description: "Relate addition and multiplication" },
                { label: "R.10", description: "Relate multiplication and division" }
            ]
        },
        {
            title: "Subtraction",
            items: [
                { label: "D.1", description: "Subtract numbers up to three digits." },
                { label: "D.2", description: "Subtraction input/output tables - up to three ." },
                { label: "D.3", description: "Subtract numbers up to three digits - word ." },
                { label: "D.4", description: "Complete the subtraction sentence - up to three" },
                { label: "D.5", description: "Balance subtraction equations - up to three ." },
                { label: "D.6", description: "Subtraction patterns over increasing place ." },
                { label: "D.7", description: "Subtraction: fill in the missing digits." }
            ]
        },
        {
            title: "Division Fluency",
            items: [
                { label: "K.1", description: "Division facts for 2, 3, 4, 5, 10." },
                { label: "K.2", description: "Division facts for 2, 3, 4, 5, 10: true or false?" },
                { label: "K.3", description: "Division facts for 2, 3, 4, 5, 10: sorting." },
                { label: "K.4", description: "Division facts for 6, 7, 8, 9." },
                { label: "K.5", description: "Division facts for 6, 7, 8, 9: true or false?" },
                { label: "K.6", description: "Division facts for 6, 7, 8, 9: sorting." },
                { label: "K.7", description: "Division facts up to 10." },
                { label: "K.8", description: "Division facts up to 10: true or false?" },
                { label: "K.9", description: "Division facts up to 10: sorting." },
                { label: "K.10", description: "Division facts up to 10: find the missing number." },
                { label: "K.11", description: "Division sentences up to 10: true or false?" }
            ]
        },
        {
            title: "Mixed Operations",
            items: [
                { label: "S.1", description: "Addition, subtraction, multiplication and division ." },
                { label: "S.2", description: "Complete the addition, subtraction, ." },
                { label: "S.3", description: "Multiplication and division facts up to 5: true or?" },
                { label: "S.4", description: "Multiplication and division facts up to 10: true or ?" },
                { label: "S.5", description: "Multiplication and division facts up to 12: true or ?" },
                { label: "S.6", description: "Multiplication and division sentences up to 12:  " },
                { label: "S.7", description: "Add, subtract, multiply and divide." },
                { label: "S.8", description: "Addition, subtraction, multiplication and division  ." },
                { label: "S.9", description: "Add and subtract data from tables." },
                { label: "S.10", description: "Multi-step word problems." },
                { label: "S.11", description: "Missing operators." }
            ]
        },
        {
            title: "Understand Multiplication",
            items: [
                { label: "E.1", description: "Count equal groups." },
                { label: "E.2", description: "Identify multiplication expressions for equal ." },
                { label: "E.3", description: "Write multiplication sentences for equal groups." },
                { label: "E.4", description: "Relate addition and multiplication for equal ." },
                { label: "E.5", description: "Identify multiplication expressions for arrays." },
                { label: "E.6", description: "Write multiplication sentences for arrays." },
                { label: "E.7", description: "Make arrays to model multiplication." },
                { label: "E.8", description: "Write multiplication sentences for number lines." }
            ]
        },
        {
            title: "Division",
            items: [
                { label: "L.1", description: "Complete the division table." },
                { label: "L.2", description: "Division input/output tables." },
                { label: "L.3", description: "Division word problems." },
                { label: "L.4", description: "Divisibility rules for 2, 5 and 10." }
            ]
        },
        {
            title: "Multiplication Skill Builders",
            items: [
                { label: "F.1", description: "Multiply by 0." },
                { label: "F.2", description: "Multiply by 1." },
                { label: "F.3", description: "Multiply by 2." },
                { label: "F.4", description: "Multiply by 3." },
                { label: "F.5", description: "Multiply by 4." },
                { label: "F.6", description: "Multiply by 5." },
                { label: "F.7", description: "Multiply by 6." },
                { label: "F.8", description: "Multiply by 7." },
                { label: "F.9", description: "Multiply by 8." },
                { label: "F.10", description: "Multiply by 9." },
                { label: "F.11", description: "Multiply by 10." }
            ]
        },
        {
            title: "Data and Graphs",
            items: [
                { label: "M.1", description: "Interpret line plots." },
                { label: "M.2", description: "Create line plots." },
                { label: "M.3", description: "Interpret pictographs." },
                { label: "M.4", description: "Create pictographs." },
                { label: "M.5", description: "Sort shapes into a Venn diagram." },
                { label: "M.6", description: "Count shapes in a Venn diagram." }
            ]
        },
        {
            title: "Estimation and Rounding",
            items: [
                { label: "T.1", description: "Rounding." },
                { label: "T.2", description: "Round money amounts." },
                { label: "T.3", description: "Rounding puzzles." },
                { label: "T.4", description: "Estimate sums." },
                { label: "T.5", description: "Estimate sums: word problems." },
                { label: "T.6", description: "Estimate differences." },
                { label: "T.7", description: "Estimate differences: word problems." },
                { label: "T.8", description: "Estimate products." },
                { label: "T.9", description: "Estimate products: word problems." },
                { label: "T.10", description: "Estimate sums, differences and products: word ." }
            ]
        },
        {
            title: "Money",
            items: [
                { label: "N.1", description: "Count coins and notes - up to 500-rupee note." },
                { label: "N.2", description: "Which picture shows more?" },
                { label: "N.3", description: "Purchases - do you have enough money - up to " },
                { label: "N.4", description: "Making change." },
                { label: "N.5", description: "Inequalities with money." },
                { label: "N.6", description: "Put money amounts in order." },
                { label: "N.7", description: "Add and subtract money amounts." },
                { label: "N.8", description: "Add money amounts - word problems." },
                { label: "N.9", description: "Price lists." }
            ]
        },
        {
            title: "Multiplication Fluency",
            items: [
                { label: "G.1", description: "Multiplication facts for 2, 3, 4, 5 and 10." },
                { label: "G.2", description: "Multiplication facts for 2, 3, 4, 5, 10: true or false?" },
                { label: "G.3", description: "Multiplication facts for 2, 3, 4, 5, 10: sorting." },
                { label: "G.4", description: "Multiplication facts for 6, 7, 8 and 9." },
                { label: "G.5", description: "Multiplication facts for 6, 7, 8, 9: true or false?" },
                { label: "G.6", description: "Multiplication facts for 6, 7, 8, 9: sorting." },
                { label: "G.7", description: "Multiplication facts up to 10." },
                { label: "G.8", description: "Multiplication facts up to 10: true or false?" },
                { label: "G.9", description: "Multiplication facts up to 10: sorting." },
                { label: "G.10", description: "Multiplication sentences up to 10: true or false?" },
                { label: "G.11", description: "Multiplication facts up to 10: find the missing ." },
                { label: "G.12", description: "Multiplication facts up to 10: select the missing ." },
                { label: "G.13", description: "Squares up to 10 x 10." }
            ]
        },
        {
            title: "Time",
            items: [
                { label: "O.1", description: "Match analogue clocks and times." },
                { label: "O.2", description: "Match digital clocks and times." },
                { label: "O.3", description: "Read clocks and write times." },
                { label: "O.4", description: "A.M. or P.M." },
                { label: "O.5", description: "Elapsed time." },
                { label: "O.6", description: "Elapsed time word problems." },
                { label: "O.7", description: "Time patterns." },
                { label: "O.8", description: "Read a calendar." },
                { label: "O.9", description: "Reading schedules." },
                { label: "O.10", description: "Timelines." }
            ]
        },
        {
            title: "Patterns",
            items: [
                { label: "V.1", description: "Repeating patterns." },
                { label: "V.2", description: "Growing patterns." },
                { label: "V.3", description: "Find the next shape in a pattern." },
                { label: "V.4", description: "Complete a repeating pattern." },
                { label: "V.5", description: "Make a repeating pattern." },
                { label: "V.6", description: "Find the next row in a growing pattern." }
            ]
        },
        {
            title: "Probability",
            items: [
                { label: "W.1", description: "More, less and equally likely." },
                { label: "W.2", description: "Certain, probable, unlikely and impossible." },
                { label: "W.3", description: "Combinations." }
            ]
        },

    ];

    const menuItems = [
        { label: 'L', text: 'Lower kindergarten' },
        { label: 'U', text: 'Upper kindergarten' },
        { label: 'I', text: 'Level I' },
        { label: 'II', text: 'Level II' },
        { label: 'III', text: 'Level III' },
        { label: 'IV', text: 'Level IV' },
        { label: 'V', text: 'Level V' },
        { label: 'VI', text: 'Level VI' },
        { label: 'VII', text: 'Level VII' },
        { label: 'VIII', text: 'Level VIII' },
    ];

    return (
        <div className="maths-container">
            <aside className="menu">
                {menuItems.map((item, index) => (
                    <div
                        key={index}
                        className={`menu-item menu-item-${item.label}`}
                        data-text={item.text}
                    >
                        {item.label}
                    </div>
                ))}
            </aside>
            <div className="content-maths">
                {/* ... (keep the rest of the content as is) */}

                <div className="category intro">
                    <h3>Class-III maths</h3>
                    <p>Here is a list of all of the maths skills students learn in UKG! These skills are organised into categories, and you can move your mouse over any skill name to preview the skill. To start practising, just click on any link. STEMWORLD will track your score, and the questions will automatically increase in difficulty as you improve!</p>
                </div>
                <div className="category-list">
                    {categories.map((category, index) => (
                        <div key={index} className="category">
                            <h3>{category.title}</h3>
                            <ul>
                                {category.items.map((item, i) => (
                                    <li key={i}>
                                        <span className="item-label">{item.label}</span>
                                        <span className="item-description">{item.description}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Classiii;