
import React from 'react';
//  import './class.css';

const Classiv = () => {
    const categories = [
        {
            title: "Number Sense",
            items: [
                { label: "A.1", description: "Place values." },
                { label: "A.2", description: "Convert between place values." },
                { label: "A.3", description: "Word names for numbers." },
                { label: "A.4", description: "Ordinal numbers to 100th." },
                { label: "A.5", description: "Rounding." },
                { label: "A.6", description: "Even or odd: arithmetic rules." },
                { label: "A.7", description: "Inequalities with number lines." },
                { label: "A.8", description: "Compare numbers up to five digits." }
            ]
        },
        {
            title: "Division",
            items: [
                { label: "E.1", description: "Division facts to 10." },
                { label: "E.2", description: "Division facts to 10: word problems." },
                { label: "E.3", description: "Properties of division." },
                { label: "E.4", description: "Divide larger numbers." },
                { label: "E.5", description: "Divide larger numbers: word problems." },
                { label: "E.6", description: "Complete the division table." },
                { label: "E.7", description: "Interpret remainders." },
                { label: "E.8", description: "Choose numbers with a particular quotient." },
                { label: "E.9", description: "Divide numbers ending in zeroes." },
                { label: "E.10", description: "Estimate quotients: word problems." },
                { label: "E.11", description: "Divisibility rules." },
                { label: "E.12", description: "Divisibility rules: word problems." },
                { label: "E.13", description: "Division patterns over increasing place values." }
            ]
        },
        {
            title: "Units of Measurement",
            items: [
                { label: "K.1", description: "Choose the appropriate metric unit of measure." },
                { label: "K.2", description: "Compare and convert metric units of length." },
                { label: "K.3", description: "Compare and convert metric units of mass." },
                { label: "K.4", description: "Compare and convert metric units of volume." },
                { label: "K.5", description: "Metric mixed units." }
            ]
        },
        {
            title: "Addition",
            items: [
                { label: "B.1", description: "Add numbers up to five digits." },
                { label: "B.2", description: "Add numbers up to five digits: word problems." },
                { label: "B.3", description: "Addition: fill in the missing digits." },
                { label: "B.4", description: "Properties of addition." },
                { label: "B.5", description: "Add three or more numbers up to five digits ." },
                { label: "B.6", description: "Addition patterns over increasing place values." },
                { label: "B.7", description: "Choose numbers with a particular sum." },
                { label: "B.8", description: "Estimate sums." },
                { label: "B.9", description: "Estimate sums: word problems." }
            ]
        },
        {
            title: "Time",
            items: [
                { label: "L.1", description: "Convert time units" },
                { label: "L.2", description: "Add and subtract mixed time units" },
                { label: "L.3", description: "A.M. or P.M." },
                { label: "L.4", description: "Elapsed time" },
                { label: "L.5", description: "Find start and end times: multi-step word " },
                { label: "L.6", description: "Convert between 12-hour and 24-hour time" },
                { label: "L.7", description: "Transportation schedules - 12-hour time" },
                { label: "L.8", description: "Transportation schedules - 24-hour time" },
                { label: "L.9", description: "Time patterns" }
            ]
        },
        {
            title: "Subtraction",
            items: [
                { label: "C.1", description: "Subtract numbers up to five digits" },
                { label: "C.2", description: "Subtract numbers up to five digits: word " },
                { label: "C.3", description: "Subtraction: fill in the missing digits" },
                { label: "C.4", description: "Subtraction patterns over increasing place " },
                { label: "C.5", description: "Choose numbers with a particular difference" },
                { label: "C.6", description: "Estimate differences" },
                { label: "C.7", description: "Estimate differences: word problems" }
            ]
        },
        {
            title: "Mixed operations",
            items: [
                { label: "F.1", description: "Add, subtract, multiply and divide" },
                { label: "F.2", description: "Addition, subtraction, multiplication and division " },
                { label: "F.3", description: "Estimate sums, differences, products and : " },
                { label: "F.4", description: "Multi-step word problems" },
                { label: "F.5", description: "Word problems with extra or missing information" },
                { label: "F.6", description: "Solve word problems using guess-and-check" },
                { label: "F.7", description: "Choose numbers with a particular sum, ," },
                { label: "F.8", description: "Mentally add and subtract numbers ending in " }
            ]
        },
        {
            title: "Geometry",
            items: [
                { label: "M.1", description: "Which two-dimensional figure is being ?" },
                { label: "M.2", description: "Identify three-dimensional figures" },
                { label: "M.3", description: "Count vertices, edges and faces" },
                { label: "M.4", description: "Identify faces of three-dimensional figures" },
                { label: "M.5", description: "Which three-dimensional figure is being ?" },
                { label: "M.6", description: "Nets of three-dimensional figures" },
                { label: "M.7", description: "Number of sides in polygons" },
                { label: "M.8", description: "Identify lines of symmetry" },
                { label: "M.9", description: "Rotational symmetry" }
            ]
        },
        {
            title: "Geometric measurement",
            items: [
                { label: "N.1", description: "Perimeter of rectangles" },
                { label: "N.2", description: "Perimeter of polygons" },
                { label: "N.3", description: "Perimeter of rectilinear shapes" },
                { label: "N.4", description: "Perimeter: find the missing side length" },
                { label: "N.5", description: "Use perimeter to determine cost" },
                { label: "N.6", description: "Find the area of figures made of unit squares" },
                { label: "N.7", description: "Select figures with a given area" },
                { label: "N.8", description: "Select two figures with the same area" },
                { label: "N.9", description: "Create figures with a given area" },
                { label: "N.10", description: "Find the area or missing side length of a " },
                { label: "N.11", description: "Area and perimeter: word problems" }
            ]
        },
        {
            title: "Multiplication",
            items: [
                { label: "D.1", description: "Multiplication facts to 10" },
                { label: "D.2", description: "Multiplication facts up to 10: find the missing " },
                { label: "D.3", description: "Compare numbers using multiplication" },
                { label: "D.4", description: "Multiply 1-digit numbers by 2-digit numbers" },
                { label: "D.5", description: "Multiply 1-digit numbers by 3-digit or 4-digit " },
                { label: "D.6", description: "Multiply 1-digit numbers by larger numbers" },
                { label: "D.7", description: "Multiplication patterns over increasing place " },
                { label: "D.8", description: "Properties of multiplication" },
                { label: "D.9", description: "Estimate products - multiply by 1-digit numbers" },
                { label: "D.10", description: "Estimate products - multiply by larger numbers" },
                { label: "D.11", description: "Estimate products: word problems" },
                { label: "D.12", description: "Box multiplication" },
                { label: "D.13", description: "Lattice multiplication" },
                { label: "D.14", description: "Multiply a two-digit number by a two-digit number: complete the missing steps" },
                { label: "D.15", description: "Multiply a two-digit number by a two-digit number" },
                { label: "D.16", description: "Multiply a two-digit number by a two-digit number: word problems" },
                { label: "D.17", description: "Choose numbers with a particular product" },
                { label: "D.18", description: "Multiply a two-digit number by a three-digit number: complete the missing steps" },
                { label: "D.19", description: "Multiply a two-digit number by a three-digit number" },
                { label: "D.20", description: "Multiply a two-digit number by a three-digit number: word problems" },
                { label: "D.21", description: "Multiply numbers ending in zeroes" },
                { label: "D.22", description: "Multiply numbers ending in zeroes: word problems" },
                { label: "D.23", description: "Multiply three numbers" }
            ]
        },

        {
            title: "Patterns and sequences",
            items: [
                { label: "I.1", description: "Complete an increasing number pattern" },
                { label: "I.2", description: "Complete a geometric number pattern" },
                { label: "I.3", description: "Number patterns: word problems" },
                { label: "I.4", description: "Number patterns: mixed review" }
            ]
        },

        {
            title: "Money",
            items: [
                { label: "J.1", description: "Compare money amounts" },
                { label: "J.2", description: "Round money amounts" },
                { label: "J.3", description: "Add and subtract money amounts" },
                { label: "J.4", description: "Add, subtract, multiply and divide money " },
                { label: "J.5", description: "Making change" },
                { label: "J.6", description: "Price lists" },
                { label: "J.7", description: "Price lists with multiplication" },
                { label: "J.8", description: "Unit prices" }
            ]
        },

        {
            title: "Positions",
            items: [
                { label: "E.1", description: "Are there enough?" },
                { label: "E.2", description: "More" },
                { label: "E.3", description: "Fewer" },
                { label: "E.4", description: "Fewer and more - compare by counting" },
                { label: "E.5", description: "Compare in a mixed group" }
            ]
        },
        {
            title: "Fractions",
            items: [
                { label: "O.1", description: "Halves and quarters" },
                { label: "O.2", description: "Equal parts" },
                { label: "O.3", description: "Simple fractions: what fraction does the shape?" },
                { label: "O.4", description: "Simple fractions: which shape matches the?" },
                { label: "O.5", description: "Simple fractions: parts of a group" }
            ]
        },
        {
            title: "Probability",
            items: [
                { label: "P.1", description: "Understanding probability" },
                { label: "P.2", description: "Find the probability" },
                { label: "P.3", description: "Make predictions" },
                { label: "P.4", description: "Combination" }
            ]
        }


    ];

    const menuItems = [
        { label: 'L', text: 'Lower kindergarten' },
        { label: 'U', text: 'Upper kindergarten' },
        { label: 'I', text: 'Level I' },
        { label: 'II', text: 'Level II' },
        { label: 'III', text: 'Level III' },
        { label: 'IV', text: 'Level IV' },
        { label: 'V', text: 'Level V' },
        { label: 'VI', text: 'Level VI' },
        { label: 'VII', text: 'Level VII' },
        { label: 'VIII', text: 'Level VIII' },
    ];

    return (
        <div className="maths-container">
            <aside className="menu">
                {menuItems.map((item, index) => (
                    <div
                        key={index}
                        className={`menu-item menu-item-${item.label}`}
                        data-text={item.text}
                    >
                        {item.label}
                    </div>
                ))}
            </aside>
            <div className="content-maths">
                {/* ... (keep the rest of the content as is) */}

                <div className="category intro">
                    <h3>Class-Iv maths</h3>
                    <p>Here is a list of all of the maths skills students learn in UKG! These skills are organised into categories, and you can move your mouse over any skill name to preview the skill. To start practising, just click on any link. STEM WORLD will track your score, and the questions will automatically increase in difficulty as you improve!</p>
                </div>
                <div className="category-list">
                    {categories.map((category, index) => (
                        <div key={index} className="category">
                            <h3>{category.title}</h3>
                            <ul>
                                {category.items.map((item, i) => (
                                    <li key={i}>
                                        <span className="item-label">{item.label}</span>
                                        <span className="item-description">{item.description}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Classiv;