
import React from 'react';
//  import './class.css';

const Classvii = () => {
    const categories = [
        {
            title: "Number Theory",
            items: [
                { label: "A.1", description: "Prime or composite" },
                { label: "A.2", description: "Prime factorisation" },
                { label: "A.3", description: "Multiplicative inverses" },
                { label: "A.4", description: "Divisibility rules" },
                { label: "A.5", description: "Highest common factor" },
                { label: "A.6", description: "Lowest common multiple" },
                { label: "A.7", description: "HCF and LCM: word problems" },
                { label: "A.8", description: "Scientific notation" },
                { label: "A.9", description: "Compare numbers written in scientific notation" },
                { label: "A.10", description: "Classify numbers" }
            ]
        },
        {
            title: "Exponents",
            items: [
                { label: "I.1", description: "Understanding exponents" },
                { label: "I.2", description: "Evaluate powers" },
                { label: "I.3", description: "Solve equations with variable exponents" },
                { label: "I.4", description: "Powers with negative bases" },
                { label: "I.5", description: "Powers with decimal and fractional bases" },
                { label: "I.6", description: "Evaluate numerical expressions involving" }
            ]
        },
        {
            title: "One-variable Equations",
            items: [
                { label: "Q.1", description: "Which x satisfies an equation?" },
                { label: "Q.2", description: "Write an equation from words" },
                { label: "Q.3", description: "Model and solve equations using algebra tiles" },
                { label: "Q.4", description: "Write and solve equations that represent " },
                { label: "Q.5", description: "Solve one-step equations" },
                { label: "Q.6", description: "Solve two-step equations" },
                { label: "Q.7", description: "Solve equations: word problems" },
                { label: "Q.8", description: "Solve equations involving like terms" },
                { label: "Q.9", description: "Solve equations: complete the solution" }
            ]
        },
        {
            title: "Integers",
            items: [
                { label: "B.1", description: "Understanding integers" },
                { label: "B.2", description: "Integers on number lines" },
                { label: "B.3", description: "Graph integers on horizontal and vertical  " },
                { label: "B.4", description: "Absolute value and opposite integers" },
                { label: "B.5", description: "Compare and order integers" }
            ]
        },
        {
            title: "Ratios, Rates, and Proportions",
            items: [
                { label: "J.1", description: "Understanding ratios" },
                { label: "J.2", description: "Identify equivalent ratios" },
                { label: "J.3", description: "Write an equivalent ratio" },
                { label: "J.4", description: "Equivalent ratios: word problems" },
                { label: "J.5", description: "Unit rates" },
                { label: "J.6", description: "Compare ratios: word problems" },
                { label: "J.7", description: "Scale drawings: word problems" },
                { label: "J.8", description: "Do the ratios form a proportion?" },
                { label: "J.9", description: "Do the ratios form a proportion: word problems" },
                { label: "J.10", description: "Solve proportions" },
                { label: "J.11", description: "Solve proportions: word problems" },
                { label: "J.12", description: "Estimate population size using proportions" },
                { label: "J.13", description: "Rate of change" },
                { label: "J.14", description: "Constant rate of change" }
            ]
        },
        {
            title: "Two-dimensional Figures",
            items: [
                { label: "R.1", description: "Identify and classify polygons" },
                { label: "R.2", description: "Name, measure and classify angles" },
                { label: "R.3", description: "Classify triangles" },
                { label: "R.4", description: "Identify trapeziums" },
                { label: "R.5", description: "Classify quadrilaterals" },
                { label: "R.6", description: "Graph triangles and quadrilaterals" },
                { label: "R.7", description: "Triangle angle-sum property" },
                { label: "R.8", description: "Exterior angle property" },
                { label: "R.9", description: "Find missing angles in triangles and " },
                { label: "R.10", description: "Interior angles of polygons" },
                { label: "R.11", description: "Lines, line segments and half lines" },
                { label: "R.12", description: "Parallel, perpendicular and intersecting lines" },
                { label: "R.13", description: "Identify complementary, supplementary," },
                { label: "R.14", description: "Find measures of complementary," },
                { label: "R.15", description: "Transversal of parallel lines" },
                { label: "R.16", description: "Find lengths and measures of bisected line " },
                { label: "R.17", description: "Parts of a circle" },
                { label: "R.18", description: "Symmetry" }
            ]
        },
        {
            title: "Decimals",
            items: [
                { label: "D.1", description: "Decimal numbers review" },
                { label: "D.2", description: "Compare and order decimals" },
                { label: "D.3", description: "Decimal number lines" },
                { label: "D.4", description: "Round decimals" }
            ]
        },
        {
            title: "Consumer Maths",
            items: [
                { label: "L.1", description: "Add, subtract, multiply and divide money amounts:" },
                { label: "L.2", description: "Price lists" },
                { label: "L.3", description: "Unit prices" },
                { label: "L.4", description: "Unit prices: find the total price" },
                { label: "L.5", description: "Percent of a number, discount and more" },
                { label: "L.6", description: "Find the percent: discount and mark-up" },
                { label: "L.7", description: "Sale prices: find the original price" },
                { label: "L.8", description: "Multi-step problems with percents" },
                { label: "L.9", description: "Estimate tips" },
                { label: "L.10", description: "Simple interest" }
            ]
        },
        {
            title: "Congruence and Similarity",
            items: [
                { label: "S.1", description: "Similar and congruent figures" },
                { label: "S.2", description: "Side lengths and angle measures of congruent " },
                { label: "S.3", description: "Congruence statements and corresponding parts" },
                { label: "S.4", description: "Side lengths and angle measures of similar " },
                { label: "S.5", description: "Similar figures and indirect measurement" }
            ]
        },
        {
            title: "Operations with Decimals",
            items: [
                { label: "E.1", description: "Add and subtract decimals" },
                { label: "E.2", description: "Add and subtract decimals: word problems" },
                { label: "E.3", description: "Multiply decimals" },
                { label: "E.4", description: "Multiply decimals and whole numbers: word " },
                { label: "E.5", description: "Divide decimals" },
                { label: "E.6", description: "Divide decimals by whole numbers: word " },
                { label: "E.7", description: "Estimate sums, differences and products of " },
                { label: "E.8", description: "Add, subtract, multiply and divide decimals:  " },
                { label: "E.9", description: "Multi-step inequalities with decimals" },
                { label: "E.10", description: "Maps with decimal distances" },
                { label: "E.11", description: "Evaluate numerical expressions involving " }
            ]
        },
        {
            title: "Problem Solving and Estimation",
            items: [
                { label: "M.1", description: "Estimate to solve word problems" },
                { label: "M.2", description: "Multi-step word problems" },
                { label: "M.3", description: "Guess-and-check word problems" },
                { label: "M.4", description: "Use Venn diagrams to solve problems" },
                { label: "M.5", description: "Find the number of each type of coin" },
                { label: "M.6", description: "Elapsed time word problems" }
            ]
        },
        {
            title: "Units of Measurement",
            items: [
                { label: "N.1", description: "Estimate metric measurements" },
                { label: "N.2", description: "Compare and convert metric units" },
                { label: "N.3", description: "Metric mixed units" },
                { label: "N.4", description: "Convert square and cubic units of length" },
                { label: "N.5", description: "Convert between cubic metres and litres" },
                { label: "N.6", description: "Precision" }
            ]
        },
        {
            title: "Pythagoras' Theorem",
            items: [
                { label: "U.1", description: "Pythagoras' theorem: find the length of the " },
                { label: "U.2", description: "Pythagoras' theorem: find the missing leg length" },
                { label: "U.3", description: "Pythagoras' theorem: word problems" },
                { label: "U.4", description: "Converse of Pythagoras' theorem: is it a right ?" }
            ]
        },
        {
            title: "Three-Dimensional Figures",
            items: [
                { label: "V.1", description: "Bases of three-dimensional figures" },
                { label: "V.2", description: "Nets of three-dimensional figures" },
                { label: "V.3", description: "Front, side and top view" }
            ]
        },
        {
            title: "Expressions and Properties",
            items: [
                { label: "P.1", description: "Write variable expressions" },
                { label: "P.2", description: "Write variable expressions: word problems" },
                { label: "P.3", description: "Evaluate linear expressions" },
                { label: "P.4", description: "Evaluate multi-variable expressions" },
                { label: "P.5", description: "Evaluate absolute value expressions" },
                { label: "P.6", description: "Evaluate nonlinear expressions" },
                { label: "P.7", description: "Identify terms and coefficients" },
                { label: "P.8", description: "Sort factors of expressions" },
                { label: "P.9", description: "Properties of addition and multiplication" },
                { label: "P.10", description: "Multiply using the distributive property" },
                { label: "P.11", description: "Solve equations using properties" },
                { label: "P.12", description: "Write equivalent expressions using properties" },
                { label: "P.13", description: "Add and subtract like terms" },
                { label: "P.14", description: "Add, subtract and multiply linear expressions" },
                { label: "P.15", description: "Factors of linear expressions" },
                { label: "P.16", description: "Identify equivalent linear expressions" }
            ]
        },
        {
            title: "Operations with Fractions",
            items: [
                { label: "G.1", description: "Add and subtract fractions" },
                { label: "G.2", description: "Add and subtract fractions: word problems" },
                { label: "G.3", description: "Add and subtract mixed numbers" },
                { label: "G.4", description: "Add and subtract mixed numbers: word " },
                { label: "G.5", description: "Inequalities with addition and subtraction of" },
                { label: "G.6", description: "Estimate sums and differences of mixed " },
                { label: "G.7", description: "Multiply fractions and whole numbers" },
                { label: "G.8", description: "Multiply two fractions using models" },
                { label: "G.9", description: "Multiply fractions" },
                { label: "G.10", description: "Multiply mixed numbers" },
                { label: "G.11", description: "Multiply fractions and mixed numbers: word " },
                { label: "G.12", description: "Divide whole numbers and unit fractions" },
                { label: "G.13", description: "Divide fractions" },
                { label: "G.14", description: "Divide mixed numbers" },
                { label: "G.15", description: "Divide fractions and mixed numbers: word " },
                { label: "G.16", description: "Estimate products and quotients of fractions " },
                { label: "G.17", description: "Add, subtract, multiply and divide fractions and " },
                { label: "G.18", description: "Evaluate numerical expressions involving " }
            ]
        },
        {
            title: "Expressions and Properties",
            items: [
                { label: "P.1", description: "Write variable expressions" },
                { label: "P.2", description: "Write variable expressions: word problems" },
                { label: "P.3", description: "Evaluate linear expressions" },
                { label: "P.4", description: "Evaluate multi-variable expressions" },
                { label: "P.5", description: "Evaluate absolute value expressions" },
                { label: "P.6", description: "Evaluate nonlinear expressions" },
                { label: "P.7", description: "Identify terms and coefficients" },
                { label: "P.8", description: "Sort factors of expressions" },
                { label: "P.9", description: "Properties of addition and multiplication" },
                { label: "P.10", description: "Multiply using the distributive property" },
                { label: "P.11", description: "Solve equations using properties" },
                { label: "P.12", description: "Write equivalent expressions using properties" },
                { label: "P.13", description: "Add and subtract like terms" },
                { label: "P.14", description: "Add, subtract and multiply linear expressions" },
                { label: "P.15", description: "Factors of linear expressions" },
                { label: "P.16", description: "Identify equivalent linear expressions" }
            ]
        },
        {
            title: "Statistics",
            items: [
                { label: "Y.1", description: "Calculate mean, median, mode and range" },
                { label: "Y.2", description: "Interpret charts to find mean, median, mode and " },
                { label: "Y.3", description: "Mean, median, mode and range: find the missing " },
                { label: "Y.4", description: "Changes in mean, median, mode and range" }
            ]
        },
        {
            title: "Probability",
            items: [
                { label: "Z.1", description: "Probability of simple events" },
                { label: "Z.2", description: "Probability of opposite, mutually exclusive and overlapping events" },
                { label: "Z.3", description: "Experimental probability" },
                { label: "Z.4", description: "Make predictions" },
                { label: "Z.5", description: "Compound events: find the number of outcomes" },
                { label: "Z.6", description: "Counting principle" }
            ]
        }

    ];

    const menuItems = [
        { label: 'L', text: 'Lower kindergarten' },
        { label: 'U', text: 'Upper kindergarten' },
        { label: 'I', text: 'Level I' },
        { label: 'II', text: 'Level II' },
        { label: 'III', text: 'Level III' },
        { label: 'IV', text: 'Level IV' },
        { label: 'V', text: 'Level V' },
        { label: 'VI', text: 'Level VI' },
        { label: 'VII', text: 'Level VII' },
        { label: 'VIII', text: 'Level VIII' },
    ];

    return (
        <div className="maths-container">
            <aside className="menu">
                {menuItems.map((item, index) => (
                    <div
                        key={index}
                        className={`menu-item menu-item-${item.label}`}
                        data-text={item.text}
                    >
                        {item.label}
                    </div>
                ))}
            </aside>
            <div className="content-maths">
                {/* ... (keep the rest of the content as is) */}

                <div className="category intro">
                    <h3>Class-vii maths</h3>
                    <p>Here is a list of all of the maths skills students learn in UKG! These skills are organised into categories, and you can move your mouse over any skill name to preview the skill. To start practising, just click on any link. STEM WORLD will track your score, and the questions will automatically increase in difficulty as you improve!</p>
                </div>
                <div className="category-list">
                    {categories.map((category, index) => (
                        <div key={index} className="category">
                            <h3>{category.title}</h3>
                            <ul>
                                {category.items.map((item, i) => (
                                    <li key={i}>
                                        <span className="item-label">{item.label}</span>
                                        <span className="item-description">{item.description}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Classvii;