import React from 'react'
import Header_ixl from '../Header_IXL/header_ixl'
import '../Class-1/class-1.css';

function Class9() {
    return (
        <div className='class_1'>
            <div className="container">

                <Header_ixl />

                <div className="main-content">
                    <div className="left-content">
                        <h3>India</h3>
                        <h4>Skills available for India class IX maths Curriculum</h4>
                        <div className="description">
                            Objectives are in black and STEM WORLD maths skills are in dark green. Hold your mouse over the name of a skill to view a sample question. Click on the name of a skill to practise that skill.
                        </div>
                    </div>

                    <div className="right-content">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
                            alt="India Flag"
                            className="india-flag"
                        />
                    </div>
                </div>

                <div style={{ display: "flex", width: "100%", marginTop: "20px", padding: "15px" }}>
                    <div style={{ width: "75%", background: "#f65314", padding: "20px" }}>
                        <h6>Showing alignment for: CISCE Class IX</h6>
                        <p>National Council of Education Research and Training Syllabus :Class IX</p>
                    </div>
                    <div style={{ width: "25%", background: "gray", padding: "20px" }}>
                        <h6>Actions</h6>
                        <p>Print curriculum</p>
                    </div>
                </div>
            </div>

            <div style={{ marginLeft: "110px" }}>
                <h3 style={{ color: "red" }}>9.I Number Systems</h3>
                <ul style={{ listStyle: "none" }}>
                    <li><h6>9.I.1 Real Numbers</h6>
                        <ul style={{ listStyle: "none" }}>
                            <li><h6>9.I.1.1 Review of representation of natural numbers, integers, rational numbers on the number line. Representation of terminating/non-terminating recurring decimals, on the number line through successive magnification. Rational numbers as recurring/terminating decimals.</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Classify numbers (IX-A.1)</li>
                                    <li>Compare and order rational numbers (IX-A.2)</li>
                                    <li>Number lines (IX-A.3)</li>
                                    <li>Convert between decimals and fractions (IX-A.4)</li>
                                    <li>Square roots (IX-A.5)</li>
                                    <li>Cube roots (IX-A.6)</li>
                                </ul>
                            </li>
                        </ul>
                    </li>


                    <li><h6>9.I.1.2 Examples of nonrecurring/non terminating decimals such as √2, √3, √5 etc. Existence of non-rational numbers (irrational numbers) such as √2, √3 and their representation on the number line. Explaining that every real number is represented by a unique point on the number line, and conversely, every point on the number line represents a unique real number. Existence of √x for a given positive real number x (visual proof to be emphasized). Definition of nth root of a real number.</h6>

                        <ul style={{ color: "green" }}>
                            <li>Number lines (IX-A.3)</li>
                            <li>Roots of integers (IX-CC.1)</li>
                            <li>Roots of rational numbers (IX-CC.2)</li>
                            <li>Find roots using a calculator (IX-CC.3)</li>
                            <li>Nth roots (IX-CC.4)</li>

                        </ul>
                    </li>

                    <li><h6>9.I.1.3 Recall of laws of exponents with integral powers. Rational exponents with positive real bases (to be done by particular cases, allowing learner to arrive at the general laws).</h6>

                        <ul style={{ color: "green" }}>
                            <li>Powers with integer bases (IX-U.1)</li>
                            <li>Powers with decimal and fractional bases (IX-U.2)</li>
                            <li>Negative exponents (IX-U.3)</li>
                            <li>Multiplication with exponents (IX-U.4)</li>
                            <li>Multiplication and division with exponents (IX-U.6)</li>
                            <li>Power rule (IX-U.7)</li>
                            <li>Evaluate expressions using properties of exponents (IX-U.8)</li>
                            <li>Identify equivalent expressions involving exponents (IX-U.9)</li>
                            <li>Evaluate rational exponents (IX-V.1)</li>
                            <li>Multiplication with rational exponents (IX-V.2)</li>
                            <li>Division with rational exponents (IX-V.3)</li>
                            <li>Power rule with rational exponents (IX-V.4)</li>
                            <li>Simplify expressions involving rational exponents I (IX-V.5)</li>
                            <li>Simplify expressions involving rational exponents II (IX-V.6)</li>

                        </ul>

                        <li><h6>9.I.1.4 Rationalisation (with precise meaning) of real numbers of the type (and their combinations) 1/(a + b√x) and 1/(√x + √y) where x and y are natural numbers and a, b are integers.</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Simplify radical expressions using conjugates (IX-CC.11)</li>

                        </ul>
                    </li>

                    <h3 style={{ color: "red" }}>9.II Algebra</h3>
                    <h6>9.II.1 Polynomials</h6>
                    <li><h6>9.II.1.1 Definition of a polynomial in one variable, its coefficients, with examples and counter examples, its terms, zero polynomial. Degree of a polynomial. Constant, linear, quadratic, cubic polynomials; monomials, binomials, trinomials. Factors and multiples. Zeros/roots of a polynomial/equation. State and motivate the Remainder Theorem with examples and analogy to integers. Statement and proof of the Factor Theorem. Factorisation of ax² + bx + c, a ≠ 0 where a, b, c are real numbers, and of cubic polynomials using the Factor Theorem.</h6>

                        <ul style={{ color: "green" }}>
                            <li>Identify monomials (IX-Y.1)</li>
                            <li>Powers of monomials (IX-Y.5)</li>
                            <li>Polynomial vocabulary (IX-Z.1)</li>
                            <li>HCF of monomials (IX-AA.1)</li>
                            <li>Factorise out a monomial (IX-AA.2)</li>
                            <li>Factorise quadratics with leading coefficient 1 (IX-AA.3)</li>
                            <li>Factorise quadratics with other leading coefficients (IX-AA.4)</li>
                            <li>Factorise quadratics: special cases (IX-AA.5)</li>
                            <li>Factorise by grouping (IX-AA.7)</li>
                            <li>Factorise polynomials (IX-AA.8)</li>
                            <li>Characteristics of quadratic equations (IX-BB.1)</li>
                            <li>Solve a quadratic equation using square roots (IX-BB.3)</li>
                            <li>Solve a quadratic equation using the zero product property (IX-BB.4)</li>
                            <li>Solve a quadratic equation by factorising (IX-BB.5)</li>

                        </ul>
                    </li>



                    <li><h6>9.II.1.2 Recall of algebraic expressions and identities. Further identities of the type: (x + y + z)² = x² + y² + z² + 2xy + 2yz + 2zx, (x ± y)³ = x³ ± y³ ± 3xy (x ± y), x³ + y³ + z³ – 3xyz = (x + y + z) (x² + y² + z² – xy – yz – zx) and their use in factorization of polynomials. Simple expressions reducible to these polynomials.</h6>
                        <ul style={{ color: "green" }}>
                            <li>Factorise quadratics: special cases (IX-AA.5)</li>
                        </ul>
                    </li>

                    <div>
                        <h6>9.II.2 Linear Equations in Two Variables</h6>

                        <li><h6>9.II.2.1 Recall of linear equations in one variable. Introduction to the equation in two variables. Prove that a linear equation in two variables has infinitely many solutions, and justify their being written as ordered pairs of real numbers, plotting them and showing that they seem to lie on a line. Examples, problems from real life, including problems on Ratio and Proportion and with algebraic and graphical solutions being done simultaneously.</h6>

                            <ul style={{ color: "green" }}>
                                <li>Model and solve equations using algebra tiles (IX-N.1)</li>
                                <li>Write and solve equations that represent diagrams (IX-N.2)</li>
                                <li>Solve one-step linear equations (IX-N.3)</li>
                                <li>Solve two-step linear equations (IX-N.4)</li>
                                <li>Solve advanced linear equations (IX-N.5)</li>
                                <li>Solve equations with variables on both sides (IX-N.6)</li>
                                <li>Solve equations: complete the solution (IX-N.7)</li>
                                <li>Find the number of solutions (IX-N.8)</li>
                                <li>Create equations with no solutions or infinitely many solutions (IX-N.9)</li>
                                <li>Solve linear equations: word problems (IX-N.10)</li>
                                <li>Solve linear equations: mixed review (IX-N.11)</li>
                                <li>Identify proportional relationships (IX-S.1)</li>
                                <li>Find the constant of variation (IX-S.2)</li>
                                <li>Graph a proportional relationship (IX-S.3)</li>
                                <li>Write direct variation equations (IX-S.4)</li>
                                <li>Write and solve direct variation equations (IX-S.5)</li>
                                <li>Identify linear equations (IX-T.1)</li>
                                <li>Slope-intercept form: write an equation from a table (IX-T.9)</li>
                                <li>Slope-intercept form: write an equation from a word problem (IX-T.10)</li>
                                <li>Write linear equations to solve word problems (IX-T.12)</li>
                                <li>Write equations in standard form (IX-T.14)</li>
                                <li>Standard form: find x- and y-intercepts (IX-T.15)</li>
                                <li>Standard form: graph an equation (IX-T.16)</li>
                                <li>Equations of horizontal and vertical lines (IX-T.17)</li>
                                <li>Graph a horizontal or vertical line (IX-T.18)</li>
                                <li>Write an equation for a parallel or perpendicular line (IX-T.20)</li>

                            </ul>
                        </li>
                    </div>

                    <h3 style={{ color: "red" }}>9.III Coordinate Geometry</h3>

                    <li><h6>9.III.1 The Cartesian plane, coordinates of a point, names and terms associated with the coordinate plane, notations, plotting points in the plane, graph of linear equations as examples; focus on linear equations of the type ax + by + c = 0 by writing it as y =mx + c and linking with the chapter on linear equations in two variables.</h6>

                        <ul style={{ color: "green" }}>
                            <li>Coordinate plane review (IX-R.1)</li>
                            <li>Quadrants and axes (IX-R.2)</li>
                            <li>Graph a proportional relationship (IX-S.3)</li>
                            <li>Find the slope of a graph (IX-T.2)</li>
                            <li>Find the slope from two points (IX-T.3)</li>
                            <li>Slope-intercept form: find the slope and y-intercept (IX-T.5)</li>
                            <li>Slope-intercept form: graph an equation (IX-T.6)</li>
                            <li>Slope-intercept form: write an equation from a graph (IX-T.7)</li>
                            <li>Slope-intercept form: write an equation (IX-T.8)</li>
                            <li>Compare linear equations, graphs and tables (IX-T.13)</li>
                            <li>Write equations in standard form (IX-T.14)</li>
                            <li>Standard form: find x- and y-intercepts (IX-T.15)</li>
                            <li>Standard form: graph an equation (IX-T.16)</li>
                            <li>Graph a horizontal or vertical line (IX-T.18)</li>
                            <li>Slopes of parallel and perpendicular lines (IX-T.19)</li>
                            <li>Write an equation for a parallel or perpendicular line (IX-T.20)</li>

                        </ul>
                    </li>


                    <h3 style={{ color: "red" }}>9.IV Geometry</h3>
                    <h5>9.IV.1 Introduction to Euclid's Geometry</h5>

                    <li><h6>9.IV.1.1 History – Euclid and geometry in India. Euclid's method of formalizing observed phenomenon into rigorous mathematics with definitions, common/obvious notions, axioms/postulates, and theorems. The five postulates of Euclid. Equivalent versions of the fifth postulate. Showing the relationship between axiom and theorem..</h6>
                        <h6>9.IV.1.1.1 Given two distinct points, there exists one and only one line through them.</h6>

                        <ul>
                            <li>Find the slope from two points (IX-T.3)</li>
                        </ul>
                        <h6>9.IV.1.1.1 Given two distinct points, 9.IV.1.1.2 (Prove) Two distinct lines cannot have more than one point in common.</h6>
                    </li>




                    <h5>9.IV.2 Lines and Angles</h5>

                    <li><h6>9.IV.2.1 If a ray stands on a line, then the sum of the two adjacent angles so formed is 180° and the converse.</h6>

                        <ul style={{ color: "green" }}>
                            <li>Identify complementary, supplementary, vertical, adjacent and congruent angles (IX-F.3)</li>
                            <li>Find measures of complementary, supplementary, vertical and adjacent angles (IX-F.4)</li>
                        </ul>
                    </li>

                    <li><h6>9.IV.2.2 If two lines intersect, the vertically opposite angles are equal.</h6>

                        <ul style={{ color: "green" }}>
                            <li>Transversals: name angle pairs (IX-F.1)</li>
                            <li>Transversals of parallel lines: find angle measures (IX-F.2)</li>
                            <li>Identify complementary, supplementary, vertical, adjacent and congruent angles (IX-F.3)</li>
                            <li>Find measures of complementary, supplementary, vertical and adjacent angles (IX-F.4)</li>
                        </ul>
                    </li>


                    <li><h6>9.IV.2.3 Results on corresponding angles, alternate angles, interior angles when a transversal intersects two parallel lines.</h6></li>
                    <li><h6>9.IV.2.4 Lines, which are parallel to a given line, are parallel.</h6></li>
                    <li><h6>9.IV.2.5 The sum of the angles of a triangle is 180°.</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Triangle angle-sum property (IX-G.2)</li>
                    </ul>


                    <li><h6>9.IV.2.6 If a side of a triangle is produced, the exterior angle so formed is equal to the sum of the two interior opposite angles.</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Exterior angle property (IX-G.3)</li>
                    </ul>

                    <li><h5>9.IV.3 Triangles</h5></li>
                    <li><h6>9.IV.3.1 Two triangles are congruent if any two sides and the included angle of one triangle is equal to any two sides and the included angle of the other triangle (SAS Congruence).</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>SSS, SAS and ASA Theorems (IX-G.8)</li>
                    </ul>


                    <li><h6>9.IV.3.2 Two triangles are congruent if any two angles and the included side of one triangle is equal to any two angles and the included side of the other triangle (ASA Congruence).</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>SSS, SAS and ASA Theorems (IX-G.8)</li>
                    </ul>



                    <li><h6>9.IV.3.3 Two triangles are congruent if the three sides of one triangle are equal to three sides of the other triangle (SSS Congruence).</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>SSS, SAS and ASA Theorems (IX-G.8)</li>
                    </ul>


                    <li><h6>9.IV.3.4 Two right triangles are congruent if the hypotenuse and a side of one triangle are equal (respectively) to the hypotenuse and a side of the other triangle.</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Hypotenuse-Leg Theorem (IX-G.11)</li>
                    </ul>




                    <li><h6>9.IV.3.5 The angles opposite to equal sides of a triangle are equal.</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Congruency in isosceles and equilateral triangles (IX-G.10)</li>
                    </ul>




                    <li><h6>9.IV.3.6 The sides opposite to equal angles of a triangle are equal.</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Congruency in isosceles and equilateral triangles (IX-G.10)</li>
                    </ul>


                    <li><h6>9.IV.3.7 Triangle inequalities and relation between 'angle and facing side'; inequalities in a triangle.</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Triangle Inequality Theorem (IX-G.6)</li>
                    </ul>


                    <h5>9.IV.4 Quadrilaterals</h5>
                    <li><h6>9.IV.4.1 The diagonal divides a parallelogram into two congruent triangles.</h6></li>
                    <li><h6>9.IV.4.2 In a parallelogram opposite sides are equal and conversely.</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Properties of parallelograms (IX-H.3)</li>
                        <li>Proving a quadrilateral is a parallelogram (IX-H.4)</li>
                    </ul>


                    <li><h6>9.IV.4.3 In a parallelogram opposite angles are equal and conversely.</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Properties of parallelograms (IX-H.3)</li>
                        <li>Proving a quadrilateral is a parallelogram (IX-H.4)</li>
                    </ul>

                    <li><h6>9.IV.4.4 A quadrilateral is a parallelogram if a pair of its opposite sides is parallel and equal.</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Properties of parallelograms (IX-H.3)</li>
                        <li>Proving a quadrilateral is a parallelogram (IX-H.4)</li>
                    </ul>

                    <li><h6>9.IV.4.5 In a parallelogram, the diagonals bisect each other and conversely..</h6></li>
                    <li><h6>9.IV.4.6 In a triangle, the line segment joining the mid points of any two sides is parallel to the third side and (motivate) its converse..</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Midsegments of triangles (IX-G.7)</li>
                    </ul>


                    <li><h6>9.IV.5 Review concept of area, recall area of a rectangle.</h6></li>
                    <li><h6>9.IV.5.1 Parallelograms on the same base and between the same parallels have the same area.</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Area of rectangles and squares (IX-J.2)</li>
                        <li>Area of parallelograms and triangles (IX-J.3)</li>
                        <li>Area and perimeter mixed review (IX-J.8)</li>
                    </ul>


                    <li><h6>9.IV.5.2 Triangles on the same base and between the same parallels are equal in area and its converse..</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Area of parallelograms and triangles (IX-J.3)</li>
                    </ul>


                    <li><h6>9.IV.6 Through examples, arrive at definitions of circle related concepts, radius, circumference, diameter, chord, arc, subtended angle.</h6></li>
                    <li><h6>9.IV.6.1 Equal chords of a circle subtend equal angles at the centre and (motivate) its converse..</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Arcs and chords (IX-L.6)</li>
                    </ul>



                    <li><h6>9.IV.6.2 The perpendicular from the centre of a circle to a chord bisects the chord and conversely, the line drawn through the centre of a circle to bisect a chord is perpendicular to the chord..</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Arcs and chords (IX-L.6)</li>
                    </ul>


                    <li><h6>9.IV.6.3 There is one and only one circle passing through three given non-collinear points..</h6></li>
                    <li><h6>9.IV.6.4 Equal chords of a circle (or of congruent circles) are equidistant from the centre(s) and conversely.</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Arcs and chords (IX-L.6)</li>
                    </ul>



                    <li><h6>9.IV.6.5 The angle subtended by an arc at the centre is double the angle subtended by it at any point on the remaining part of the circle.</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Central angles (IX-L.2)</li>
                    </ul>


                    <li><h6>9.IV.6.6 Angles in the same segment of a circle are equal.</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Arc measure and arc length (IX-L.3)</li>
                    </ul>


                    <li><h6>9.IV.6.7 If a line segment joining two points subtends equal angle at two other points lying on the same side of the line containing the segment, the four points lie on a circle.</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Arcs and chords (IX-L.6)</li>
                    </ul>



                    <li><h6>9.IV.6.8 The sum of the either pair of the opposite angles of a cyclic quadrilateral is 180° and its converse.</h6></li>

                    <h5>9.IV.7 Constructions</h5>
                    <li><h6>9.IV.7.1 Construction of bisectors of a line segment and angle, 60°, 90°, 45° angles etc, equilateral triangles.</h6></li>
                    <ul style={{ color: 'green' }}>
                        <li>Construct the midpoint or perpendicular bisector of a segment (IX-M.1)</li>
                        <li>Construct an angle bisector (IX-M.2)</li>
                        <li>Construct an equilateral triangle or regular hexagon (IX-M.4)</li>
                    </ul>

                    <li><h6>9.IV.7.2 Construction of a triangle given its base, sum/difference of the other two sides and one base angle.</h6></li>
                    <li><h6>9.IV.7.3 Construction of a triangle of given perimeter and base angles..</h6></li>

                    <h3 style={{ color: "red" }}>9.V Mensuration</h3>

                    <li><h6>9.V.1 Area of a triangle using Heron's formula (without proof) and its application in finding the area of a quadrilateral.</h6>

                        <ul style={{ color: "green" }}>
                            <li>Heron's formula (IX-J.9)</li>
                        </ul>
                        <h6>9.V.2 Surface areas and volumes of cubes, cuboids, spheres (including hemispheres) and right circular cylinders/cones..</h6>
                        <ul style={{ color: "green" }}>
                            <li>Introduction to surface area and volume (IX-K.1)</li>
                            <li>Surface area of prisms and cylinders (IX-K.2)</li>
                            <li>Surface area of cones (IX-K.3)</li>
                            <li>Volume of prisms and cylinders (IX-K.4)</li>
                            <li>Volume of cones (IX-K.5)</li>
                            <li>Surface area and volume of spheres (IX-K.6)</li>
                            <li>Surface area and volume review (IX-K.7)</li>
                        </ul>
                    </li>


                    <h3 style={{ color: "red" }}>9.VI Statistics and Probability</h3>
                    <h5>9.VI.1 Statistics</h5>

                    <li><h6>9.VI.1.1 Introduction to Statistics: Collection of data, presentation of data – tabular form, ungrouped/ grouped, bar graphs, histograms (with varying base lengths), frequency polygons, qualitative analysis of data to choose the correct form of presentation for the collected data. Mean, median, mode of ungrouped data.</h6>
                        <ul style={{color:"green"}}>
                            <li>Interpret bar graphs, line graphs and histograms (IX-O.1)</li>
                            <li>Create bar graphs, line graphs and histograms (IX-O.2)</li>
                            <li>Interpret pie charts (IX-O.3)</li>
                            <li>Interpret stem-and-leaf plots (IX-O.4)</li>
                        </ul>
                    </li>


                    <h5>9.VI.2 Probability</h5>

                    <li><h6>9.VI.2.1 History, Repeated experiments and observed frequency approach to probability. Focus is on empirical probability. (A large amount of time to be devoted to group and to individual activities to motivate the concept; the experiments to be drawn from real-life situations, and from examples used in the chapter on statistics).</h6>
                        <ul style={{color:"green"}}>
                            <li>Theoretical probability (IX-EE.1)</li>
                            <li>Experimental probability (IX-EE.2)</li>
                            <li>Compound events: find the number of outcomes (IX-EE.3)</li>
                            <li>Identify independent and dependent events (IX-EE.4)</li>
                            <li>Probability of independent and dependent events (IX-EE.5)</li>
                        </ul>
                    </li>
                </ul>


            </div>






        </div >
    )
}

export default Class9









