
import React from 'react';
//  import './class.css';

const Classix = () => {
    const categories = [
        {
            title: "Numbers",
            items: [
                { label: "A.1", description: "Classify numbers" },
                { label: "A.2", description: "Compare and order rational numbers" },
                { label: "A.3", description: "Number lines" },
                { label: "A.4", description: "Convert between decimals and fractions" },
                { label: "A.5", description: "Square roots" },
                { label: "A.6", description: "Cube roots" }
            ]
        },
        {
            title: "Circles",
            items: [
                { label: "L.1", description: "Parts of a circle" },
                { label: "L.2", description: "Central angles" },
                { label: "L.3", description: "Arc measure and arc length" },
                { label: "L.4", description: "Area of sectors" },
                { label: "L.5", description: "Circle measurements: mixed review" },
                { label: "L.6", description: "Arcs and chords" },
                { label: "L.7", description: "Tangent lines" },
                { label: "L.8", description: "Perimeter of polygons with an inscribed circle" },
                { label: "L.9", description: "Inscribed angles" },
                { label: "L.10", description: "Angles in inscribed right triangles" },
                { label: "L.11", description: "Angles in inscribed quadrilaterals" }
            ]
        },
        {
            title: "Rational exponents",
            items: [
                { label: "V.1", description: "Evaluate rational exponents" },
                { label: "V.2", description: "Multiplication with rational exponents" },
                { label: "V.3", description: "Division with rational exponents" },
                { label: "V.4", description: "Power rule with rational exponents" },
                { label: "V.5", description: "Simplify expressions involving rational" },
                { label: "V.6", description: "Simplify expressions involving rational " }
            ]
        },
        {
            title: "Operations",
            items: [
                { label: "B.1", description: "Add, subtract, multiply and divide integers" },
                { label: "B.2", description: "Evaluate numerical expressions involving " },
                { label: "B.3", description: "Evaluate variable expressions involving integers" },
                { label: "B.4", description: "Add and subtract rational numbers" },
                { label: "B.5", description: "Multiply and divide rational numbers" },
                { label: "B.6", description: "Evaluate numerical expressions involving  " },
                { label: "B.7", description: "Evaluate variable expressions involving rational " }
            ]
        },
        {
            title: "Constructions",
            items: [
                { label: "M.1", description: "Construct the midpoint or perpendicular bisector" },
                { label: "M.2", description: "Construct an angle bisector" },
                { label: "M.3", description: "Construct a congruent angle" },
                { label: "M.4", description: "Construct an equilateral triangle or regular " }
            ]
        },
        {
            title: "Solve equations",
            items: [
                { label: "N.1", description: "Model and solve equations using algebra tiles" },
                { label: "N.2", description: "Write and solve equations that represent " },
                { label: "N.3", description: "Solve one-step linear equations" },
                { label: "N.4", description: "Solve two-step linear equations" },
                { label: "N.5", description: "Solve advanced linear equations" },
                { label: "N.6", description: "Solve equations with variables on both sides" },
                { label: "N.7", description: "Solve equations: complete the solution" },
                { label: "N.8", description: "Find the number of solutions" },
                { label: "N.9", description: "Create equations with no solutions or infinitely" },
                { label: "N.10", description: "Solve linear equations: word problems" },
                { label: "N.11", description: "Solve linear equations: mixed review" }
            ]
        },

        {
            title: "Logarithms",
            items: [
                { label: "W.1", description: "Convert between exponential and logarithmic" },
                { label: "W.2", description: "Evaluate logarithms" },
                { label: "W.3", description: "Change of base formula" },
                { label: "W.4", description: "Identify properties of logarithms" },
                { label: "W.5", description: "Product property of logarithms" },
                { label: "W.6", description: "Quotient property of logarithms" },
                { label: "W.7", description: "Power property of logarithms" },
                { label: "W.8", description: "Properties of logarithms: mixed review" },
                { label: "W.9", description: "Evaluate logarithms: mixed review" }
            ]
        },
        {
            title: "Scientific notation",
            items: [
                { label: "X.1", description: "Convert between standard and scientific " },
                { label: "X.2", description: "Compare numbers written in scientific notation" },
                { label: "X.3", description: "Multiply numbers written in scientific notation" },
                { label: "X.4", description: "Divide numbers written in scientific notation" }
            ]
        },
        {
            title: "Measurement",
            items: [
                { label: "E.1", description: "Convert rates and measurements" },
                { label: "E.2", description: "Precision" },
                { label: "E.3", description: "Greatest possible error" },
                { label: "E.4", description: "Minimum and maximum area and volume" },
                { label: "E.5", description: "Percent error" },
                { label: "E.6", description: "Percent error: area and volume" }
            ]
        },
        {
            title: "Lines and angles",
            items: [
                { label: "F.1", description: "Transversals: name angle pairs" },
                { label: "F.2", description: "Transversals of parallel lines: find angle " },
                { label: "F.3", description: "Identify complementary, supplementary, vertical,  " },
                { label: "F.4", description: "Find measures of complementary, " }
            ]
        },
        {
            title: "Problem solving",
            items: [
                { label: "P.1", description: "Word problems: mixed review" },
                { label: "P.2", description: "Word problems with money" },
                { label: "P.3", description: "Consecutive integer problems" },
                { label: "P.4", description: "Rate of travel: word problems" },
                { label: "P.5", description: "Weighted averages: word problems" }
            ]
        },
        {
            title: "Logic",
            items: [
                { label: "Q.1", description: "Identify hypotheses and conclusions" },
                { label: "Q.2", description: "Counterexamples" }
            ]
        },
        {
            title: "Problem solving",
            items: [
                { label: "P.1", description: "Word problems: mixed review" },
                { label: "P.2", description: "Word problems with money" },
                { label: "P.3", description: "Consecutive integer problems" },
                { label: "P.4", description: "Rate of travel: word problems" },
                { label: "P.5", description: "Weighted averages: word problems" }
            ]
        },
        {
            title: "Logic",
            items: [
                { label: "Q.1", description: "Identify hypotheses and conclusions" },
                { label: "Q.2", description: "Counterexamples" }
            ]
        },
        {
            title: "Coordinate plane",
            items: [
                { label: "R.1", description: "Coordinate plane review" },
                { label: "R.2", description: "Quadrants and axes" }
            ]
        },
        {
            title: "Measurement",
            items: [
                { label: "E.1", description: "Convert rates and measurements" },
                { label: "E.2", description: "Precision" },
                { label: "E.3", description: "Greatest possible error" },
                { label: "E.4", description: "Minimum and maximum area and volume" },
                { label: "E.5", description: "Percent error" },
                { label: "E.6", description: "Percent error: area and volume" }
            ]
        },
        {
            title: "Lines and Angles",
            items: [
                { label: "F.1", description: "Transversals: name angle pairs" },
                { label: "F.2", description: "Transversals of parallel lines: find angle " },
                { label: "F.3", description: "Identify complementary, supplementary, vertical,  " },
                { label: "F.4", description: "Find measures of complementary, " }
            ]
        },
        {
            title: "Triangles",
            items: [
                { label: "G.1", description: "Classify triangles" },
                { label: "G.2", description: "Triangle angle-sum property" },
                { label: "G.3", description: "Exterior angle property" },
                { label: "G.4", description: "Exterior angle inequality" },
                { label: "G.5", description: "Angle-side relationships in triangles" },
                { label: "G.6", description: "Triangle Inequality Theorem" },
                { label: "G.7", description: "Midsegments of triangles" },
                { label: "G.8", description: "SSS, SAS and ASA Theorems" },
                { label: "G.9", description: "SSS Theorem in the coordinate plane" },
                { label: "G.10", description: "Congruency in isosceles and equilateral " },
                { label: "G.11", description: "Hypotenuse-Leg Theorem" }
            ]
        },
        {
            title: "Quadrilaterals",
            items: [
                { label: "H.1", description: "Classify quadrilaterals" },
                { label: "H.2", description: "Graph quadrilaterals" },
                { label: "H.3", description: "Properties of parallelograms" },
                { label: "H.4", description: "Proving a quadrilateral is a parallelogram" },
                { label: "H.5", description: "Properties of rhombuses" },
                { label: "H.6", description: "Properties of squares and rectangles" },
                { label: "H.7", description: "Properties of trapeziums" },
                { label: "H.8", description: "Properties of kites" },
                { label: "H.9", description: "Review: properties of quadrilaterals" }
            ]
        },
        {
            title: "Polygons",
            items: [
                { label: "I.1", description: "Polygon vocabulary" },
                { label: "I.2", description: "Interior angles of polygons" },
                { label: "I.3", description: "Exterior angles of polygons" },
                { label: "I.4", description: "Review: interior and exterior angles of polygons" }
            ]
        },
        {
            title: "Area and Perimeter",
            items: [
                { label: "J.1", description: "Perimeter" },
                { label: "J.2", description: "Area of rectangles and squares" },
                { label: "J.3", description: "Area of parallelograms and triangles" },
                { label: "J.4", description: "Area of trapeziums" },
                { label: "J.5", description: "Area and circumference of circles" },
                { label: "J.6", description: "Area of compound figures" },
                { label: "J.7", description: "Area between two shapes" },
                { label: "J.8", description: "Area and perimeter mixed review" },
                { label: "J.9", description: "Heron's formula" }
            ]
        },
        {
            title: "Surface Area and Volume",
            items: [
                { label: "K.1", description: "Introduction to surface area and volume" },
                { label: "K.2", description: "Surface area of prisms and cylinders" },
                { label: "K.3", description: "Surface area of cones" },
                { label: "K.4", description: "Volume of prisms and cylinders" },
                { label: "K.5", description: "Volume of cones" },
                { label: "K.6", description: "Surface area and volume of spheres" },
                { label: "K.7", description: "Surface area and volume review" }
            ]
        },
        {
            title: "Linear Equations",
            items: [
                { label: "T.1", description: "Identify linear equations" },
                { label: "T.2", description: "Find the slope of a graph" },
                { label: "T.3", description: "Find the slope from two points" },
                { label: "T.4", description: "Find a missing coordinate using slope" },
                { label: "T.5", description: "Slope-intercept form: find the slope and " },
                { label: "T.6", description: "Slope-intercept form: graph an equation" },
                { label: "T.7", description: "Slope-intercept form: write an equation from a " },
                { label: "T.8", description: "Slope-intercept form: write an equation" },
                { label: "T.9", description: "Slope-intercept form: write an equation from a " },
                { label: "T.10", description: "Slope-intercept form: write an equation from a  " },
                { label: "T.11", description: "Linear equations: solve for y" },
                { label: "T.12", description: "Write linear equations to solve word problems" },
                { label: "T.13", description: "Compare linear equations, graphs and tables" },
                { label: "T.14", description: "Write equations in standard form" },
                { label: "T.15", description: "Standard form: find x- and y-intercepts" },
                { label: "T.16", description: "Standard form: graph an equation" },
                { label: "T.17", description: "Equations of horizontal and vertical lines" },
                { label: "T.18", description: "Graph a horizontal or vertical line" },
                { label: "T.19", description: "Slopes of parallel and perpendicular lines" },
                { label: "T.20", description: "Write an equation for a parallel or  " }
            ]
        },
        {
            title: "Factorising",
            items: [
                { label: "AA.1", description: "HCF of monomials" },
                { label: "AA.2", description: "Factorise out a monomial" },
                { label: "AA.3", description: "Factorise quadratics with leading coefficient 1" },
                { label: "AA.4", description: "Factorise quadratics with other leading coefficients" },
                { label: "AA.5", description: "Factorise quadratics: special cases" },
                { label: "AA.6", description: "Factorise quadratics using algebra tiles" },
                { label: "AA.7", description: "Factorise by grouping" },
                { label: "AA.8", description: "Factorise polynomials" }
            ]
        },
        {
            title: "Quadratic equations",
            items: [
                { label: "BB.1", description: "Characteristics of quadratic equations" },
                { label: "BB.2", description: "Complete a table: quadratic equations" },
                { label: "BB.3", description: "Solve a quadratic equation using square roots" },
                { label: "BB.4", description: "Solve a quadratic equation using the zero  " },
                { label: "BB.5", description: "Solve a quadratic equation by factorising" },
                { label: "BB.6", description: "Solve a quadratic equation using the quadratic " }
            ]
        },
        {
            title: "Radical expressions",
            items: [
                { label: "CC.1", description: "Roots of integers" },
                { label: "CC.2", description: "Roots of rational numbers" },
                { label: "CC.3", description: "Find roots using a calculator" },
                { label: "CC.4", description: "Nth roots" },
                { label: "CC.5", description: "Simplify radical expressions with variables I" },
                { label: "CC.6", description: "Simplify radical expressions with variables II" },
                { label: "CC.7", description: "Multiply radical expressions" },
                { label: "CC.8", description: "Divide radical expressions" },
                { label: "CC.9", description: "Add and subtract radical expressions" },
                { label: "CC.10", description: "Simplify radical expressions using the distributive " },
                { label: "CC.11", description: "Simplify radical expressions using conjugates" }
            ]
        },
        {
            title: "Rational expressions",
            items: [
                { label: "DD.1", description: "Simplify complex fractions" },
                { label: "DD.2", description: "Simplify rational expressions" },
                { label: "DD.3", description: "Multiply and divide rational expressions" },
                { label: "DD.4", description: "Divide polynomials" },
                { label: "DD.5", description: "Add and subtract rational expressions" },
                { label: "DD.6", description: "Solve rational equations" }
            ]
        },
        {
            title: "Probability",
            items: [
                { label: "EE.1", description: "Theoretical probability" },
                { label: "EE.2", description: "Experimental probability" },
                { label: "EE.3", description: "Compound events: find the number of " },
                { label: "EE.4", description: "Identify independent and dependent " },
                { label: "EE.5", description: "Probability of independent and dependent " },
                { label: "EE.6", description: "Factorials" },
                { label: "EE.7", description: "Counting principle" }
            ]
        },
        {
            title: "Statistics",
            items: [
                { label: "FF.1", description: "Mean, median, mode and range" },
                { label: "FF.2", description: "Quartiles" },
                { label: "FF.3", description: "Identify biased samples" },
                { label: "FF.4", description: "Variance and standard deviation" }
            ]
        },
        {
            title: "Coordinate Plane",
            items: [
                { label: "R.1", description: "Coordinate plane review" },
                { label: "R.2", description: "Quadrants and axes" }
            ]
        },
        {
            title: "Direct Variation",
            items: [
                { label: "S.1", description: "Identify proportional relationships" },
                { label: "S.2", description: "Find the constant of variation" },
                { label: "S.3", description: "Graph a proportional relationship" },
                { label: "S.4", description: "Write direct variation equations" },
                { label: "S.5", description: "Write and solve direct variation equations" }
            ]
        },
        {
            title: "Linear Equations",
            items: [
                { label: "T.1", description: "Identify linear equations" },
                { label: "T.2", description: "Find the slope of a graph" },
                { label: "T.3", description: "Find the slope from two points" },
                { label: "T.4", description: "Find a missing coordinate using slope" },
                { label: "T.5", description: "Slope-intercept form: find the slope and " },
                { label: "T.6", description: "Slope-intercept form: graph an equation" },
                { label: "T.7", description: "Slope-intercept form: write an equation from a" },
                { label: "T.8", description: "Slope-intercept form: write an equation" },
                { label: "T.9", description: "Slope-intercept form: write an equation from a " },
                { label: "T.10", description: "Slope-intercept form: write an equation from a " },
                { label: "T.11", description: "Linear equations: solve for y" },
                { label: "T.12", description: "Write linear equations to solve word problems" },
                { label: "T.13", description: "Compare linear equations, graphs and tables" },
                { label: "T.14", description: "Write equations in standard form" },
                { label: "T.15", description: "Standard form: find x- and y-intercepts" },
                { label: "T.16", description: "Standard form: graph an equation" },
                { label: "T.17", description: "Equations of horizontal and vertical lines" },
                { label: "T.18", description: "Graph a horizontal or vertical line" },
                { label: "T.19", description: "Slopes of parallel and perpendicular lines" },
                { label: "T.20", description: "Write an equation for a parallel or" }
            ]
        },
        {
            title: "Exponents",
            items: [
                { label: "U.1", description: "Powers with integer bases" },
                { label: "U.2", description: "Powers with decimal and fractional bases" },
                { label: "U.3", description: "Negative exponents" },
                { label: "U.4", description: "Multiplication with exponents" },
                { label: "U.5", description: "Division with exponents" },
                { label: "U.6", description: "Multiplication and division with exponents" },
                { label: "U.7", description: "Power rule" },
                { label: "U.8", description: "Evaluate expressions using properties of" },
                { label: "U.9", description: "Identify equivalent expressions involving " }
            ]
        }

    ];

    const menuItems = [
        { label: 'L', text: 'Lower kindergarten' },
        { label: 'U', text: 'Upper kindergarten' },
        { label: 'I', text: 'Level I' },
        { label: 'II', text: 'Level II' },
        { label: 'III', text: 'Level III' },
        { label: 'IV', text: 'Level IV' },
        { label: 'V', text: 'Level V' },
        { label: 'VI', text: 'Level VI' },
        { label: 'VII', text: 'Level VII' },
        { label: 'VIII', text: 'Level VIII' },
    ];

    return (
        <div className="maths-container">
            <aside className="menu">
                {menuItems.map((item, index) => (
                    <div
                        key={index}
                        className={`menu-item menu-item-${item.label}`}
                        data-text={item.text}
                    >
                        {item.label}
                    </div>
                ))}
            </aside>
            <div className="content-maths">
                {/* ... (keep the rest of the content as is) */}

                <div className="category intro">
                    <h3>Class-ix maths</h3>
                    <p>Here is a list of all of the maths skills students learn in UKG! These skills are organised into categories, and you can move your mouse over any skill name to preview the skill. To start practising, just click on any link. STEM WORLD will track your score, and the questions will automatically increase in difficulty as you improve!</p>
                </div>
                <div className="category-list">
                    {categories.map((category, index) => (
                        <div key={index} className="category">
                            <h3>{category.title}</h3>
                            <ul>
                                {category.items.map((item, i) => (
                                    <li key={i}>
                                        <span className="item-label">{item.label}</span>
                                        <span className="item-description">{item.description}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Classix;