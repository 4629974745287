import React from 'react';
import ClassCard from '../../components/ClassCard/ClassCard';
import './ClassesList.css'; // Import the CSS for styling
// import ScrollToTop from '../ScrollToTop/ScrollToTop';
// import TopSection from '../TopSection/TopSection';

const ClassesList = () => {
    const classes = [
        {
            level: 'L',
            title: 'Lower kindergarten',
            includes: ['More', 'Inside and outside', 'Wide and narrow', 'Light and heavy', 'Count 1-rupee coins'],
            skillsCount: 55,
            color: '#A020F0', // purpale
        },
        {
            level: 'U',
            title: 'Upper kindergarten',
            includes: ['Number lines - up to 20', 'Fewer, more and same', 'Put numbers up to 10 in order', 'Identify shapes traced from solids', 'Shapes of everyday objects I'],
            skillsCount: 144,
            color: 'green', // Greens
        },
        {
            level: 'I',
            title: 'Class I',
            includes: ['Includes: Ordinal numbers|Addition facts - sums up to 20|Subtraction facts - numbers up to 10|Select two-dimensional shapes|Count sides and vertices'],
            skillsCount: 164,
            color: '#0000FF', //blue  
        },
        {
            level: 'II',
            title: 'Class II',
            includes: ['Includes: Ordinal numbers up to 100th|Find the next row in a growing pattern|Add multiples of 10|Fact families|Place value models - tens and ones'],
            skillsCount: 151,
            color: 'green', // Green
        },
        {
            level: 'III',
            title: 'Class III',
            includes: ['Includes: Multiplication facts up to 10: select the missing factors|Create line plots|Add and subtract money amounts|Compare and convert metric units of length'],
            skillsCount: 199,
            color: '#A020F0', // blue 
        },
        {
            level: 'IV',
            title: 'Class IV',
            includes: ['Includes: Divisibility rules|Create bar graphs|Number patterns: word problems|Rotational symmetry|Find the probability'],
            skillsCount: 135,
            color: 'green', // green
        },
        {
            level: 'V',
            title: 'Class V',
            includes: ['Includes: Convert fractions to decimals|Create line graphs|Interpret stem-and-leaf plots|Find start and end times: word problems|Nets of three-dimensional figures'],
            skillsCount: 142,
            color: '#0000FF', // blue
        },
        {
            level: 'VI',
            title: 'Class VI',
            includes: ['Includes: Prime factorisation|Unit rates and equivalent rates|Do the ratios form a proportion?|Scale drawings: word problems|Lines, line segments and rays'],
            skillsCount: 168,
            color: '#A020F0', // purpal
        },
        {
            level: 'VII',
            title: 'Class VII',
            includes: ['Includes: Find the percent: discount and mark-up|Estimate tips|Model and solve equations using algebra tiles|Solve one-step equations|Experimental probability'],
            skillsCount: 225,
            color: 'green', // blue
        },
        {
            level: 'VIII',
            title: 'Class VIII',
            includes: ['Includes: Write equations for proportional relationships from tables|Solve two-step equations|Powers of monomials|Square and cube roots of monomials|Multiply polynomials'],
            skillsCount: 229,
            color: '#A020F0', // Lavender
        },
        {
            level: 'IX',
            title: 'Class IX',
            includes: ['Includes: Interior angles of polygons|Herons formula|Product property of logarithms|Factorise by grouping|Simplify rational expressions'],
            skillsCount: 207,
            color: '#0000FF', // Orchid
        },
        {
            level: 'X',
            title: 'Class X',
            includes: ['Includes: Complete the square|Midpoint formula|Triangle Inequality Theorem|Similar triangles and similarity transformations|Geometric probability'],
            skillsCount: 300,
            color: 'green', // Tomato
        },
        {
            level: 'XI',
            title: 'Class XI',
            includes: ['Includes: Graph inequalities|Complex conjugates|Write equations of circles in standard form using properties|Graph circles|Reference anglesIncludes: Find the percent: discount and mark-up|Estimate tips|Model and solve equations using algebra tiles|Solve one-step equations|Experimental probability'],
            skillsCount: 236,
            color: '#A020F0', // Mint Green
        },


    ];



    const maxSkillsCount = Math.max(...classes.map(classItem => classItem.skillsCount));

    const getPath = (skillsCount) => {
        if (skillsCount === 164) {
            return `/skills/${maxSkillsCount}`;
        }

        const paths = {
            55: '/LowerKindergartenMaths',
            144: '/UpperKindergartenMaths',
            164: '/UpperKindergartenMaths',
            207: '/UpperKindergartenMaths',
            300: '/ClassX',
        };

        return paths[skillsCount] || `/${skillsCount}`;
    };
    return (
        <div className="classes-list-container">
            <div className="classes-list-header">
                {/* <TopSection/> */}
                <h1>STEM Maths</h1>
                <p>
                    On STEM WORLD, maths is more than just numbers. With unlimited questions, engaging item types,
                    and real-world scenarios, STEM WORLD helps learners experience maths at its most mesmerising!
                </p>
            </div>


            <div className="classes-list">
                {classes.map((classItem, index) => (
                    <ClassCard
                        key={index}
                        title={classItem.title}
                        level={classItem.level || 'Unspecified'} // Provide a default value if level is not defined
                        includes={classItem.includes}
                        skillsCount={classItem.skillsCount}
                        color={classItem.color}
                    />
                ))}
            </div>
        </div>
    );
};

export default ClassesList;
