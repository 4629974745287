import React from 'react'
import Header_ixl from '../Header_IXL/header_ixl'
// import './class-2.css';

function Class7() {
    return (
        <div>

            <div className="container">

                <Header_ixl />

                <div className="main-content">
                    <div className="left-content">
                        <h3>India</h3>
                        <h4>Skills available for India class VII maths curriculum</h4>
                        <div className="description">
                            Objectives are in black and STEM WORLD maths skills are in dark green. Hold your mouse over the name of a skill to view a sample question. Click on the name of a skill to practise that skill.</div>
                    </div>

                    <div className="right-content">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
                            alt="India Flag"
                            className="india-flag"
                        />
                    </div>
                </div>

                <div style={{ display: "flex", width: "100%", marginTop: "20px", padding: "15px" }}>
                    <div style={{ width: "75%", background: "#f65314", padding: "20px" }}>
                        <h6>Showing alignments for:</h6>
                        <p>National Council of Education Research and Training Syllabus</p>
                    </div>
                    <div style={{ width: "25%", background: "gray", padding: "20px" }}>
                        <h6>Actions</h6>
                        <p>Print curriculum</p>
                    </div>
                </div>
            </div>

            <div style={{ marginLeft: "110px" }}>
                <h3 style={{ color: "red" }}>7.NS Number System</h3>
                <ul style={{ listStyle: "none" }}>
                    <li><h6>7.NS.i Knowing our Numbers: Integers</h6>
                        <ul style={{ listStyle: "none" }}>
                            <li><h6>7.NS.i.1 Multiplication and division of integers (through patterns). Division by zero is meaningless</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Integer multiplication and division rules (VII-C.6)</li>
                                    <li>Multiply and divide integers (VII-C.7)</li>
                                    <li>Complete multiplication and division sentences with integers (VII-C.8)</li>

                                </ul>
                            </li>
                        </ul>
                    </li>


                    <li><h6>7.NS.i.2 Properties of integers (including identities for addition & multiplication, commutative, associative, distributive) (through patterns). These would include examples from whole numbers as well. Involve expressing commutative and associative properties in a general form. Construction of counterexamples, including some by children. Counter examples like subtraction is not commutative.</h6>
                        <ul style={{ color: "green" }}>
                            <li>Properties of addition and multiplication (VII-P.9)</li>
                        </ul>
                    </li>

                    <li><h6>7.NS.i.3 Word problems including integers (all operations)</h6>
                        <ul style={{ color: "green" }}>
                            <li>Add and subtract integers: word problems (VII-C.5)</li>
                        </ul>
                    </li>

                    <h6>7.NS.ii Fractions and rational numbers:</h6>
                    <li><h6>7.NS.ii.1 Multiplication of fractions</h6>
                        <ul style={{ color: "green" }}>
                            <li>Multiply fractions and whole numbers (VII-G.7)</li>
                            <li>Multiply fractions (VII-G.9)</li>
                            <li>Multiply mixed numbers (VII-G.10)</li>
                        </ul>
                    </li>



                    <li><h6>7.NS.ii.2 Fraction as an operator</h6>

                        <ul style={{ color: "green" }}>
                            <li>Multiply fractions and mixed numbers: word problems (VII-G.11)</li>

                        </ul>
                    </li>

                    <div>

                        <li><h6>7.NS.ii.3 Reciprocal of a fraction</h6>
                            <ul style={{ color: "green" }}>
                                <li>Multiplicative inverses (VII-A.3)</li>
                            </ul>
                        </li>
                    </div>

                    <li><h6>7.NS.ii.4 Division of fractions</h6>
                        <ul style={{ color: "green" }}>
                            <li>Divide fractions (VII-G.13)</li>
                            <li>Divide mixed numbers (VII-G.14)</li>

                        </ul>
                    </li>

                    <h6>7.NS.ii.5 Word problems involving mixed fractions</h6>
                    <ul style={{ color: "green" }}>
                        <li>Fractions: word problems with graphs and tables (VII-F.4)</li>
                        <li>Compare fractions: word problems (VII-F.7)</li>
                        <li>Add and subtract mixed numbers (VII-G.3)</li>
                        <li>Multiply fractions and mixed numbers: word problems (VII-G.11)</li>
                        <li>Divide fractions and mixed numbers: word problems (VII-G.15)</li>
                        <li>Add, subtract, multiply and divide fractions and mixed numbers: word problems (VII-G.17)</li>

                    </ul>

                    <li><h6>7.NS.ii.6 Introduction to rational numbers (with representation on number line).</h6>
                        <ul style={{ color: "green" }}>
                            <li>Equivalent fractions (VII-F.2)</li>
                            <li>Write fractions in lowest terms (VII-F.3)</li>
                            <li>Lowest common denominator (VII-F.5)</li>
                            <li>Compare and order fractions (VII-F.6)</li>
                            <li>Convert between mixed numbers and improper fractions (VII-F.8)</li>
                            <li>Compare mixed numbers and improper fractions (VII-F.9)</li>
                            <li>Identify rational numbers (VII-H.1)</li>
                            <li>Compare rational numbers (VII-H.3)</li>
                            <li>Put rational numbers in order (VII-H.4)</li>
                        </ul>
                    </li>




                    <li><h6>7.NS.ii.7 Operations on rational numbers (all operations).</h6>
                        <ul style={{ color: "green" }}>
                            <li>Add and subtract rational numbers (VII-H.5)</li>
                            <li>Multiply and divide rational numbers (VII-H.7)</li>
                        </ul>
                    </li>


                    <li><h6>7.NS.ii.7 Operations on rational numbers (all operations).</h6>
                        <ul style={{ color: "green" }}>
                            <li>Add and subtract rational numbers (VII-H.5)</li>
                            <li>Multiply and divide rational numbers (VII-H.7)</li>
                        </ul>
                    </li>



                    <li><h6>7.NS.ii.8 Representation of rational number as a decimal.</h6>
                        <ul style={{ color: "green" }}>
                            <li>Convert between decimals and fractions or mixed numbers (VII-H.2)</li>
                        </ul>
                    </li>

                    <li><h6>7.NS.ii.9 Word problems on rational numbers (all operations)</h6>
                        <ul style={{ color: "green" }}>
                            <li>Add, subtract, multiply and divide fractions and mixed numbers: word problems (VII-G.17)</li>
                        </ul>
                    </li>

                    <li><h6>7.NS.ii.10 Multiplication and division of decimal fractions</h6>
                        <ul style={{ color: "green" }}>
                            <li>Multiply decimals (VII-E.3)</li>
                            <li>Multiply decimals and whole numbers: word problems (VII-E.4)</li>
                            <li>Divide decimals (VII-E.5)</li>
                            <li>Divide decimals by whole numbers: word problems (VII-E.6)</li>
                        </ul>
                    </li>

                    <li><h6>7.NS.ii.11 Conversion of units (length & mass)</h6>
                        <ul style={{ color: "green" }}>
                            <li>Compare and convert metric units (VII-N.2)</li>
                            <li>Metric mixed units (VII-N.3)</li>
                            <li>Convert square and cubic units of length (VII-N.4)</li>
                            <li>Convert between cubic metres and litres (VII-N.5)</li>
                        </ul>
                    </li>


                    <li><h6>7.NS.ii.12 Word problems (including all operations)</h6>
                        <ul style={{ color: "green" }}>
                            <li>Add and subtract decimals: word problems (VII-E.2)</li>
                            <li>Multiply decimals and whole numbers: word problems (VII-E.4)</li>
                            <li>Divide decimals by whole numbers: word problems (VII-E.6)</li>
                            <li>Add, subtract, multiply and divide decimals: word problems (VII-E.8)</li>
                            <li>Multiply fractions and mixed numbers: word problems (VII-G.11)</li>
                            <li>Divide fractions and mixed numbers: word problems (VII-G.15)</li>
                            <li>Add, subtract, multiply and divide fractions and mixed numbers: word problems (VII-G.17)</li>
                        </ul>
                    </li>


                    <h5>7.NS.iii Powers:</h5>
                    <li><h6>7.NS.iii.1 Exponents only natural numbers.</h6>
                        <ul style={{ color: "green" }}>
                            <li>Understanding exponents (VII-I.1)</li>
                            <li>Evaluate powers (VII-I.2)</li>
                            <li>Solve equations with variable exponents (VII-I.3)</li>

                        </ul>
                    </li>

                    <h5>7.NS.iii.2 Laws of exponents (through observing patterns to arrive at generalisation.)</h5>
                    <li><h6>7.NS.iii.2.i a to the m power x an= a to the m+n power</h6>
                        <ul style={{ color: "green" }}>
                            <li>Multiplication with exponents (VIII-F.8)</li>
                        </ul>
                    </li>


                    <li><h6>7.NS.iii.2.ii (a to the m power)n = a to the mn power</h6>
                        <ul style={{ color: "green" }}>
                            <li>Power rule (VIII-F.11)</li>
                        </ul>
                    </li>


                    <li><h6>7.NS.iii.2.iii a to the m power/an = a to the m–n power, where m–n ∈?</h6>
                        <ul style={{ color: "green" }}>
                            <li>Division with exponents (VIII-F.9)</li>
                            <li>Multiplication and division with exponents (VIII-F.10)</li>
                            <li>Evaluate expressions using properties of exponents (VIII-F.12)</li>
                        </ul>
                    </li>

                    <h3 style={{ color: "red" }}>7.A Algebra</h3>
                    <li><h6>7.A.A Algebraic Expressions</h6>
                        <ul style={{ listStyle: "none" }}>
                            <li><h6>7.A.A.1 Generate algebraic expressions (simple) involving one or two variables</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Write variable expressions for arithmetic sequences (VII-O.7)</li>
                                    <li>Write variable expressions (VII-P.1)</li>
                                    <li>Write variable expressions: word problems (VII-P.2)</li>

                                </ul>
                            </li>

                            <li><h6>7.NS.iii.2.ii (a to the m power)n = a to the mn power</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Identify terms and coefficients (VII-P.7)</li>
                                </ul>
                            </li>


                            <li><h6>7.A.A.3 Like and unlike terms, degree of expressions e.g., x²y etc. (exponent ≤ 3, number of variables)</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Add and subtract like terms (VII-P.13)</li>
                                </ul>
                            </li>


                            <li><h6>7.A.A.4 Addition, subtraction of algebraic expressions (coefficients should be integers).</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Add and subtract like terms (VII-P.13)</li>
                                </ul>
                            </li>

                            <li><h6>7.A.A.5 Simple linear equations in one variable (in contextual problems) with two operations (avoid complicated coefficients)</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Solve equations using properties (VII-P.11)</li>
                                    <li>Write an equation from words (VII-Q.2)</li>
                                    <li>Write and solve equations that represent diagrams (VII-Q.4)</li>
                                    <li>Solve one-step equations (VII-Q.5)</li>
                                    <li>Solve two-step equations (VII-Q.6)</li>
                                    <li>Solve equations: word problems (VII-Q.7)</li>
                                    <li>Solve equations involving like terms (VII-Q.8)</li>
                                    <li>Solve equations: complete the solution (VII-Q.9)</li>
                                </ul>
                            </li>

                            <h3 style={{ color: "red" }}>7.RP Ratio and Proportion</h3>

                            <ul style={{ listStyle: "none" }}>
                                <li><h6>7.RP.1 Ratio and proportion (revision)</h6>
                                    <ul style={{ color: "green" }}>
                                        <li>Understanding ratios (VII-J.1)</li>
                                        <li>Identify equivalent ratios (VII-J.2)</li>
                                        <li>Write an equivalent ratio (VII-J.3)</li>
                                        <li>Equivalent ratios: word problems (VII-J.4)</li>
                                        <li>Compare ratios: word problems (VII-J.6)</li>
                                        <li>Do the ratios form a proportion? (VII-J.8)</li>
                                        <li>Do the ratios form a proportion: word problems (VII-J.9)</li>
                                        <li>Solve proportions (VII-J.10)</li>
                                        <li>Solve proportions: word problems (VII-J.11)</li>
                                        <li>Estimate population size using proportions (VII-J.12)</li>

                                    </ul>
                                </li>
                            </ul>


                            <li><h6>7.RP.2 Unitary method continued, consolidation, general expression.</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Unit rates (VII-J.5)</li>
                                    <li>Unit prices (VII-L.3)</li>
                                    <li>Unit prices: find the total price (VII-L.4)</li>
                                </ul>
                            </li>

                            <li><h6>7.RP.3 Percentage - an introduction.</h6>
                                <ul style={{ color: "green" }}>
                                    <li>What percentage is illustrated? (VII-K.1)</li>
                                </ul>
                            </li>


                            <li><h6>7.RP.4 Understanding percentage as a fraction with denominator 100</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Estimate percents of numbers (VII-K.4)</li>
                                    <li>Solve percent equations (VII-K.7)</li>
                                    <li>Solve percent equations: word problems (VII-K.8)</li>
                                </ul>
                            </li>



                            <li><h6>7.RP.5 Converting fractions and decimals into percentage and vice-versa.</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Convert between percents, fractions and decimals (VII-K.2)</li>
                                    <li>Compare percents to fractions and decimals (VII-K.3)</li>
                                </ul>
                            </li>


                            <li><h6>7.RP.6 Application to profit and loss (single transaction only).</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Percents of numbers and money amounts (VII-K.5)</li>
                                    <li>Percents of numbers and money amounts (VII-K.5)Percents of numbers: word problems (VII-K.6)</li>
                                    <li>Price lists (VII-L.2)</li>
                                    <li>Percent of a number, discount and more (VII-L.5)</li>
                                    <li>Find the percent: discount and mark-up (VII-L.6)</li>
                                    <li>Sale prices: find the original price (VII-L.7)</li>
                                    <li>Multi-step problems with percents (VII-L.8)</li>

                                </ul>
                            </li>

                            <li><h6>7.RP.7 Application to simple interest (time period in complete years)</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Simple interest (VII-L.10)</li>
                                </ul>
                            </li>


                            <h3 style={{ color: "red" }}>7.G Geometry</h3>

                            <ul style={{ listStyle: "none" }}>
                                <li><h6>7.G.i Understanding shapes:</h6>

                                    <li><h6>7.G.i.1 Pairs of angles (linear, supplementary, complementary, adjacent, vertically opposite) (verification and simple proof of vertically opposite angles)</h6></li>
                                    <ul style={{ color: "green" }}>
                                        <li>Name, measure and classify angles (VII-R.2)</li>
                                        <li>Identify complementary, supplementary, vertical, adjacent and congruent angles (VII-R.13)</li>
                                        <li>Find measures of complementary, supplementary, vertical and adjacent angles (VII-R.14)</li>
                                    </ul>
                                </li>
                            </ul>

                            <li><h6>7.G.i.2 Properties of parallel lines with transversal (alternate, corresponding, interior, exterior angles)</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Transversal of parallel lines (VII-R.15)</li>
                                </ul>
                            </li>

                            <li><h6>7.G.ii Properties of triangles:</h6>
                                <li><h6>7.G.ii.1 Angle sum property (with notions of proof & verification through paper folding, proofs using property of parallel lines, difference between proof and verification.)</h6></li>
                                <ul style={{ color: "green" }}>
                                    <li>Triangle angle-sum property (VII-R.7)</li>
                                </ul>
                            </li>


                            <li><h6>7.G.ii.2 Exterior angle property</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Exterior angle property (VII-R.8)</li>
                                </ul>
                            </li>

                            <h5>7.G.ii.3 Sum of two sides of a it's third side</h5>
                            <li><h6>7.G.ii.4 Pythagoras Theorem (Verification only)</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Converse of Pythagoras' theorem: is it a right triangle? (VII-U.4)</li>
                                </ul>
                            </li>

                            <h5>7.G.iii Symmetry</h5>
                            <li><h6>7.G.iii.1 Recalling reflection symmetry</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Symmetry (VII-R.18)</li>
                                </ul>
                            </li>

                            <li><h6>7.G.iii.2 Idea of rotational symmetry, observations of rotational symmetry of 2-D objects. (900, 1200, 1800)</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Rotational symmetry (VI-T.2)</li>
                                </ul>
                            </li>

                            <li><h6>7.G.iii.3 Operation of rotation through 900 and 1800 of simple figures.</h6></li>
                            <li><h6>7.G.iii.4 Examples of figures with both rotation and reflection symmetry (both operations)</h6></li>
                            <li><h6>7.G.iii.5 Examples of figures that have reflection and rotation symmetry and vice-versa</h6></li>

                            <h5>7.G.iv Representing 3-D in 2-D:</h5>
                            <li><h6>7.G.iv.1 Drawing 3-D figures in 2-D showing hidden faces.</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Nets of three-dimensional figures (VII-V.2)</li>
                                </ul>
                            </li>


                            <li><h6>7.G.iv.2 Identification and counting of vertices, edges, faces, nets (for cubes cuboids, and cylinders, cones).</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Count vertices, edges and faces (VI-V.2)</li>
                                </ul>
                            </li>

                            <li><h6>7.G.iv.3 Matching pictures with objects (Identifying names)</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Bases of three-dimensional figures (VII-V.1)</li>
                                </ul>
                            </li>

                            <li><h6>7.G.iv.4 Mapping the space around approximately through visual estimation.</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Front, side and top view (VII-V.3)</li>
                                </ul>
                            </li>

                            <h5>7.G.v Congruence</h5>
                            <li><h6>7.G.v.1 Congruence through superposition (examples-blades, stamps, etc.)</h6></li>
                            <li><h6>7.G.v.2 Extend congruence to simple geometrical shapes e.g. triangles, circles.</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Side lengths and angle measures of congruent figures (VII-S.2)</li>
                                    <li>Congruence statements and corresponding parts (VII-S.3)</li>
                                </ul>
                            </li>


                            <li><h6>7.G.v.3 Criteria of congruence (by verification) SSS, SAS, ASA, RHS</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Congruent triangles: SSS, SAS and ASA (VIII-P.4)</li>
                                </ul>
                            </li>



                            <li><h6>7.G.vi Construction (Using scale, protractor, compass)</h6></li>
                            <li><h6>7.G.vi.1 Construction of a line parallel to a given line from a point outside it. (Simple proof as remark with the reasoning of alternate angles)</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Construct parallel lines (VII-T.4)</li>
                                </ul>
                            </li>


                            <li><h6>7.G.vi.2 Construction of simple triangles. Like given three sides, given a side and two angles on it, given two sides and the angle between them.</h6></li>


                            <h3 style={{ color: "red" }}>7.MEN Mensuration</h3>
                            <ul style={{ listStyle: "none" }}>
                                <li><h6>7.MEN.1 Revision of perimeter, Idea of, Circumference of Circle</h6></li>
                                <ul style={{ color: "green" }}>
                                    <li>Perimeter (VII-W.1)</li>
                                    <li>Area and perimeter: word problems (VII-W.4)</li>
                                    <li>Circles: calculate area, circumference, radius and diameter (VII-W.5)</li>
                                </ul>
                            </ul>


                            <h5>7.MEN. Area</h5>
                            <li><h6>7.MEN.1 Concept of measurement using a basic unit area of a square, rectangle, triangle, parallelogram and circle, area between two rectangles and two concentric circles. Data</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Area of rectangles and parallelograms (VII-W.2)</li>
                                    <li>Area of triangles (VII-W.3)</li>
                                    <li>Circles: calculate area, circumference, radius and diameter (VII-W.5)</li>
                                    <li>Semicircles: calculate area, perimeter, radius and diameter (VII-W.7)</li>
                                    <li>Quarter circles: calculate area, perimeter and radius (VII-W.8)</li>
                                    <li>Area of compound figures with triangles, semicircles and quarter circles (VII-W.9)</li>
                                    <li>Area between two shapes (VII-W.10)</li>
                                </ul>

                            </li>


                            <h3 style={{ color: "red" }}>7.DH Data handling</h3>
                            <ul style={{ listStyle: "none" }}>
                                <li><h6>7.DH.i Collection and organisation of data – choosing the data to collect for a hypothesis testing.</h6></li>
                                <ul style={{ color: "green" }}>
                                    <li>Interpret tables (VII-X.1)</li>
                                    <li>Interpret line plots (VII-X.2)</li>
                                    <li>Create line plots (VII-X.3)</li>
                                    <li>Interpret stem-and-leaf plots (VII-X.4)</li>
                                    <li>Interpret bar graphs (VII-X.6)</li>
                                    <li>Create bar graphs (VII-X.7)</li>
                                    <li>Interpret histograms (VII-X.8)</li>
                                    <li>Create histograms (VII-X.9)</li>
                                    <li>Create frequency charts (VII-X.10)</li>
                                    <li>Interpret line graphs (VII-X.11)</li>
                                    <li>Create line graphs (VII-X.12)</li>
                                </ul>
                            </ul>



                            <li><h6>7.DH.ii Mean, median and mode of ungrouped data – understanding what they represent.</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Calculate mean, median, mode and range (VII-Y.1)</li>
                                    <li>Interpret charts to find mean, median, mode and range (VII-Y.2)</li>
                                </ul>
                            </li>


                            <li><h6>7.DH.iii Constructing bargraphs</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Create bar graphs (VII-X.7)</li>
                                </ul>
                            </li>


                            <li><h6>7.DH.iv Feel of probability using data through experiments. Notion of chance in events like tossing coins, dice etc. Tabulating and counting occurrences of 1 through 6 in a number of throws. Comparing the observation with that for a coin. Observing strings of throws, notion of randomness.</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Probability of simple events (VII-Z.1)</li>
                                    <li>Experimental probability (VII-Z.3)</li>
                                    <li>Make predictions (VII-Z.4)</li>
                                </ul>
                            </li>

                        </ul>
                    </li>


                </ul>
            </div>

        </div>
    )
}

export default Class7
