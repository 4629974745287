
import React from 'react';
//  import './class.css';

const Classv = () => {
    const categories = [
        {
            title: "Place values and number sense",
            items: [
                { label: "A.1", description: "Place values" },
                { label: "A.2", description: "Convert between place values" },
                { label: "A.3", description: "Compare numbers" },
                { label: "A.4", description: "Word names for numbers" },
                { label: "A.5", description: "Rounding" },
                { label: "A.6", description: "Even or odd: arithmetic rules" }
            ]
        },
        {

            title: "Decimals",
            items: [
                { label: "E.1", description: "What decimal number is illustrated?" },
                { label: "E.2", description: "Model decimals and fractions" },
                { label: "E.3", description: "Understanding decimals expressed in words" },
                { label: "E.4", description: "Place values in decimal numbers" },
                { label: "E.5", description: "Equivalent decimals" },
                { label: "E.6", description: "Round decimals" },
                { label: "E.7", description: "Decimal number lines" },
                { label: "E.8", description: "Compare decimals on number lines" },
                { label: "E.9", description: "Compare decimal numbers" },
                { label: "E.10", description: "Put decimal numbers in order" },
                { label: "E.11", description: "Convert fractions to decimals" },
                { label: "E.12", description: "Convert decimals to fractions" }
            ]
        },
        {
            title: "Data and graphs",
            items: [
                { label: "K.1", description: "Read a table" },
                { label: "K.2", description: "Interpret line graphs" },
                { label: "K.3", description: "Create line graphs" },
                { label: "K.4", description: "Interpret bar graphs" },
                { label: "K.5", description: "Create bar graphs" },
                { label: "K.6", description: "Interpret pictographs" },
                { label: "K.7", description: "Create pictographs" },
                { label: "K.8", description: "Interpret histograms" },
                { label: "K.9", description: "Create histograms" },
                { label: "K.10", description: "Interpret line plots" },
                { label: "K.11", description: "Create line plots" },
                { label: "K.12", description: "Frequency charts" },
                { label: "K.13", description: "Interpret stem-and-leaf plots" },
                { label: "K.14", description: "Create stem-and-leaf plots" },
                { label: "K.15", description: "Choose the best type of graph" }
            ]
        },
        {
            title: "Addition and subtraction",
            items: [
                { label: "B.1", description: "Add and subtract whole numbers" },
                { label: "B.2", description: "Add and subtract whole numbers: word " },
                { label: "B.3", description: "Complete addition and subtraction sentences" },
                { label: "B.4", description: "Fill in the missing digits" },
                { label: "B.5", description: "Choose numbers with a particular sum or " },
                { label: "B.6", description: "Properties of addition" },
                { label: "B.7", description: "Estimate sums and differences of whole numbers" },
                { label: "B.8", description: "Estimate sums and differences: word problems" }
            ]
        },
        {
            title: "Fractions",
            items: [
                { label: "F.1", description: "Fractions review" },
                { label: "F.2", description: "Unit fractions: modelling word problems" },
                { label: "F.3", description: "Unit fractions: word problems" },
                { label: "F.4", description: "Fractions of a whole: modelling word problems" },
                { label: "F.5", description: "Fractions of a whole: word problems" },
                { label: "F.6", description: "Fractions of a group: word problems" },
                { label: "F.7", description: "Equivalent fractions" },
                { label: "F.8", description: "Patterns of equivalent fractions" },
                { label: "F.9", description: "Write fractions in lowest terms" },
                { label: "F.10", description: "Compare fractions" },
                { label: "F.11", description: "Put fractions in order" },
                { label: "F.12", description: "Fractions of a number" },
                { label: "F.13", description: "Fractions of a number: word problems" },
                { label: "F.14", description: "Mixed numbers" }
            ]
        },

        {
            title: "Probability",
            items: [
                { label: "L.1", description: "Understanding probability" },
                { label: "L.2", description: "Find the probability" },
                { label: "L.3", description: "Make predictions" },
                { label: "L.4", description: "Combinations" }
            ]
        },

        {
            title: "Time",
            items: [
                { label: "M.1", description: "Convert time units" },
                { label: "M.2", description: "Add and subtract mixed time units" },
                { label: "M.3", description: "Elapsed time" },
                { label: "M.4", description: "Find start and end times: word problems" },
                { label: "M.5", description: "Convert between 12-hour and 24-hour time" },
                { label: "M.6", description: "Schedules and timelines - 12-hour time" },
                { label: "M.7", description: "Schedules - 24-hour time" },
                { label: "M.8", description: "Time patterns" }
            ]
        },
        {
            title: "Mixed operations",
            items: [
                { label: "G.1", description: "Add, subtract, multiply and divide whole numbers" },
                { label: "G.2", description: "Add, subtract, multiply and divide whole :  " }
            ]
        },
        {
            title: "Problem solving",
            items: [
                { label: "H.1", description: "Multi-step word problems" },
                { label: "H.2", description: "Word problems with extra or missing information" },
                { label: "H.3", description: "Guess-and-check problems" },
                { label: "H.4", description: "Find the order" },
                { label: "H.5", description: "Use Venn diagrams to solve problems" }
            ]
        },
        {
            title: "Division",
            items: [
                { label: "D.1", description: "Division facts to 12" },
                { label: "D.2", description: "Division facts to 12: word problems" },
                { label: "D.3", description: "Divisibility rules" },
                { label: "D.4", description: "Divisibility rules: word problems" },
                { label: "D.5", description: "Divide by one-digit numbers" },
                { label: "D.6", description: "Divide by one-digit numbers: word problems" },
                { label: "D.7", description: "Divide by one-digit numbers: interpret " },
                { label: "D.8", description: "Estimate quotients: word problems" },
                { label: "D.9", description: "Division patterns over increasing place values" },
                { label: "D.10", description: "Divide numbers ending in zeroes" },
                { label: "D.11", description: "Divide numbers ending in zeroes: word problems" },
                { label: "D.12", description: "Divide by two-digit numbers" },
                { label: "D.13", description: "Divide by two-digit numbers: word problems" },
                { label: "D.14", description: "Choose numbers with a particular quotient" }
            ]
        },
        {
            title: "Money",
            items: [
                { label: "I.1", description: "Add and subtract money amounts" },
                { label: "I.2", description: "Add and subtract money: word problems" },
                { label: "I.3", description: "Multiply money amounts: word problems" },
                { label: "I.4", description: "Divide money amounts: word problems" },
                { label: "I.5", description: "Price lists" },
                { label: "I.6", description: "Unit prices" }
            ]
        },
        {
            title: "Number sequences",
            items: [
                { label: "J.1", description: "Complete an increasing number sequence" },
                { label: "J.2", description: "Complete a geometric number sequence" },
                { label: "J.3", description: "Use a rule to complete a number sequence" },
                { label: "J.4", description: "Number sequences: word problems" },
                { label: "J.5", description: "Number sequences: mixed review" }
            ]
        },
        {
            title: "Geometry",
            items: [
                { label: "O.1", description: "Which figure is being described?" },
                { label: "O.2", description: "Number of sides in polygons" },
                { label: "O.3", description: "Regular and irregular polygons" },
                { label: "O.4", description: "Types of angles" },
                { label: "O.5", description: "Measure angles with a protractor" },
                { label: "O.6", description: "Lines of symmetry" },
                { label: "O.7", description: "Rotational symmetry" },
                { label: "O.8", description: "Reflection, rotation and translation" },
                { label: "O.9", description: "Identify three-dimensional figures" },
                { label: "O.10", description: "Count vertices, edges and faces" },
                { label: "O.11", description: "Nets of three-dimensional figures" },
                { label: "O.12", description: "Three-dimensional figures viewed from different " }
            ]
        },
        {
            title: "Geometric measurement",
            items: [
                { label: "P.1", description: "Perimeter" },
                { label: "P.2", description: "Perimeter: find the missing side lengths" },
                { label: "P.3", description: "Area of squares and rectangles" },
                { label: "P.4", description: "Area and perimeter of figures on grids" },
                { label: "P.5", description: "Area and perimeter: word problems" },
                { label: "P.6", description: "Use area and perimeter to determine cost" },
                { label: "P.7", description: "Volume of figures made of unit cubes" }
            ]
        }
    ];

    const menuItems = [
        { label: 'L', text: 'Lower kindergarten' },
        { label: 'U', text: 'Upper kindergarten' },
        { label: 'I', text: 'Level I' },
        { label: 'II', text: 'Level II' },
        { label: 'III', text: 'Level III' },
        { label: 'IV', text: 'Level IV' },
        { label: 'V', text: 'Level V' },
        { label: 'VI', text: 'Level VI' },
        { label: 'VII', text: 'Level VII' },
        { label: 'VIII', text: 'Level VIII' },
    ];

    return (
        <div className="maths-container">
            <aside className="menu">
                {menuItems.map((item, index) => (
                    <div
                        key={index}
                        className={`menu-item menu-item-${item.label}`}
                        data-text={item.text}
                    >
                        {item.label}
                    </div>
                ))}
            </aside>
            <div className="content-maths">
                {/* ... (keep the rest of the content as is) */}

                <div className="category intro">
                    <h3>Class-v maths</h3>
                    <p>Here is a list of all of the maths skills students learn in UKG! These skills are organised into categories, and you can move your mouse over any skill name to preview the skill. To start practising, just click on any link. STEM WORLD will track your score, and the questions will automatically increase in difficulty as you improve!</p>
                </div>
                <div className="category-list">
                    {categories.map((category, index) => (
                        <div key={index} className="category">
                            <h3>{category.title}</h3>
                            <ul>
                                {category.items.map((item, i) => (
                                    <li key={i}>
                                        <span className="item-label">{item.label}</span>
                                        <span className="item-description">{item.description}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Classv;