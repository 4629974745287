
import React from 'react';
// import './KindergartenMaths.css';

const UpperKindergartenMaths = () => {
    const categories = [
        {
            title: "Counting and number patterns",
            items: [
                { label: "A.1", description: "Name the shapeA.1	Skip-counting" },
                { label: "A.2", description: "Skip-counting sequences" },
                { label: "A.3", description: "Counting patterns - up to 100" },
                { label: "A.4", description: "Number lines - up to 100" },
                { label: "A.5", description: "Hundred chart" },
                { label: "A.6", description: "Identify numbers as even or odd" },
                { label: "A.7", description: "Select even or odd numbers" },
                { label: "A.8", description: "Even or odd numbers on number lines" },
                { label: "A.9", description: "Which even or odd number comes before or" },
                { label: "A.9", description: "Skip-counting stories" },
                { label: "A.9", description: "	Skip-counting puzzles" },
            ]
        },
        {
            title: "Addition - two digits",
            items: [
                { label: "G.1", description: "Add multiples of 10" },
                { label: "G.2", description: "Add a two-digit and a one-digit number -" },
                { label: "G.3", description: "Add a two-digit and a one-digit number -" },
                { label: "G.4", description: "Add two two-digit numbers - without regrouping" },
                { label: "G.5", description: "Add two two-digit numbers - with regrouping" },
                { label: "G.6", description: "Write addition sentences to describe pictures" },
                { label: "G.7", description: "Addition input/output tables - up to two digits" },
                { label: "G.8", description: "Ways to make a number using addition" },
                { label: "G.9", description: "Addition word problems - up to two digits" },
                { label: "G.10", description: "Complete the addition sentence - up to" },
                { label: "G.11", description: "Write the addition sentence - up to two digits" },
                { label: "G.12", description: "Balance addition equations - up to two digits" },
                { label: "G.13", description: "Add three or more numbers up to two" },
                { label: "G.14", description: "Add three or more numbers up to two digits -" }
            ]
        },
        {
            title: "Money",
            items: [
                { label: "L.1", description: "Coin values" },
                { label: "L.2", description: "Count money - up to 10 rupees" },
                { label: "L.3", description: "Count money - up to 50 rupees" },
                { label: "L.4", description: "Equivalent amounts of money - up to 10 rupees" },
                { label: "L.5", description: "Equivalent amounts of money - up to 50 rupees" },
                { label: "L.6", description: "Exchanging coins" },
                { label: "L.7", description: "Comparing groups of coins" },
                { label: "L.8", description: "Add and subtract money - word problems - up" },
                { label: "L.9", description: "Which picture shows more - up to 10 rupees" },
                { label: "L.10", description: "Which picture shows more - up to 50 rupees" },
                { label: "L.11", description: "Least number of coins" },
                { label: "L.12", description: "Purchases - do you have enough money - up to " },
                { label: "L.13", description: "Purchases - do you have enough money - up to  " },
                { label: "L.14", description: "Making change" }
            ]
        },
        {
            title: "Comparing and ordering",
            items: [
                { label: "G.1", description: "Comparing numbers up to 100" },
                { label: "G.2", description: "Put numbers up to 100 in order" },
                { label: "G.3", description: "Greatest and least - word problems - up to 100" },

            ]
        },
        {
            title: "Subtraction - two digits",
            items: [
                { label: "H.1", description: "Subtract multiples of 10" },
                { label: "H.2", description: "Subtract a one-digit number from a two-digit " },
                { label: "H.3", description: "Subtract a one-digit number from a two-digit" },
                { label: "H.4", description: "Subtract two two-digit numbers - without " },
                { label: "H.5", description: "Subtract two two-digit numbers - with " },
                { label: "H.6", description: "Write subtraction sentences to describe pictures" },
                { label: "H.7", description: "Subtraction input/output tables - up to two digits" },
                { label: "H.8", description: "Ways to make a number using subtraction" },
                { label: "H.9", description: "Subtraction word problems - up to two digits" },
                { label: "H.10", description: "Complete the subtraction sentence - up to two " },
                { label: "H.11", description: "Write the subtraction sentence - up to two digits" },
                { label: "H.12", description: "Balance subtraction equations - up to two digits" }
            ]
        },
        {
            title: "Time",
            items: [
                { label: "M.1", description: "Days of the week" },
                { label: "M.2", description: "Seasons" },
                { label: "M.3", description: "Read a calendar" },
                { label: "M.4", description: "Months of the year" },
                { label: "M.5", description: "Number of days in each month" },
                { label: "M.6", description: "Relate time units" },
                { label: "M.7", description: "A.M. or P.M." },
            ]
        },
        {
            title: "Names of numbers",
            items: [
                { label: "C.1", description: "Ordinal numbers up to 10th" },
                { label: "C.2", description: "Ordinal numbers up to 100th" },
                { label: "C.3", description: "Writing numbers up to 100 in words" },
                { label: "C.4", description: "Distinguishing ordinal and cardinal numbers" }
            ]
        },
        {
            title: "Patterns",
            items: [
                { label: "D.1", description: "Repeating patterns" },
                { label: "D.2", description: "Growing patterns" },
                { label: "D.3", description: "Find the next shape in a pattern" },
                { label: "D.4", description: "Complete a repeating pattern" },
                { label: "D.5", description: "Make a repeating pattern" },
                { label: "D.6", description: "Find the next row in a growing pattern" }
            ]
        },
        {
            title: "Addition - one digit",
            items: [
                { label: "E.1", description: "Review - add one-digit numbers - sums to 10" },
                { label: "E.2", description: "Review - ways to make a number - sums to 10" },
                { label: "E.3", description: "Review - writing addition sentences - sums to 10" },
                { label: "E.4", description: "Add doubles" },
                { label: "E.5", description: "Add one-digit numbers" },
                { label: "E.6", description: "Addition with pictures - sums to 20" },
                { label: "E.7", description: "Write addition sentences to describe pictures -" },
                { label: "E.8", description: "Addition input/output tables - sums to 20" },
                { label: "E.9", description: "Add zero" },
                { label: "E.10", description: "Addition word problems - one digit" },
                { label: "E.11", description: "Complete the addition sentence - one digit" },
                { label: "E.12", description: "Write the addition sentence - one digit" },
                { label: "E.13", description: "Balance addition equations - one digit" },
                { label: "E.14", description: "Add three or more one-digit numbers" },
                { label: "E.15", description: "Add three or more one-digit numbers - word " },
                { label: "E.16", description: "Identify repeated addition for arrays - sums to 10" },
                { label: "E.17", description: "Write addition sentences for arrays - sums to 10" },
                { label: "E.18", description: "Identify repeated addition for arrays - sums to " },
                { label: "E.19", description: "Write addition sentences for arrays - sums to 25" }
            ]
        },
        {
            title: "Subtraction - one digit",
            items: [
                { label: "F.1", description: "Review - subtract one-digit numbers - up to 10" },
                { label: "F.2", description: "Review - ways to subtract - up to 10" },
                { label: "F.3", description: "Review - writing subtraction sentences - up to 10" },
                { label: "F.4", description: "Subtract doubles" },
                { label: "F.5", description: "Subtract a one-digit number from a two-digit" },
                { label: "F.6", description: "Subtraction with pictures" },
                { label: "F.7", description: "Write subtraction sentences to describe pictures " },
                { label: "F.8", description: "Subtraction input/output tables - up to 18" },
                { label: "F.9", description: "Subtract zero/all" },
                { label: "F.10", description: "Subtraction word problems - up to 18" },
                { label: "F.11", description: "Complete the subtraction sentence - up to 18" },
                { label: "F.12", description: "Write the subtraction sentence - up to 18" },
                { label: "F.13", description: "Balance subtraction equations - up to 18" }
            ]
        },

        {
            title: "Data and graphs",
            items: [
                { label: "N.1", description: "Which tally chart is correct?" },
                { label: "N.2", description: "Interpret tally charts" }
            ]
        },
        {
            title: "Geometry",
            items: [
                { label: "O.1", description: "Name the two-dimensional shape" },
                { label: "O.2", description: "Select two-dimensional shapes" },
                { label: "O.3", description: "Count sides and vertices" },
                { label: "O.4", description: "Compare sides and vertices" },
                { label: "O.5", description: "Name the three-dimensional shape" },
                { label: "O.6", description: "Select three-dimensional shapes" },
                { label: "O.7", description: "Count vertices, edges and faces" },
                { label: "O.8", description: "Compare vertices, edges and faces" },
                { label: "O.9", description: "Flip, turn and slide" }
            ]
        },
        {
            title: "Measurement",
            items: [
                { label: "P.1", description: "Long and short" },
                { label: "P.2", description: "Tall and short" },
                { label: "P.3", description: "Light and heavy" },
                { label: "P.4", description: "Holds more or less" },
                { label: "P.5", description: "Compare size, weight and capacity" },
                { label: "P.6", description: "Choose the appropriate measuring tool" },
                { label: "P.7", description: "Measure using objects" },
                { label: "P.8", description: "Measure using a centimetre ruler" }
            ]
        },
        {
            title: "Logical reasoning",
            items: [
                { label: "Q.1", description: "Guess the number" }
            ]
        },
        {
            title: "Probability",
            items: [
                { label: "R.1", description: "More, less and equally likely" },
                { label: "R.2", description: "Certain, probable, unlikely and impossible" }
            ]
        },
        {
            title: "Mixed operations",
            items: [
                { label: "S.1", description: "Add and subtract numbers up to 20" },
                { label: "S.2", description: "Addition and subtraction - ways to make a " },
                { label: "S.3", description: "Addition and subtraction word problems - up to " },
                { label: "S.4", description: "Addition and subtraction - balance equations - " },
                { label: "S.5", description: "Write the addition or subtraction rule for an  " },
                { label: "S.6", description: "Add and subtract numbers up to 100" },
                { label: "S.7", description: "Addition and subtraction - ways to make a " },
                { label: "S.8", description: "Addition and subtraction word problems - up to " },
                { label: "S.9", description: "Addition and subtraction - balance equations - " },
                { label: "S.10", description: "Write the addition or subtraction rule for an " },
                { label: "S.11", description: "Which sign (+ or -) makes the number sentence  " },
                { label: "S.12", description: "Write addition and subtraction sentences" }
            ]
        }


    ];

    const menuItems = [
        { label: 'L', text: 'Lower kindergarten' },
        { label: 'U', text: 'Upper kindergarten' },
        { label: 'I', text: 'Level I' },
        { label: 'II', text: 'Level II' },
        { label: 'III', text: 'Level III' },
        { label: 'IV', text: 'Level IV' },
        { label: 'V', text: 'Level V' },
        { label: 'VI', text: 'Level VI' },
        { label: 'VII', text: 'Level VII' },
        { label: 'VIII', text: 'Level VIII' },
    ];

    return (
        <div className="maths-container">
            <aside className="menu">
                {menuItems.map((item, index) => (
                    <div
                        key={index}
                        className={`menu-item menu-item-${item.label}`}
                        data-text={item.text}
                    >
                        {item.label}
                    </div>
                ))}
            </aside>
            <div className="content-maths">
                {/* ... (keep the rest of the content as is) */}

                <div className="category intro">
                    <h3>Upper Kindergarten Maths</h3>
                    <p>Here is a list of all of the maths skills students learn in UKG! These skills are organised into categories, and you can move your mouse over any skill name to preview the skill. To start practising, just click on any link. STEM WORLD will track your score, and the questions will automatically increase in difficulty as you improve!</p>
                </div>
                <div className="category-list">
                    {categories.map((category, index) => (
                        <div key={index} className="category">
                            <h3>{category.title}</h3>
                            <ul>
                                {category.items.map((item, i) => (
                                    <li key={i}>
                                        <span className="item-label">{item.label}</span>
                                        <span className="item-description">{item.description}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default UpperKindergartenMaths;