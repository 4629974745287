
import React from 'react';
//  import './class.css';

const Classviii = () => {
    const categories = [
        {
            title: "Number theory",
            items: [
                { label: "A.1", description: "Factors" },
                { label: "A.2", description: "Divisibility rules" },
                { label: "A.3", description: "Prime or composite" },
                { label: "A.4", description: "Prime factorisation" },
                { label: "A.5", description: "Highest common factor" },
                { label: "A.6", description: "Lowest common multiple" },
                { label: "A.7", description: "HCF and LCM: word problems" },
                { label: "A.8", description: "Classify numbers" }
            ]
        },

        {
            title: "Percents",
            items: [
                { label: "J.1", description: "Convert between percents, fractions and " },
                { label: "J.2", description: "Compare percents to fractions and decimals" },
                { label: "J.3", description: "Find what percent one number is of another" },
                { label: "J.4", description: "Find what percent one number is of another:  " },
                { label: "J.5", description: "Estimate percents of numbers" },
                { label: "J.6", description: "Percents of numbers and money amounts" },
                { label: "J.7", description: "Percents of numbers: word problems" },
                { label: "J.8", description: "Compare percents of numbers" },
                { label: "J.9", description: "Solve percent equations" },
                { label: "J.10", description: "Percent of change" },
                { label: "J.11", description: "Percent of change: word problems" }
            ]
        },
        {
            title: "Number Sequences",
            items: [
                { label: "U.1", description: "Identify arithmetic and geometric sequences" },
                { label: "U.2", description: "Arithmetic sequences" },
                { label: "U.3", description: "Geometric sequences" },
                { label: "U.4", description: "Number sequences: mixed review" },
                { label: "U.5", description: "Number sequences: word problems" },
                { label: "U.6", description: "Evaluate variable expressions for number " },
                { label: "U.7", description: "Write variable expressions for arithmetic " }
            ]
        },
        {
            title: "Integers",
            items: [
                { label: "B.1", description: "Integers on number lines" },
                { label: "B.2", description: "Graph integers on horizontal and " },
                { label: "B.3", description: "Compare and order integers" }
            ]
        },
        {
            title: "Expressions and Properties",
            items: [
                { label: "V.1", description: "Write variable expressions" },
                { label: "V.2", description: "Write variable expressions from diagrams" },
                { label: "V.3", description: "Write variable expressions: word problems" },
                { label: "V.4", description: "Evaluate one-variable expressions" },
                { label: "V.5", description: "Evaluate multi-variable expressions" },
                { label: "V.6", description: "Evaluate absolute value expressions" },
                { label: "V.7", description: "Evaluate radical expressions" },
                { label: "V.8", description: "Evaluate rational expressions" },
                { label: "V.9", description: "Identify terms and coefficients" },
                { label: "V.10", description: "Sort factors of expressions" },
                { label: "V.11", description: "Properties of addition and multiplication" },
                { label: "V.12", description: "Multiply using the distributive property" },
                { label: "V.13", description: "Simplify variable expressions using properties" },
                { label: "V.14", description: "Add and subtract like terms" },
                { label: "V.15", description: "Add, subtract and multiply linear expressions" },
                { label: "V.16", description: "Factors of linear expressions" },
                { label: "V.17", description: "Identify equivalent linear expressions" }
            ]
        },
        {
            title: "Operations with Integers",
            items: [
                { label: "C.1", description: "Integer addition and subtraction rules" },
                { label: "C.2", description: "Add and subtract integers using counters" },
                { label: "C.3", description: "Add and subtract integers" },
                { label: "C.4", description: "Add and subtract three or more integers" },
                { label: "C.5", description: "Add and subtract integers: word problems" },
                { label: "C.6", description: "Integer multiplication and division rules" },
                { label: "C.7", description: "Multiply and divide integers" },
                { label: "C.8", description: "Evaluate numerical expressions involving integers" }
            ]
        },
        {
            title: "Consumer Maths",
            items: [
                { label: "K.1", description: "Price lists" },
                { label: "K.2", description: "Unit prices" },
                { label: "K.3", description: "Unit prices: find the total price" },
                { label: "K.4", description: "Percent of a number: VAT, discount and more" },
                { label: "K.5", description: "Find the percent: discount and mark-up" },
                { label: "K.6", description: "Sale prices: find the original price" },
                { label: "K.7", description: "Multi-step problems with percents" },
                { label: "K.8", description: "Estimate tips" },
                { label: "K.9", description: "Simple interest" },
                { label: "K.10", description: "Compound interest" }
            ]
        },
        {
            title: "Expressions and Properties",
            items: [
                { label: "V.1", description: "Write variable expressions" },
                { label: "V.2", description: "Write variable expressions from diagrams" },
                { label: "V.3", description: "Write variable expressions: word problems" },
                { label: "V.4", description: "Evaluate one-variable expressions" },
                { label: "V.5", description: "Evaluate multi-variable expressions" },
                { label: "V.6", description: "Evaluate absolute value expressions" },
                { label: "V.7", description: "Evaluate radical expressions" },
                { label: "V.8", description: "Evaluate rational expressions" },
                { label: "V.9", description: "Identify terms and coefficients" },
                { label: "V.10", description: "Sort factors of expressions" },
                { label: "V.11", description: "Properties of addition and multiplication" },
                { label: "V.12", description: "Multiply using the distributive property" },
                { label: "V.13", description: "Simplify variable expressions using properties" },
                { label: "V.14", description: "Add and subtract like terms" },
                { label: "V.15", description: "Add, subtract and multiply linear expressions" },
                { label: "V.16", description: "Factors of linear expressions" },
                { label: "V.17", description: "Identify equivalent linear expressions" }
            ]
        },
        {
            title: "Rational Numbers",
            items: [
                { label: "D.1", description: "Identify rational and irrational numbers" },
                { label: "D.2", description: "Write fractions in lowest terms" },
                { label: "D.3", description: "Lowest common denominator" },
                { label: "D.4", description: "Round decimals and mixed numbers" },
                { label: "D.5", description: "Convert between decimals and fractions or mixed numbers" },
                { label: "D.6", description: "Compare rational numbers" },
                { label: "D.7", description: "Put rational numbers in order" }
            ]
        },
        {
            title: "Units of Measurement",
            items: [
                { label: "L.1", description: "Convert rates and measurements: metric units" },
                { label: "L.2", description: "Metric mixed units" },
                { label: "L.3", description: "Convert square and cubic units of length" },
                { label: "L.4", description: "Convert between cubic metres and litres" },
                { label: "L.5", description: "Precision" }
            ]
        },
        {
            title: "Problem Solving",
            items: [
                { label: "M.1", description: "Multi-step word problems" },
                { label: "M.2", description: "Guess-and-check word problems" },
                { label: "M.3", description: "Use Venn diagrams to solve problems" },
                { label: "M.4", description: "Elapsed time word problems" }
            ]
        },
        {
            title: "One-variable equations",
            items: [
                { label: "W.1", description: "Which x satisfies an equation?" },
                { label: "W.2", description: "Write an equation from words" },
                { label: "W.3", description: "Model and solve equations using algebra tiles" },
                { label: "W.4", description: "Write and solve equations that represent diagrams" },
                { label: "W.5", description: "Properties of equality" },
                { label: "W.6", description: "Solve one-step equations" },
                { label: "W.7", description: "Solve two-step equations" },
                { label: "W.8", description: "Solve multi-step equations" },
                { label: "W.9", description: "Solve equations involving like terms" },
                { label: "W.10", description: "Solve equations: complete the solution" },
                { label: "W.11", description: "Solve equations: word problems" }
            ]
        },
        {
            title: "Exponents and roots",
            items: [
                { label: "F.1", description: "Understanding exponents" },
                { label: "F.2", description: "Evaluate powers" },
                { label: "F.3", description: "Solve equations with variable exponents" },
                { label: "F.4", description: "Powers with negative bases" },
                { label: "F.5", description: "Powers with decimal and fractional bases" },
                { label: "F.6", description: "Understanding negative exponents" },
                { label: "F.7", description: "Evaluate powers with negative exponents" },
                { label: "F.8", description: "Multiplication with exponents" },
                { label: "F.9", description: "Division with exponents" },
                { label: "F.10", description: "Multiplication and division with exponents" },
                { label: "F.11", description: "Power rule" },
                { label: "F.12", description: "Evaluate expressions using properties of exponents" },
                { label: "F.13", description: "Identify equivalent expressions involving exponents" },
                { label: "F.14", description: "Square roots of perfect squares" },
                { label: "F.15", description: "Positive and negative square roots" },
                { label: "F.16", description: "Estimate positive and negative square roots" },
                { label: "F.17", description: "Relationship between squares and square roots" },
                { label: "F.18", description: "Solve equations involving squares and square roots" },
                { label: "F.19", description: "Cube roots of perfect cubes" },
                { label: "F.20", description: "Estimate cube roots" },
                { label: "F.21", description: "Solve equations involving cubes and cube roots" }
            ]
        },
        {
            title: "Two-dimensional figures",
            items: [
                { label: "O.1", description: "Identify and classify polygons" },
                { label: "O.2", description: "Classify triangles" },
                { label: "O.3", description: "Identify trapeziums" },
                { label: "O.4", description: "Classify quadrilaterals" },
                { label: "O.5", description: "Graph triangles and quadrilaterals" },
                { label: "O.6", description: "Properties of parallelograms" },
                { label: "O.7", description: "Properties of rhombuses" },
                { label: "O.8", description: "Properties of squares and rectangles" },
                { label: "O.9", description: "Find missing angles in triangles and quadrilaterals" },
                { label: "O.10", description: "Interior angles of polygons" },
                { label: "O.11", description: "Lines, line segments and half lines" },
                { label: "O.12", description: "Identify complementary, supplementary, vertical, adjacent and congruent angles" },
                { label: "O.13", description: "Find measures of complementary, supplementary, vertical and adjacent angles" },
                { label: "O.14", description: "Transversal of parallel lines" },
                { label: "O.15", description: "Find lengths and measures of bisected line segments and angles" },
                { label: "O.16", description: "Parts of a circle" },
                { label: "O.17", description: "Symmetry" },
                { label: "O.18", description: "Count lines of symmetry" },
                { label: "O.19", description: "Draw lines of symmetry" }
            ]
        },
        {
            title: "Monomials and polynomials",
            items: [
                { label: "X.1", description: "Identify monomials" },
                { label: "X.2", description: "Model polynomials with algebra tiles" },
                { label: "X.3", description: "Add and subtract polynomials using algebra tiles" },
                { label: "X.4", description: "Add and subtract polynomials" },
                { label: "X.5", description: "Add polynomials to find perimeter" },
                { label: "X.6", description: "Multiply monomials" },
                { label: "X.7", description: "Divide monomials" },
                { label: "X.8", description: "Multiply and divide monomials" },
                { label: "X.9", description: "Powers of monomials" },
                { label: "X.10", description: "Square and cube roots of monomials" },
                { label: "X.11", description: "Multiply polynomials using algebra tiles" },
                { label: "X.12", description: "Multiply polynomials" },
                { label: "X.13", description: "Multiply polynomials to find area" }
            ]
        },
        {
            title: "Factorising",
            items: [
                { label: "Y.1", description: "HCF of monomials" },
                { label: "Y.2", description: "Factorise out a monomial" },
                { label: "Y.3", description: "Factorise quadratics with leading coefficient 1" },
                { label: "Y.4", description: "Factorise quadratics with other leading coefficients" },
                { label: "Y.5", description: "Factorise quadratics: special cases" },
                { label: "Y.6", description: "Factorise quadratics using algebra tiles" },
                { label: "Y.7", description: "Factorise by grouping" },
                { label: "Y.8", description: "Factorise polynomials" }
            ]
        },
        {
            title: "Congruence and similarity",
            items: [
                { label: "P.1", description: "Similar and congruent figures" },
                { label: "P.2", description: "Side lengths and angle measures of congruent figures" },
                { label: "P.3", description: "Congruence statements and corresponding parts" },
                { label: "P.4", description: "Congruent triangles: SSS, SAS and ASA" },
                { label: "P.5", description: "Side lengths and angle measures of similar figures" }
            ]
        },
        {
            title: "Constructions",
            items: [
                { label: "Q.1", description: "Construct the midpoint or perpendicular bisector of a segment" },
                { label: "Q.2", description: "Construct an angle bisector" },
                { label: "Q.3", description: "Construct a congruent angle" },
                { label: "Q.4", description: "Construct a perpendicular line" },
                { label: "Q.5", description: "Construct parallel lines" },
                { label: "Q.6", description: "Construct an equilateral triangle or regular hexagon" }
            ]
        },

        {
            title: "Statistics",
            items: [
                { label: "AA.1", description: "Calculate mean, median, mode and range" },
                { label: "AA.2", description: "Interpret charts to find mean, median, mode and " },
                { label: "AA.3", description: "Mean, median, mode and range: find the missing " },
                { label: "AA.4", description: "Changes in mean, median, mode and range" }
            ]
        },
        {
            title: "Probability",
            items: [
                { label: "BB.1", description: "Probability of simple events" },
                { label: "BB.2", description: "Probability of opposite, mutually exclusive and " },
                { label: "BB.3", description: "Experimental probability" },
                { label: "BB.4", description: "Make predictions" },
                { label: "BB.5", description: "Compound events: find the number of " },
                { label: "BB.6", description: "Counting principle" }
            ]
        },
        {
            title: "Data and graphs",
            items: [
                { label: "Z.1", description: "Interpret tables" },
                { label: "Z.2", description: "Interpret bar graphs" },
                { label: "Z.3", description: "Create bar graphs" },
                { label: "Z.4", description: "Interpret line graphs" },
                { label: "Z.5", description: "Create line graphs" },
                { label: "Z.6", description: "Interpret line plots" },
                { label: "Z.7", description: "Create line plots" },
                { label: "Z.8", description: "Interpret stem-and-leaf plots" },
                { label: "Z.9", description: "Create stem-and-leaf plots" },
                { label: "Z.10", description: "Interpret histograms" },
                { label: "Z.11", description: "Create histograms" },
                { label: "Z.12", description: "Create frequency charts" },
                { label: "Z.13", description: "Interpret pie charts" },
                { label: "Z.14", description: "Pie charts and central angles" },
                { label: "Z.15", description: "Choose the best type of graph" }
            ]
        },
        {
            title: "Proportional relationships",
            items: [
                { label: "I.1", description: "Find the constant of proportionality from a table" },
                { label: "I.2", description: "Write equations for proportional relationships  " },
                { label: "I.3", description: "Identify proportional relationships by graphing" },
                { label: "I.4", description: "Find the constant of proportionality from a graph" },
                { label: "I.5", description: "Write equations for proportional relationships  " },
                { label: "I.6", description: "Identify proportional relationships" },
                { label: "I.7", description: "Graph proportional relationships" },
                { label: "I.8", description: "Interpret graphs of proportional relationships" },
                { label: "I.9", description: "Write and solve equations for proportional " }
            ]
        },
        {
            title: "Three-dimensional figures",
            items: [
                { label: "S.1", description: "Parts of three-dimensional figures" },
                { label: "S.2", description: "Nets of three-dimensional figures" },
                { label: "S.3", description: "Front, side and top view" },
                { label: "S.4", description: "Base plans" },
                { label: "S.5", description: "Similar solids" }
            ]
        },
        {
            title: "Geometric measurement",
            items: [
                { label: "T.1", description: "Perimeter" },
                { label: "T.2", description: "Area" },
                { label: "T.3", description: "Area between two shapes" },
                { label: "T.4", description: "Area and perimeter: word problems" },
                { label: "T.5", description: "Circles, semicircles and quarter circles" },
                { label: "T.6", description: "Circles: word problems" },
                { label: "T.7", description: "Volume of prisms and cylinders" },
                { label: "T.8", description: "Surface area of prisms and cylinders" },
                { label: "T.9", description: "Volume and surface area of similar solids" },
                { label: "T.10", description: "Perimeter, area and volume: changes in scale" }
            ]
        }
    ];

    const menuItems = [
        { label: 'L', text: 'Lower kindergarten' },
        { label: 'U', text: 'Upper kindergarten' },
        { label: 'I', text: 'Level I' },
        { label: 'II', text: 'Level II' },
        { label: 'III', text: 'Level III' },
        { label: 'IV', text: 'Level IV' },
        { label: 'V', text: 'Level V' },
        { label: 'VI', text: 'Level VI' },
        { label: 'VII', text: 'Level VII' },
        { label: 'VIII', text: 'Level VIII' },
    ];

    return (
        <div className="maths-container">
            <aside className="menu">
                {menuItems.map((item, index) => (
                    <div
                        key={index}
                        className={`menu-item menu-item-${item.label}`}
                        data-text={item.text}
                    >
                        {item.label}
                    </div>
                ))}
            </aside>
            <div className="content-maths">
                {/* ... (keep the rest of the content as is) */}

                <div className="category intro">
                    <h3>Class-viii maths</h3>
                    <p>Here is a list of all of the maths skills students learn in UKG! These skills are organised into categories, and you can move your mouse over any skill name to preview the skill. To start practising, just click on any link. STEM WORLD will track your score, and the questions will automatically increase in difficulty as you improve!</p>
                </div>
                <div className="category-list">
                    {categories.map((category, index) => (
                        <div key={index} className="category">
                            <h3>{category.title}</h3>
                            <ul>
                                {category.items.map((item, i) => (
                                    <li key={i}>
                                        <span className="item-label">{item.label}</span>
                                        <span className="item-description">{item.description}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Classviii;