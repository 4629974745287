import React from 'react'
import Header_ixl from '../Header_IXL/header_ixl'
import '../Class-1/class-1.css';

function Class11() {
    return (
        <div className='class_1'>
            <div className="container">

                <Header_ixl />

                <div className="main-content">
                    <div className="left-content">
                        <h3>India</h3>
                        <h4>Skills available for India class XI maths curriculum</h4>
                        <div className="description">
                            Objectives are in black and STEM WORLD maths skills are in dark green. Hold your mouse over the name of a skill to view a sample question. Click on the name of a skill to practise that skill.</div>
                    </div>
                    <div className="right-content">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
                            alt="India Flag"
                            className="india-flag"
                        />
                    </div>
                </div>

                <div style={{ display: "flex", width: "100%", marginTop: "20px", padding: "15px" }}>
                    <div style={{ width: "75%", background: "#f65314", padding: "20px" }}>
                        <h6>Showing alignment for: CISCE Class XI</h6>
                        <p>National Council of Education Research and Training Syllabus :Class XI</p>
                    </div>
                    <div style={{ width: "25%", background: "gray", padding: "20px" }}>
                        <h6>Actions</h6>
                        <p>Print curriculum</p>
                    </div>
                </div>
            </div>

            <div style={{ marginLeft: "110px" }}>
                <h3 style={{ color: "red" }}>11.I Sets and Functions</h3>
                <ul style={{ listStyle: "none" }}>
                    <li><h6>11.I.1 Sets</h6>
                        <ul style={{ listStyle: "none" }}>
                            <li><h6>.11.I.1.1 Sets and their representations. Empty set. Finite and Infinite sets. Equal sets. Subsets. Subsets of the set of real numbers especially intervals (with notations). Power set. Universal set. Venn diagrams. Union and Intersection of sets. Difference of sets. Complement of a set.</h6>
                                <li><h6>11.I.2 Relations and Functions</h6>
                                    <ul style={{ listStyle: "none" }}>
                                        <li><h6>11.I.2.1 Ordered pairs, Cartesian product of sets. Number of elements in the cartesian product of two finite sets. Cartesian product of the reals with itself (up to R X R X R).</h6>
                                            <li><h6>11.I.2.2 Definition of relation, pictorial diagrams, domain, co-domain and range of a relation. Function as a special kind of relation from one set to another. Pictorial representation of a function, domain, co-domain and range of a function. Real valued function of the real variable, domain and range of these functions, constant, identity, polynomial, rational, modulus, signum and greatest integer functions with their graphs. Sum, difference, product and quotients of functions.</h6></li>
                                            <ul style={{ color: "green" }}>
                                                <li>Relations: convert between tables, graphs, mappings and lists of points (XI-C.1)</li>
                                                <li>Domain and range of relations (XI-C.2)</li>
                                                <li>Identify independent and dependent variables (XI-C.3)</li>
                                                <li>Identify functions (XI-C.4)</li>
                                                <li>Identify functions: vertical line test (XI-C.5)</li>
                                                <li>Find values using function graphs (XI-C.6)</li>
                                                <li>Complete a function table from a graph (XI-C.9)</li>
                                                <li>Add and subtract functions (XI-Q.1)</li>
                                                <li>Multiply functions (XI-Q.2)</li>
                                                <li>Divide functions (XI-Q.3)</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </li>
                        </ul>
                    </li>



                    <h6>11.I.3 Trigonometric Functions</h6>
                    <li><h6>11.I.3.1 Positive and negative angles. Measuring angles in radians and in degrees and conversion from one measure to another. Definition of trigonometric functions with the help of unit circle. Truth of the identity sin²x + cos²x = 1, for all x. Signs of trigonometric functions and sketch of their graphs. Expressing sin (x + y) and cos (x + y) in terms of sin x, sin y, cos x and cos y. Deducing the identities like following: tan (x ± y) = (tan x ± tan y)/(1 ± tan x x tan y), cot(x ± y)= (cot x cot y ± 1)/(cot y ± cot x), sin x + sin y = 2sin (x + y)/2 x cos (x - y)/2, cos x + cos y = 2 cos (x + y)/2 cos (x - y)/2, sin x - sin y = 2cos (x + y)/2 sin (x - y)/2, cos x - cos y = -2sin (x + y)/2 sin (x - y)/2.</h6>

                        <ul style={{ color: "green" }}>
                            <li>Graphs of angles (XI-R.4)</li>
                            <li>Trigonometric ratios: sin, cos and tan (XI-S.3)</li>
                            <li>Trigonometric ratios: csc, sec and cot (XI-S.4)</li>
                            <li>Trigonometric ratios in similar right triangles (XI-S.5)</li>
                            <li>Find trigonometric ratios using the unit circle (XI-S.6)</li>
                            <li>Sin, cos and tan of special angles (XI-S.7)</li>
                            <li>Csc, sec and cot of special angles (XI-S.8)</li>
                            <li>Find trigonometric functions using a calculator (XI-S.9)</li>
                            <li>Inverses of sin, cos and tan (XI-S.10)</li>
                            <li>Inverses of csc, sec and cot (XI-S.11)</li>
                            <li>Solve trigonometric equations I (XI-S.12)</li>
                            <li>Solve trigonometric equations II (XI-S.13)</li>
                            <li>Trigonometric ratios: find a side length (XI-S.14)</li>
                            <li>Trigonometric ratios: find an angle measure (XI-S.15)</li>
                        </ul>
                    </li>
                    <h6>11.I.3.2 Identities related to sin 2x, cos 2x, tan 2x, sin 3x, cos 3x and tan 3x. General solution of trigonometric equations of the type sin θ = sin α, cos θ = cos α and tan θ = tan α. Proofs and simple applications of sine and cosine formulae.</h6>

                    <h3 style={{ color: "red" }}>11.II Algebra</h3>

                    <h6>11.II.1 Principle of Mathematical Induction</h6>
                    <li><h6>11.II.1.1 Processes of the proof by induction, motivating the application of the method by looking at natural numbers as the least inductive subset of real numbers. The principle of mathematical induction and simple applications.</h6>


                        <h6>11.II.2 Complex Numbers and Quadratic Equations</h6>
                        <li><h6>11.II.2.1 Need for complex numbers, especially √-1 , to be motivated by inability to solve every quadratic equation. Brief description of algebraic properties of complex numbers. Argand plane and polar representation of complex numbers. Statement of Fundamental Theorem of Algebra, solution of quadratic equations in the complex number system..</h6></li>

                        <ul style={{ color: "green" }}>
                            <li>Introduction to complex numbers (XI-F.1)</li>
                            <li>Add and subtract complex numbers (XI-F.2)</li>
                            <li>Complex conjugates (XI-F.3)</li>
                            <li>Multiply complex numbers (XI-F.4)</li>
                            <li>Divide complex numbers (XI-F.5)</li>
                            <li>Add, subtract, multiply and divide complex numbers (XI-F.6)</li>
                            <li>Absolute values of complex numbers (XI-F.7)</li>
                            <li>Powers of i (XI-F.8)</li>
                            <li>Complex conjugate theorem (XI-J.11)</li>

                        </ul>
                    </li>
                    {/* <h3 style={{ color: "red" }}>12.III Calculus</h3> */}

                    <h6>11.II.3 Linear Inequalities</h6>
                    <li><h6>11.II.3.1 Linear inequalities. Algebraic solutions of linear inequalities in one variable and their representation on the number line. Graphical solution of linear inequalities in two variables. Solution of system of linear inequalities in two variables – graphically.</h6>

                        <ul style={{ color: "green" }}>
                            <li>Graph inequalities (XI-B.1)</li>
                            <li>Write inequalities from graphs (XI-B.2)</li>
                            <li>Identify solutions to inequalities (XI-B.3)</li>
                            <li>Solve one-step linear inequalities: addition and subtraction (XI-B.4)</li>
                            <li>Solve one-step linear inequalities: multiplication and division (XI-B.5)</li>
                            <li>Solve one-step linear inequalities (XI-B.6)</li>
                            <li>Graph solutions to one-step linear inequalities (XI-B.7)</li>
                            <li>Solve two-step linear inequalities (XI-B.8)</li>
                            <li>Graph solutions to two-step linear inequalities (XI-B.9)</li>
                            <li>Solve advanced linear inequalities (XI-B.10)</li>
                            <li>Graph solutions to advanced linear inequalities (XI-B.11)</li>
                            <li>Graph compound inequalities (XI-B.12)</li>
                            <li>Write compound inequalities from graphs (XI-B.13)</li>
                            <li>Solve compound inequalities (XI-B.14)</li>
                            <li>Graph solutions to compound inequalities (XI-B.15)</li>
                            <li>Does (x, y) satisfy the inequality? (XI-E.1)</li>
                            <li>Linear inequalities: solve for y (XI-E.2)</li>
                            <li>Graph a linear inequality in the coordinate plane (XI-E.3)</li>
                            <li>Linear inequalities: word problems (XI-E.4)</li>
                            <li>Is (x, y) a solution to the system of inequalities? (XI-E.5)</li>
                        </ul>
                    </li>

                    <div>
                        <h6>11.II.4 Permutations and Combinations</h6>
                        <li><h6>11.II.4.1 Fundamental principle of counting. Factorial n. Permutations and combinations, derivation of formulae and their connections, simple applications.</h6>

                            <ul style={{ color: "green" }}>
                                <li>Permutations (XI-FF.5)</li>
                                <li>Counting principle (XI-FF.6)</li>
                                <li>Permutation and combination notation (XI-FF.7)</li>
                            </ul>
                        </li>
                    </div>


                    <h6>11.II.5 Binomial Theorem</h6>
                    <li><h6>11.II.5.1 History, statement and proof of the binomial theorem for positive integral indices. Pascal's triangle, general and middle term in binomial expansion, simple applications.</h6>

                        <ul style={{ color: "green" }}>
                            <li>Pascal's triangle (XI-J.15)</li>
                            <li>Pascal's triangle and the Binomial Theorem (XI-J.16)</li>
                            <li>Binomial Theorem I (XI-J.17)</li>
                            <li>Binomial Theorem II (XI-J.18)</li>

                        </ul>
                    </li>




                    <h3>11.II.6 Sequence and Series</h3>
                    <li><h6>11.II.6.1 Sequence and Series. Arithmetic progression (A. P.), arithmetic mean (A.M.). Geometric progression (G.P.), general term of a G. P., sum of n terms of a G.P., geometric mean (G.M.), relation between A.M. and G.M. Sum to n terms of the special series: ∑n, ∑n² and ∑n³.</h6>

                        <ul style={{ color: "green" }}>
                            <li>Classify formulas and sequences (XI-X.1)</li>
                            <li>Find terms of an arithmetic sequence (XI-X.2)</li>
                            <li>Find terms of a geometric sequence (XI-X.3)</li>
                            <li>Evaluate formulas for sequences (XI-X.5)</li>
                            <li>Write a formula for an arithmetic sequence (XI-X.6)</li>
                            <li>Write a formula for a geometric sequence (XI-X.7)</li>
                            <li>Sequences: mixed review (XI-X.9)</li>

                        </ul>
                    </li>

                </ul>
                {/* </li> */}
                {/* </ul>? */}
            </div>

            <div style={{ marginLeft: "110px" }}>
                <h3 style={{ color: "red" }}>11.III Coordinate Geometry</h3>
                <ul style={{ listStyle: "none" }}>
                    <li>
                        <ul style={{ listStyle: "none" }}>
                            <li><h6>11.III.1 Straight Lines</h6>
                                <ul style={{ listStyle: "none" }}>
                                    <li><h6>11.III.1.1 Brief recall of 2D from earlier classes. Slope of a line and angle between two lines. Various forms of equations of a line: parallel to axes, point-slope form, slope-intercept form, two-point form, intercepts form and normal form. General equation of a line. Distance of a point from a line.
                                    </h6>
                                        <ul style={{ color: "green" }}>
                                            <li>Find the slope of a graph (XI-D.1)</li>
                                            <li>Find the slope from two points (XI-D.2)</li>
                                            <li>Slope-intercept form: find the slope and y-intercept (XI-D.4)</li>
                                            <li>Slope-intercept form: graph an equation (XI-D.5)</li>
                                            <li>Slope-intercept form: write an equation from a graph (XI-D.6)</li>
                                            <li>Slope-intercept form: write an equation (XI-D.7)</li>
                                            <li>Write linear functions to solve word problems (XI-D.10)</li>
                                            <li>Write equations in standard form (XI-D.12)</li>
                                            <li>Standard form: find x- and y-intercepts (XI-D.13)</li>
                                            <li>Standard form: graph an equation (XI-D.14)</li>
                                            <li>Equations of horizontal and vertical lines (XI-D.15)</li>
                                            <li>Graph a horizontal or vertical line (XI-D.16)</li>
                                            <li>Slopes of parallel and perpendicular lines (XI-D.17) </li>
                                            <li>Write an equation for a parallel or perpendicular line (XI-D.18)</li>

                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>

            </div>


            <div style={{ marginLeft: "110px" }}>
                <ul style={{ listStyle: "none" }}>
                    <li>
                        <ul style={{ listStyle: "none" }}>
                            <li><h6>11.III.2 Conic Sections</h6>
                                <ul style={{ listStyle: "none" }}>
                                    <li><h6>11.III.2.1 Sections of a cone: Circles, ellipse, parabola, hyperbola, a point, a straight line and pair of intersecting lines as a degenerated case of a conic section. Standard equations and simple properties of parabola, ellipse and hyperbola. Standard equation of a circle..
                                    </h6>
                                        <ul style={{ color: "green" }}>
                                            <li>Identify the direction a parabola opens (XI-K.1)</li>
                                            <li>Find the vertex of a parabola (XI-K.2)</li>
                                            <li>Find the focus or directrix of a parabola (XI-K.3)</li>
                                            <li>Find the axis of symmetry of a parabola (XI-K.4)</li>
                                            <li>Write equations of parabolas in vertex form from graphs (XI-K.5)</li>
                                            <li>Write equations of parabolas in vertex form using properties (XI-K.6)</li>
                                            <li>Convert equations of parabolas from general to vertex form (XI-K.7)</li>
                                            <li>Find properties of a parabola from equations in general form (XI-K.8)</li>
                                            <li>Graph parabolas (XI-K.9)</li>
                                            <li>Find the centre of a circle (XI-L.1)</li>
                                            <li>Find the radius or diameter of a circle (XI-L.2)</li>
                                            <li>Write equations of circles in standard form from graphs (XI-L.3)</li>
                                            <li>Write equations of circles in standard form using properties (XI-L.4)</li>
                                            <li>Convert equations of circles from general to standard form (XI-L.5)</li>
                                            <li>Find properties of circles from equations in general form (XI-L.6)</li>
                                            <li>Graph circles (XI-L.7)</li>
                                            <li>Find the center, vertices, or co-vertices of an ellipse (XI-M.1)</li>
                                            <li>Find the length of the major or minor axis of an ellipse (XI-M.2)</li>
                                            <li>Find the foci of an ellipse (XI-M.3)</li>
                                            <li>Write equations of ellipses in standard form from graphs (XI-M.4)</li>
                                            <li>Write equations of ellipses in standard form using properties (XI-M.5)</li>
                                            <li>Convert equations of ellipses from general to standard form (XI-M.6)</li>
                                            <li>Find properties of ellipses from equations in general form (XI-M.7)</li>
                                            <li>Find the center of a hyperbola (XI-N.1)</li>
                                            <li>Find the vertices of a hyperbola (XI-N.2)</li>
                                            <li>Find the length of the transverse or conjugate axes of a hyperbola (XI-N.3)</li>
                                            <li>Find the equations for the asymptotes of a hyperbola (XI-N.4)</li>
                                            <li>Find the foci of a hyperbola (XI-N.5)</li>
                                            <li>Write equations of hyperbolas in standard form from graphs (XI-N.6)</li>
                                            <li>Write equations of hyperbolas in standard form using properties (XI-N.7)</li>
                                            <li>Convert equations of hyperbolas from general to standard form (XI-N.8)</li>
                                            <li>Find properties of hyperbolas from equations in general form (XI-N.9)</li>

                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>

            </div>

            <ul style={{ listStyle: "none", marginLeft: "110px" }}>
                <li><h6>11.III.3 Introduction to Three-dimensional Geometry</h6>
                    <ul style={{ listStyle: "none" }}>
                        <li><h6>11.III.3.1 Coordinate axes and coordinate planes in three dimensions. Coordinates of a point. Distance between two points and section formula. </h6>
                        </li>
                    </ul>
                </li>
            </ul>



            <div style={{ marginLeft: "110px" }}>
                <h3 style={{ color: "red" }}>11.IV Calculus</h3>
                <ul style={{ listStyle: "none" }}>
                    <li>
                        <ul style={{ listStyle: "none" }}>
                            <li><h6>11.IV. Limits and Derivatives</h6>
                                <ul style={{ listStyle: "none" }}>
                                    <li><h6>11.IV.1 Derivative introduced as rate of change both as that of distance function and geometrically, intuitive idea of limit. lim as x → 0 (loge(1 + x))/x, lim as x → 0 (e to the x power - 1)/x. Definition of derivative, relate it to slope of tangent of the curve, derivative of sum, difference, product and quotient of functions. Derivatives of polynomial and trigonometric functions.</h6>
                                        <ul style={{ color: "green" }}>
                                            <li>Find limits using graphs (XI-Y.1)</li>
                                            <li>Find one-sided limits using graphs (XI-Y.2)</li>
                                            <li>Find one-sided limits using graphs (XI-Y.2)</li>
                                            <li>Determine if a limit exists (XI-Y.3)</li>
                                            <li>Find limits using addition, subtraction and multiplication laws (XI-Z.1)</li>
                                            <li>Find limits using the division law (XI-Z.2)</li>
                                            <li>Find limits using power and root laws (XI-Z.3)</li>
                                            <li>Find limits using limit laws (XI-Z.4)</li>
                                            <li>Find limits of polynomials and rational functions (XI-Z.5)</li>
                                            <li>Find limits involving factorisation and rationalisation (XI-Z.6)</li>
                                            <li>Average rate of change I (XI-DD.1)</li>
                                            <li>Average rate of change II (XI-DD.2)</li>
                                            <li>Find instantaneous rates of change (XI-DD.3)</li>
                                            <li>Velocity as a rate of change (XI-DD.4)</li>
                                            <li>Find values of derivatives using limits (XI-DD.5)</li>
                                            <li>Find the slope of a tangent line using limits (XI-DD.6)</li>
                                            <li>Find equations of tangent lines using limits (XI-DD.7)</li>
                                            <li>Sum and difference rules (XI-EE.1)</li>
                                            <li>Product rule (XI-EE.2)</li>
                                            <li>Quotient rule (XI-EE.3)</li>
                                            <li>Power rule I (XI-EE.4)</li>
                                            <li>Power rule II (XI-EE.5)</li>
                                            <li>Find derivatives of polynomials (XI-EE.6)</li>
                                            <li>Find derivatives of rational functions (XI-EE.7)</li>
                                            <li>Find derivatives of trigonometric functions I (XI-EE.8)</li>
                                            <li>Find derivatives of trigonometric functions II (XI-EE.9)</li>
                                            <li>Chain rule (XI-EE.10)</li>
                                            <li>Find derivatives using the product rule (XI-EE.12)</li>
                                            <li>Find derivatives using the quotient rule I (XI-EE.13)</li>
                                            <li>Find derivatives using the quotient rule II (XI-EE.14)</li>
                                            <li>Find derivatives using the chain rule I (XI-EE.15)</li>
                                            <li>Find derivatives using the chain rule II (XI-EE.16)</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>

            </div>


            <div style={{ marginLeft: "110px" }}>
                <h3 style={{ color: "red" }}>11.V Mathematical Reasoning</h3>
                <ul style={{ listStyle: "none" }}>
                    <li>
                        <ul style={{ listStyle: "none" }}>

                            <ul style={{ listStyle: "none" }}>
                                <li><h6>11.V.1 Mathematically acceptable statements. Connecting words/phrases – consolidating the understanding of "if and only if (necessary and sufficient) condition", "implies", "and/or", "implied by", "and", "or", "there exists" and their use through variety of examples related to real life and Mathematics. Validating the statements involving the connecting words – difference between contradiction, converse and contrapositive..</h6>
                                    <ul style={{ color: "green" }}>
                                        <li>Identify hypotheses and conclusions (X-W.1)</li>
                                        <li>Counterexamples (X-W.2)</li>
                                        <li>Truth tables (X-W.3)</li>
                                        <li>Truth values (X-W.4)</li>
                                        <li>Conditionals (X-W.5)</li>
                                        <li>Negations (X-W.6)</li>
                                        <li>Converses, inverses and contrapositives (X-W.7)</li>
                                        <li>Biconditionals (X-W.8)</li>

                                    </ul>
                                </li>
                            </ul>
                        </ul>
                    </li>
                </ul>

            </div>



            <div style={{ marginLeft: "110px" }}>
                <h3 style={{ color: "red" }}>11.VI Statistics and Probability</h3>
                <ul style={{ listStyle: "none" }}>
                    <li>
                        <ul style={{ listStyle: "none" }}>
                            <li><h6>11.VI.1 Statistics</h6>
                                <ul style={{ listStyle: "none" }}>
                                    <li><h6>11.VI.1.1 Measure of dispersion; mean deviation, variance and standard deviation of ungrouped/grouped data. Analysis of frequency distributions with equal means but different variances.</h6>
                                        <ul style={{ color: "green" }}>
                                            <li>Mean absolute deviation (XI-GG.4)</li>
                                            <li>Variance and standard deviation (XI-GG.5)</li>

                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>

            </div>

            <ul style={{ listStyle: "none", marginLeft: "110px" }}>
                <li><h6>11.VI.2 Probability</h6>
                    <ul style={{ listStyle: "none" }}>
                        <li><h6>11.VI.2.1 Random experiments: Outcomes, sample spaces (set representation). Events: Occurrence of events, 'not', 'and' & 'or' events, exhaustive events, mutually exclusive events. Axiomatic (set theoretic) probability, connections with the theories of earlier classes. Probability of an event, probability of 'not', 'and' & 'or' events.</h6>

                            <li>Theoretical and experimental probability (XI-FF.2)</li>
                            <li>Compound events: find the number of outcomes (XI-FF.3)</li>
                            <li>Calculate probabilities of events (XI-FF.4)</li>
                            <li>Identify independent events (XI-FF.10)</li>
                            <li>Probability of independent and dependent events (XI-FF.11)</li>
                            <li>Geometric probability (XI-FF.12)</li>

                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default Class11









