
import React from 'react';
//  import './class.css';

const Classxii = () => {
    const categories = [

        {
            title: "Functions",
            items: [
                { label: "A.1", description: "Domain and range" },
                { label: "A.2", description: "Identify functions" },
                { label: "A.3", description: "Find the slope of a linear function" },
                { label: "A.4", description: "Graph a linear function" },
                { label: "A.5", description: "Write the equation of a linear function" },
                { label: "A.6", description: "Evaluate functions" },
                { label: "A.7", description: "Find values using function graphs" },
                { label: "A.8", description: "Complete a table for a function graph" },
                { label: "A.9", description: "Add, subtract, multiply and divide functions" },
                { label: "A.10", description: "Composition of functions" },
                { label: "A.11", description: "Identify inverse functions" },
                { label: "A.12", description: "Find values of inverse functions from tables" },
                { label: "A.13", description: "Find values of inverse functions from graphs" },
                { label: "A.14", description: "Find inverse functions and relations" }
            ]
        },
        {
            title: "Families of functions",
            items: [
                { label: "B.1", description: "Translations of functions" },
                { label: "B.2", description: "Reflections of functions" },
                { label: "B.3", description: "Dilations of functions" },
                { label: "B.4", description: "Transformations of functions" },
                { label: "B.5", description: "Function transformation rules" },
                { label: "B.6", description: "Describe function transformations" }
            ]
        },
        {
            title: "Polynomials",
            items: [
                { label: "C.1", description: "Divide polynomials using long division" },
                { label: "C.2", description: "Divide polynomials using synthetic division" },
                { label: "C.3", description: "Evaluate polynomials using synthetic division" },
                { label: "C.4", description: "Write a polynomial from its roots" },
                { label: "C.5", description: "Find the roots of factorised polynomials" },
                { label: "C.6", description: "Rational root theorem" },
                { label: "C.7", description: "Complex conjugate theorem" },
                { label: "C.8", description: "Conjugate root theorems" },
                { label: "C.9", description: "Descartes' Rule of Signs" },
                { label: "C.10", description: "Fundamental Theorem of Algebra" },
                { label: "C.11", description: "Match polynomials and graphs" },
                { label: "C.12", description: "Factorise sums and differences of cubes" },
                { label: "C.13", description: "Solve equations with sums and differences of cubes" },
                { label: "C.14", description: "Factorise using a quadratic pattern" },
                { label: "C.15", description: "Solve equations using a quadratic pattern" },
                { label: "C.16", description: "Pascal's triangle" },
                { label: "C.17", description: "Pascal's triangle and the Binomial Theorem" },
                { label: "C.18", description: "Binomial Theorem I" },
                { label: "C.19", description: "Binomial Theorem II" }
            ]
        },
        {
            title: "Systems of inequalities",
            items: [
                { label: "D.1", description: "Solve systems of linear inequalities by graphing" },
                { label: "D.2", description: "Solve systems of linear and absolute value inequalities by graphing" },
                { label: "D.3", description: "Find the vertices of a solution set" },
                { label: "D.4", description: "Linear programming" }
            ]
        },
        {
            title: "Nonlinear inequalities",
            items: [
                { label: "E.1", description: "Graph solutions to quadratic inequalities" },
                { label: "E.2", description: "Solve quadratic inequalities" },
                { label: "E.3", description: "Graph solutions to higher-degree inequalities" },
                { label: "E.4", description: "Solve higher-degree inequalities" }
            ]
        },
        {
            title: "Matrices",
            items: [
                { label: "F.1", description: "Matrix vocabulary" },
                { label: "F.2", description: "Matrix operation rules" },
                { label: "F.3", description: "Add and subtract matrices" },
                { label: "F.4", description: "Multiply a matrix by a scalar" },
                { label: "F.5", description: "Linear combinations of matrices" },
                { label: "F.6", description: "Multiply two matrices" },
                { label: "F.7", description: "Simplify matrix expressions" },
                { label: "F.8", description: "Solve matrix equations" },
                { label: "F.9", description: "Determinant of a matrix" },
                { label: "F.10", description: "Is a matrix invertible?" },
                { label: "F.11", description: "Inverse of a 2 x 2 matrix" },
                { label: "F.12", description: "Inverse of a 3 x 3 matrix" },
                { label: "F.13", description: "Identify inverse matrices" },
                { label: "F.14", description: "Solve matrix equations using inverses" }
            ]
        },
        {
            title: "Trigonometry",
            items: [
                { label: "G.1", description: "Convert between radians and degrees" },
                { label: "G.2", description: "Radians and arc length" },
                { label: "G.3", description: "Quadrants" },
                { label: "G.4", description: "Coterminal and reference angles" },
                { label: "G.5", description: "Find trigonometric ratios using right triangles" },
                { label: "G.6", description: "Find trigonometric ratios using the unit circle" },
                { label: "G.7", description: "Find trigonometric ratios using reference angles" },
                { label: "G.8", description: "Inverses of trigonometric functions" },
                { label: "G.9", description: "Solve trigonometric equations" },
                { label: "G.10", description: "Trigonometric ratios: find a side length" },
                { label: "G.11", description: "Trigonometric ratios: find an angle measure" },
                { label: "G.12", description: "Solve a right triangle" },
                { label: "G.13", description: "Law of Sines" },
                { label: "G.14", description: "Law of Cosines" },
                { label: "G.15", description: "Solve a triangle" },
                { label: "G.16", description: "Area of a triangle: sine formula" },
                { label: "G.17", description: "Area of a triangle: Heron's formula" }
            ]
        },
        {
            title: "Trigonometric functions",
            items: [
                { label: "H.1", description: "Find properties of sine functions" },
                { label: "H.2", description: "Write equations of sine functions from graphs" },
                { label: "H.3", description: "Write equations of sine functions using " },
                { label: "H.4", description: "Graph sine functions" },
                { label: "H.5", description: "Find properties of cosine functions" },
                { label: "H.6", description: "Write equations of cosine functions from graphs" },
                { label: "H.7", description: "Write equations of cosine functions using " },
                { label: "H.8", description: "Graph cosine functions" },
                { label: "H.9", description: "Graph sine and cosine functions" }
            ]
        },
        {
            title: "Trigonometric identities",
            items: [
                { label: "I.1", description: "Pythagorean identities" },
                { label: "I.2", description: "Reciprocal identities" },
                { label: "I.3", description: "Quotient identities" },
                { label: "I.4", description: "Sum and difference identities" },
                { label: "I.5", description: "Double angle identities" },
                { label: "I.6", description: "Half angle identities" },
                { label: "I.7", description: "Using identities to simplify trigonometric expressions" },
                { label: "I.8", description: "Using identities to verify trigonometric identities" },
                { label: "I.9", description: "Using identities to solve trigonometric equations" },
                { label: "I.10", description: "Using identities to evaluate trigonometric expressions" }
            ]
        },
        {
            title: "Statistics and probability",
            items: [
                { label: "J.1", description: "Data types" },
                { label: "J.2", description: "Collecting data" },
                { label: "J.3", description: "Representing data" },
                { label: "J.4", description: "Measures of central tendency" },
                { label: "J.5", description: "Measures of variability" },
                { label: "J.6", description: "Probability vocabulary" },
                { label: "J.7", description: "Probability rules" },
                { label: "J.8", description: "Independent events" },
                { label: "J.9", description: "Dependent events" },
                { label: "J.10", description: "Conditional probability" },
                { label: "J.11", description: "Sample spaces" },
                { label: "J.12", description: "Counting methods" },
                { label: "J.13", description: "Random variables" },
                { label: "J.14", description: "Binomial distributions" },
                { label: "J.15", description: "Normal distributions" },
                { label: "J.16", description: "Measures of association" },
                { label: "J.17", description: "Statistical inference" }
            ]
        },
        {
            title: "Continuity",
            items: [
                { label: "K.1", description: "Identify graphs of continuous functions" },
                { label: "K.2", description: "Determine continuity using graphs" },
                { label: "K.3", description: "Determine one-sided continuity using graphs" },
                { label: "K.4", description: "Find and analyze points of discontinuity using " },
                { label: "K.5", description: "Determine continuity on an interval using graphs" },
                { label: "K.6", description: "Determine the continuity of a piecewise function " },
                { label: "K.7", description: "Make a piecewise function continuous" },
                { label: "K.8", description: "Intermediate Value Theorem" }
            ]
        },
        {
            title: "Introduction to Derivatives",
            items: [
                { label: "R.1", description: "Average rate of change I" },
                { label: "R.2", description: "Average rate of change II" },
                { label: "R.3", description: "Find instantaneous rates of change" },
                { label: "R.4", description: "Velocity as a rate of change" },
                { label: "R.5", description: "Find values of derivatives using limits" },
                { label: "R.6", description: "Find the slope of a tangent line using limits" },
                { label: "R.7", description: "Find equations of tangent lines using limits" }
            ]
        },
        {
            title: "Derivative Rules",
            items: [
                { label: "S.1", description: "Sum and difference rules" },
                { label: "S.2", description: "Product rule" },
                { label: "S.3", description: "Quotient rule" },
                { label: "S.4", description: "Power rule I" },
                { label: "S.5", description: "Power rule II" },
                { label: "S.6", description: "Chain rule" },
                { label: "S.7", description: "Inverse function rule" }
            ]
        },
        {
            title: "Calculate Derivatives",
            items: [
                { label: "T.1", description: "Find derivatives of polynomials" },
                { label: "T.2", description: "Find derivatives of rational functions" },
                { label: "T.3", description: "Find derivatives of trigonometric functions I" },
                { label: "T.4", description: "Find derivatives of trigonometric functions II" },
                { label: "T.5", description: "Find derivatives of exponential functions" },
                { label: "T.6", description: "Find derivatives of logarithmic functions" },
                { label: "T.7", description: "Find derivatives of inverse trigonometric " },
                { label: "T.8", description: "Find derivatives of radical functions" },
                { label: "T.9", description: "Find derivatives using the product rule I" },
                { label: "T.10", description: "Find derivatives using the product rule II" },
                { label: "T.11", description: "Find derivatives using the quotient rule I" },
                { label: "T.12", description: "Find derivatives using the quotient rule II" },
                { label: "T.13", description: "Find derivatives using the chain rule I" },
                { label: "T.14", description: "Find derivatives using the chain rule II" }
            ]
        },
        {
            title: "Derivative Strategies",
            items: [
                { label: "U.1", description: "Find derivatives using implicit differentiation" },
                { label: "U.2", description: "Find tangent lines using implicit differentiation" },
                { label: "U.3", description: "Find derivatives using logarithmic differentiation" }
            ]
        },
        {
            title: "Calculate Higher Derivatives",
            items: [
                { label: "V.1", description: "Find higher derivatives of polynomials" },
                { label: "V.2", description: "Find higher derivatives of rational and radical " },
                { label: "V.3", description: "Find second derivatives of trigonometric, " },
                { label: "V.4", description: "Find higher derivatives using patterns" }
            ]
        },
        {
            title: "Probability",
            items: [
                { label: "W.1", description: "Introduction to probability" },
                { label: "W.2", description: "Calculate probabilities of events" },
                { label: "W.3", description: "Combinations and permutations" },
                { label: "W.4", description: "Find probabilities using combinations and " },
                { label: "W.5", description: "Find probabilities using two-way frequency " },
                { label: "W.6", description: "Identify independent events" },
                { label: "W.7", description: "Find conditional probabilities" },
                { label: "W.8", description: "Independence and conditional probability" },
                { label: "W.9", description: "Find conditional probabilities using two-way" },
                { label: "W.10", description: "Find probabilities using the addition rule" }
            ]
        },
        {
            title: "Probability Distributions",
            items: [
                { label: "X.1", description: "Identify discrete and continuous random " },
                { label: "X.2", description: "Write a discrete probability distribution" },
                { label: "X.3", description: "Graph a discrete probability distribution" },
                { label: "X.4", description: "Expected values of random variables" },
                { label: "X.5", description: "Variance of random variables" },
                { label: "X.6", description: "Standard deviation of random variables" },
                { label: "X.7", description: "Write the probability distribution for a game of " },
                { label: "X.8", description: "Expected values for a game of chance" },
                { label: "X.9", description: "Choose the better bet" },
                { label: "X.10", description: "Find probabilities using the binomial distribution" },
                { label: "X.11", description: "Mean, variance and standard deviation of  " },
                { label: "X.12", description: "Find probabilities using the normal distribution I" },
                { label: "X.13", description: "Find probabilities using the normal distribution II" },
                { label: "X.14", description: "Find z-values" },
                { label: "X.15", description: "Find values of normal variables" },
                { label: "X.16", description: "Distributions of sample means" },
                { label: "X.17", description: "The Central Limit Theorem" },
                { label: "X.18", description: "Use normal distributions to approximate  " }
            ]
        },
        {
            title: "Statistics",
            items: [
                { label: "Y.1", description: "Match correlation coefficients to scatter plots" },
                { label: "Y.2", description: "Calculate correlation coefficients" },
                { label: "Y.3", description: "Find the equation of a regression line" },
                { label: "Y.4", description: "Interpret regression lines" },
                { label: "Y.5", description: "Analyse a regression line of a data set" },
                { label: "Y.6", description: "Analyse a regression line using statistics of a  " }
            ]
        },
        {
            title: "Measurement",
            items: [
                { label: "J.1", description: "Coin values" },
                { label: "J.2", description: "Count 1-rupee coins" }
            ]
        }
    ];

    const menuItems = [
        { label: 'L', text: 'Lower kindergarten' },
        { label: 'U', text: 'Upper kindergarten' },
        { label: 'I', text: 'Level I' },
        { label: 'II', text: 'Level II' },
        { label: 'III', text: 'Level III' },
        { label: 'IV', text: 'Level IV' },
        { label: 'V', text: 'Level V' },
        { label: 'VI', text: 'Level VI' },
        { label: 'VII', text: 'Level VII' },
        { label: 'VIII', text: 'Level VIII' },
    ];

    return (
        <div className="maths-container">
            <aside className="menu">
                {menuItems.map((item, index) => (
                    <div
                        key={index}
                        className={`menu-item menu-item-${item.label}`}
                        data-text={item.text}
                    >
                        {item.label}
                    </div>
                ))}
            </aside>
            <div className="content-maths">
                {/* ... (keep the rest of the content as is) */}

                <div className="category intro">
                    <h3>Class-xii maths</h3>
                    <p>Here is a list of all of the maths skills students learn in UKG! These skills are organised into categories, and you can move your mouse over any skill name to preview the skill. To start practising, just click on any link. STEM WORLD will track your score, and the questions will automatically increase in difficulty as you improve!</p>
                </div>
                <div className="category-list">
                    {categories.map((category, index) => (
                        <div key={index} className="category">
                            <h3>{category.title}</h3>
                            <ul>
                                {category.items.map((item, i) => (
                                    <li key={i}>
                                        <span className="item-label">{item.label}</span>
                                        <span className="item-description">{item.description}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Classxii;