// Contact.jsx
import React from "react";
import './contact.css';
import { Helmet } from "react-helmet";
import Mapadd from "../map-add/map-add";
import ContactInfo from "./ContactInfo";
import ContactForm from "./ContactForm";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export function Contact() {
    
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is coming from the homepage
        const fromHome = sessionStorage.getItem('fromHome');

        if (fromHome) {
            // If they refreshed, redirect to the homepage
            navigate('/');
        } else {
            // Set a flag in sessionStorage to indicate the user has visited the product page
            sessionStorage.setItem('fromHome', 'true');
        }

        // Cleanup: Remove the flag when the component unmounts
        return () => {
            sessionStorage.removeItem('fromHome');
        };
    }, [navigate]);
    return (
        <div className="contact_page">
            <Helmet>
                <title>STEM WORLD | Contact</title>
            </Helmet>

            <h1 className="pg heading mt-5">Let's Talk with Stem World</h1>
            <div id="contact_stem" className="contact-container">
                <ContactInfo />
                <ContactForm />
            </div>
            <Mapadd />
        </div>
    );
}
