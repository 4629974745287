import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
// import Image from 'next/image'
import robo from './robo.png'
import Ai from './Ai.png'
import IOT from './IOT.png'
import face from './face.png'
import coding from './coding.png'
import drone from './drone.png'
import arvr from './arvr.png'
import gamedev from './gamedev.png'
import appdev from './appdev.png'
import threed from './3d.png'
import obj from './obj.png'
import handpose from './handpose.png'
import Stemverse from './Stemverse.jpg'
import Arm from './Arm.jpg'
import Car from './Car.jpg'
import Spider from './Spider.jpg'
import { motion } from 'framer-motion'
import { Button } from "../../ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../ui/card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs"
import { Code, Blocks, Users, Zap, Lightbulb, Rocket, Book, Globe, ChevronDown, ChevronUp } from 'lucide-react'

export default function StemversePage() {
  const [expandedFeature, setExpandedFeature] = useState()
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is coming from the homepage
    const fromHome = sessionStorage.getItem('fromHome');

    if (fromHome) {
      // If they refreshed, redirect to the homepage
      navigate('/');
    } else {
      // Set a flag in sessionStorage to indicate the user has visited the product page
      sessionStorage.setItem('fromHome', 'true');
    }

    // Cleanup: Remove the flag when the component unmounts
    return () => {
      sessionStorage.removeItem('fromHome');
    };
  }, [navigate]);

  const features = [
    {
      id: 'blockCoding',
      icon: <Blocks className="h-6 w-6" />,
      title: 'Block Coding',
      expandedContent: [
        {
          // title: 'Block Coding',
          // icon: <Blocks className="h-6 w-6" />,
          description: 'Block-based coding is a visual programming language where children create programs by connecting blocks of code rather than typing text. Each block represents a specific command or action, making it easy for kids to understand programming concepts without worrying about syntax. It’s often used in platforms like Scratch, where children can create games, animations, and interactive stories. This method helps develop logical thinking and problem-solving skills. The drag-and-drop interface makes it accessible for beginners. Block-based coding is an engaging way to introduce programming to young learners.',
          image: coding
        },

        {
          title: 'AI/ML',
          description: 'Stemverse offers a suite of AI/ML extensions designed to enhance interactive learning experiences. These include speech recognition, allowing users to convert spoken language into text, facilitating voice-driven applications. The natural language processing (NLP) extension enables sentiment analysis, language understanding, and text-based AI interactions. Object detection identifies and classifies various objects within images, while face detection pinpoints faces for applications like facial recognition and sentiment analysis. Lastly, hand pose detection captures hand movements and gestures, opening possibilities for gesture-based interactions in creative projects. Together, these tools provide a robust framework for learning and experimentation with AI-driven technologies.',
          image: Ai
        },
        {
          title: 'Robotics',
          description: "Stemverse’s robotics extensions introduce young learners to the basics of robotics in an engaging, hands-on way, emphasizing creativity and problem-solving skills. Through robots like the arm robot, spider robot, and car robot, children can explore core concepts like movement, automation, and control. The arm robot helps students understand the mechanics of gripping and lifting, while the spider robot teaches balance and multi-legged coordination. The car robot introduces navigation and directional control. Together, these tools foster critical thinking, encouraging children to build, program, and see the tangible results of their coding efforts, making STEM subjects both accessible and exciting.",
          image: robo
        },
        {
          title: 'IoT',
          description: 'The Internet of Things (IoT) extension in Stemverse introduces children to the interconnected world of smart devices and data-driven solutions, helping them understand how technology impacts everyday life. Learning about IoT empowers young minds to create innovative projects, like smart home setups or environmental monitors, where devices communicate with each other. By incorporating IoT, Stemverse enables students to explore real-world applications of programming and problem-solving, sparking interest in how technology can solve practical challenges. This hands-on experience with IoT nurtures critical thinking, creativity, and a foundational understanding of a technology increasingly shaping the future.',
          image: IOT
        },
        {
          title: 'Drones',
          description: 'Drones in Stemverse offer a unique, hands-on way for children to explore the principles of flight, navigation, and automation, making learning fun and interactive. By programming drones, students can develop skills in coding, problem-solving, and spatial awareness as they design flight paths and complete missions. Through activities like obstacle courses or aerial photography, drones provide an engaging platform for understanding concepts like physics, geometry, and engineering. This practical experience allows young learners to see the immediate impact of their programming, fostering a deeper interest in technology and how it applies to the real world.',
          image: drone
        },
        {
          title: 'AR VR',
          description: 'The AR (Augmented Reality) and VR (Virtual Reality) extensions in Stemverse provide children with immersive learning experiences that make abstract concepts tangible and engaging. AR overlays digital elements onto the real world, allowing learners to interact with 3D models and animations in their environment, enhancing subjects like science and history. VR transports students into fully virtual spaces where they can explore simulated worlds or historical settings, deepening understanding through interactive learning. Together, AR and VR encourage creativity, spatial skills, and hands-on exploration, making STEM subjects vivid and memorable while sparking curiosity about future technologies.',
          image: arvr
        },
        {
          title: '3D Printing',
          description: 'The 3D printing extension in Stemverse introduces children to the world of digital design and tangible creation, bridging the gap between virtual concepts and real-world objects. Through 3D printing, learners can design and bring to life models, from simple shapes to intricate structures, fostering creativity and hands-on problem-solving. This technology provides a practical way to explore geometry, engineering, and design thinking, as students visualize and iterate on their ideas. By printing their creations, children see their designs transformed into physical objects, boosting their confidence and encouraging innovation. This experience makes STEM learning interactive, engaging, and relevant to real-world applications.',
          image: threed
        },
        {
          title: 'App Development',
          description: 'The app development category in Stemverse, powered by MIT App Inventor, introduces children to mobile app creation in a beginner-friendly, block-based environment. Using this tool, students can design and build apps for smartphones and tablets, developing problem-solving skills as they see their ideas come to life. Through projects, they learn essential coding concepts like logic, loops, and conditionals, along with design principles for user interfaces. This hands-on experience gives children the confidence to create real-world solutions, helping them understand the impact of technology. App development with MIT App Inventor in Stemverse encourages creativity and empowers kids to become innovators in the digital world.',
          image: appdev
        },
        {
          title: 'Game Development',
          description: 'The game development extension in Stemverse provides children with an exciting way to learn programming by creating their own games. By building games, students develop critical skills such as logic, problem-solving, and creativity as they bring characters, stories, and challenges to life. Game development introduces coding concepts like loops, conditionals, and event handling, allowing learners to control game mechanics and interactions. This hands-on approach to learning helps students understand how games are structured and fosters teamwork when working on collaborative projects. Through game development, children gain a strong foundation in coding while enjoying the process of creating something fun and interactive.',
          image: gamedev
        },
      ]
    },
    // {
    //   id: 'collaboration',
    //   title: 'Collaboration',
    //   icon: <Users className="h-6 w-6" />,
    //   description: 'Work together on projects in real-time. Share ideas and learn from peers around the world.',
    //   expandedContent: [
    //     {
    //       title: 'Real-time Editing',
    //       description: "Collaborate with teammates in real-time, seeing each other's changes instantly as you work on your project together.",
    //       image: robo
    //     },
    //     {
    //       title: 'Project Sharing',
    //       description: 'Easily share your projects with the Stemverse community or keep them private. Get feedback and inspiration from peers worldwide.',
    //       image: robo
    //     },
    //     {
    //       title: 'Version Control',
    //       description: "Keep track of your project's history with built-in version control, allowing you to revert changes or branch off to try new ideas.",
    //       image: robo
    //     }
    //   ]
    // },
    // Add more features here...
  ]

  const robotics = [Arm, Spider, Car]
  const extensions_img = [face, handpose, obj]
  const simulation = []

  const toggleFeature = (id) => {
    setExpandedFeature(expandedFeature === id ? null : id)
  }

  return (
    <div className="min-h-screen bg-white">
      <main className="container mx-auto px-4 py-12 w-full ">
        <section id="hero" className="mb-16 w-[90%]">
          <Card className="overflow-hidden w-full">
            <div className="relative">
              <div className="absolute inset-0 z-0">
                <img
                  src={robo}
                  alt="Coding Background"
                  layout="fill"
                  objectFit="cover"
                  className="object-center"
                />
              </div>
              <div className="relative z-0 bg-gradient-to-r from-purple-300/80 via-purple-400/80 to-purple-500/80 tra">
                <div className="p-8 md:p-12 text-white">
                  <motion.div
                    initial={{ opacity: 10, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                  >
                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-4 tracking-tight text-center">
                      Welcome to Stemverse
                    </h1>
                    <p className="text-xl md:text-2xl mb-6 max-w-2xl mx-auto text-center">
                      Unleash your creativity with block-based coding and join a global community of innovators!
                    </p>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                    className="flex justify-center space-x-4 mb-8"
                  >
                    <Button size="lg" className="bg-white text-blue-600 hover:bg-blue-50" onClick={() => window.open('https://stemverse.app', '_blank')}
                    >
                      Get Started
                    </Button>
                    <Button size="lg" variant="outline" className="bg-transparent border-white text-white hover:bg-white hover:text-blue-600">
                      Learn More
                    </Button>
                  </motion.div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 180" className="w-full h-auto">
                  <path fill="#ffffff" fillOpacity="1" d="M0,96L48,112C96,128,192,160,288,160C384,160,480,128,576,122.7C672,117,768,139,864,138.7C960,139,1056,117,1152,106.7C1248,96,1344,96,1392,96L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                </svg>
              </div>
            </div>
            <CardContent className="p-8 md:p-12 bg-white">
              <div className="grid md:grid-cols-2 gap-8 items-center">
                <div>
                  <CardTitle className="text-3xl font-bold mb-4 text-blue-600">About Stemverse</CardTitle>
                  <CardDescription className="text-lg mb-4">A revolutionary block-based coding platform</CardDescription>
                  <p className="text-gray-600 mb-4">
                    Stemverse is an innovative block-based coding platform designed to make programming accessible and fun for everyone. Whether you're a beginner or an experienced coder, our intuitive interface and powerful features will help you bring your ideas to life.
                  </p>
                  <p className="text-gray-600">
                    With Stemverse, you can create games, animations, interactive stories, and much more. Our platform encourages creativity, problem-solving, and computational thinking skills, preparing you for the digital future.
                  </p>
                </div>
                <div className="relative h-64 md:h-80 rounded-lg overflow-hidden shadow-lg">
                  <img
                    src={Stemverse}
                    alt="Stemverse Interface"
                    // layout="fill"
                    // objectFit="cover"
                    className="w-full h-full transition-transform duration-300 hover:scale-105 rounded"
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </section>

        <section id="features" className="mb-16 w-[90%]">
          <h2 className="text-3xl font-bold mb-8 text-center">Key Features</h2>
          <div className="grid gap-8">
            {features.map((feature, index) => (
              <Card key={feature.id} className="overflow-hidden bg-gradient-to-r from-purple-200 to-purple-400 rounded w-full">
                <CardContent className="p-6">
                  <div
                    className="flex items-center justify-between cursor-pointer"
                    onClick={() => toggleFeature(feature.id)}
                  >
                    <div className="flex items-center">
                      {feature.icon}
                      <h3 className="text-xl font-semibold ml-2">{feature.title}</h3>
                    </div>
                    {expandedFeature === feature.id ? <ChevronUp /> : <ChevronDown />}
                  </div>
                  <p className="mt-2 text-gray-600">{feature.description}</p>
                  <motion.div
                    initial={false}
                    animate={{ height: expandedFeature === feature.id ? 'auto' : 0, opacity: expandedFeature === feature.id ? 1 : 0 }}
                    transition={{ duration: 0.3 }}
                    className="overflow-hidden"
                  >
                    <div className="mt-4 space-y-8">
                      {feature.expandedContent.map((content, i) => (
                        // Inside the expandedContent map
                        <div key={i} className="flex flex-col md:flex-row gap-4 items-start">
                          {i % 2 === 0 ? (
                            <>
                              <div className="flex-1 order-1 md:order-1">
                                <h4 className="text-lg font-semibold mb-2">{content.title}</h4>
                                <p className="text-gray-600 text-justify pr-4">{content.description}</p>
                              </div>
                              <div className="relative w-full md:w-1/3 h-48 rounded-lg overflow-hidden order-2 md:order-2 float-right ml-4 mb-4">
                                <img
                                  src={content.image}
                                  alt={content.title}
                                  className="w-full h-full object-contain transition-transform duration-300 hover:scale-105"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="relative w-full md:w-1/3 h-48 rounded-lg overflow-hidden order-1 md:order-1 float-left mr-4 mb-4">
                                <img
                                  src={content.image}
                                  alt={content.title}
                                  className="w-full h-full object-contain transition-transform duration-300 hover:scale-105"
                                />
                              </div>
                              <div className="flex-1 order-2 md:order-2">
                                <h4 className="text-lg font-semibold mb-2">{content.title}</h4>
                                <p className="text-gray-600 text-justify pl-4">{content.description}</p>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </motion.div>
                </CardContent>
              </Card>
            ))}
          </div>
        </section>

        <section id="projects" className="mb-16 w-[90%]">
          <h2 className="text-3xl font-bold mb-8 text-center">Explore Projects</h2>
          <Tabs defaultValue="robotics" className="w-full rounded">
            <TabsList className="flex justify-between w-full mb-6 bg-gradient-to-r from-purple-200 to-purple-400 rounded">
              <TabsTrigger value="robotics" className={({ selected }) =>
                `px-4 py-2 rounded-lg transition duration-300 ${selected ? 'bg-white text-white shadow-lg' : 'bg-transparent text-gray-700'
                } hover:bg-white`
              }>Robotics</TabsTrigger>
              <TabsTrigger value="extensions" className={({ selected }) =>
                `px-4 py-2 rounded-lg transition duration-300 ${selected ? 'bg-white text-white shadow-lg' : 'bg-transparent text-gray-700'
                } hover:bg-white`
              }>Extensions</TabsTrigger>
              <TabsTrigger value="simulation" className={({ selected }) =>
                `px-4 py-2 rounded-lg transition duration-300 ${selected ? 'bg-white text-white shadow-lg' : 'bg-transparent text-gray-700'
                } hover:bg-white`
              }>Simulation</TabsTrigger>
            </TabsList>
            <TabsContent value="robotics" className="w-full bg-gradient-to-r from-purple-200 to-purple-400 w-full">
              <Card className="w-full p-4">
                <CardHeader>
                  <CardTitle>Create Exciting Robots</CardTitle>
                  <CardDescription>Build your own robots as we have created using block-based coding</CardDescription>
                </CardHeader>
                <CardContent className="w-full">
                  <div className="grid md:grid-cols-3 gap-4">
                    {robotics.map((i) => (
                      <div key={i} className="relative h-64 md:h-80 rounded-lg overflow-hidden shadow-lg bg-white rounded">
                        <img
                          src={i}
                          alt={`Game ${i}`}
                          className="w-full h-full transition-transform duration-300 hover:scale-105 rounded"
                        />
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
            <TabsContent value="extensions" className="w-full bg-gradient-to-r from-purple-200 to-purple-400 rounded">
              <Card className="w-full p-4">
                <CardHeader>
                  <CardTitle>Make your Own Project</CardTitle>
                  <CardDescription>Use our Extensions like Face detection, Hand Pose detection and object detection etc..to make more</CardDescription>
                </CardHeader>
                <CardContent className="w-full">
                  <div className="grid md:grid-cols-3 gap-4">
                    {extensions_img.map((i) => (
                      <div key={i} className="relative h-64 md:h-80 rounded-lg overflow-hidden shadow-lg rounded bg-white">
                        <img
                          src={i}
                          alt={`Animation ${i}`}
                          className="w-full h-full object-contain transition-transform duration-300 hover:scale-105"
                        />
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
            <TabsContent value="simulation" className="w-full bg-gradient-to-r from-purple-200 to-purple-400 rounded">
              <Card className="w-full p-4">
                <CardHeader>
                  <CardTitle>Learn Simulation with Stem Verse</CardTitle>
                  <CardDescription>Create engaging narratives with interactive elements</CardDescription>
                </CardHeader>
                <CardContent className="w-full">
                  <div className="grid md:grid-cols-3 gap-4">
                    {simulation.map((i) => (
                      <div key={i} className="relative h-64 md:h-80 rounded-lg overflow-hidden shadow-lg rounded bg-white">
                        <img
                          src={i}
                          alt={`Story ${i}`}
                          className="w-full h-full object-contain transition-transform duration-300 hover:scale-105"
                        />
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </section>

        <section id="cta" className="text-center w-[90%]">
          <Card className='bg-gradient-to-r from-purple-200 to-purple-400 w-full'>
            <CardContent className="pt-6">
              <h2 className="text-3xl font-bold mb-4">Ready to Start Coding?</h2>
              <p className="text-xl text-gray-600 mb-8">Join Stemverse today and unleash your creativity!</p>
              <Button size="lg" className="text-purple-600 bg-white" onClick={() => window.open('https://stemverse.app', '_blank')}
              >Sign Up Now
              </Button>
            </CardContent>
          </Card >
        </section>
      </main>

      <footer className="bg-gradient-to-r from-purple-200 to-purple-400 mt-16">
        <div className="container mx-auto px-4 py-8">
          <p className="text-center text-gray-600">&copy; 2023 Stemverse. All rights reserved.</p>
        </div>
      </footer>
    </div>
  )
}