import React from 'react';
import './ClassCard.css';
import { Link } from 'react-router-dom';

const ClassCard = ({ title, includes, skillsCount, color, level = 'Unspecified' }) => {
    // Define a dynamic path based on the skillsCount
    const getPath = () => {
        switch (skillsCount) {
            case 55:
                return '/LowerKindergartenMaths'; // Ensure this matches the path in your App.js
            case 144:
                return '/UpperKindergartenMaths'; // Replace with the actual path if needed
            case 164:
                return '/Classi'; // Replace with the actual path if needed
            case 151:
                return '/Classii'; // Replace with the actual path if needed
            case 199:
                return '/Classiii'; // Replace with the actual path if needed
            case 135:
                return '/Classiv'; // Replace with the actual path if needed
            case 142:
                return '/Classv'; // Replace with the actual path if needed
            case 168:
                return '/Classvi'; // Replace with the actual path if needed
            case 225:
                return '/Classvii'; // Replace with the actual path if needed
            case 247:
                return '/Classviii'; // Replace with the actual path if needed
            case 229:
                return '/Classix'; // Replace with the actual path if needed
            case 207:
                return '/Classx'; // Replace with the actual path if needed
            case 300:
                return '/Classxi'; // Replace with the actual path if neededss
            case 236:
                return '/Classxii'; // Replace with the actual path if needed
            // Add more cases as needed for different skill counts
            default:
                return `/${skillsCount}`; // Fallback path if no specific case matches
        }
    };

    return (
        <div className="class-card">
            <div
                className="level-indicator"
                style={{ backgroundColor: color }}
            >
                {level}
            </div>
            <div className="class-card-content">
                <h3>{title}</h3>
                <p>Includes: {includes.join(' | ')}</p>
            </div>
            <Link to={getPath()} className="see-skills-btn" style={{ backgroundColor: color }}>
                See all {skillsCount} skills
            </Link>
        </div>
    );
};

export default ClassCard;
