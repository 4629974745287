import React from 'react'
import Header_ixl from '../Header_IXL/header_ixl'
import '../Class-1/class-1.css';

function Class8() {
    return (
        <div className='class_1'>
            <div className="container">

                <Header_ixl />

                <div className="main-content">
                    <div className="left-content">
                        <h3>India</h3>
                        <h4>Skills available for India class VIII maths curriculum</h4>
                        <div className="description">
                            Objectives are in black and STEM WORLD maths skills are in dark green. Hold your mouse over the name of a skill to view a sample question. Click on the name of a skill to practise that skill.</div>
                    </div>

                    <div className="right-content">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
                            alt="India Flag"
                            className="india-flag"
                        />
                    </div>
                </div>

                <div style={{ display: "flex", width: "100%", marginTop: "20px", padding: "15px" }}>
                    <div style={{ width: "75%", background: "#f65314", padding: "20px" }}>
                        <h6>National Council of Education Research and Training Syllabus</h6>
                    </div>
                    <div style={{ width: "25%", background: "gray", padding: "20px" }}>
                        <h6>Actions</h6>
                        <p>Print curriculum</p>
                    </div>
                </div>
            </div>

            <div style={{ marginLeft: "110px" }}>
                <h3 style={{ color: "red" }}>8.NS Number System</h3>
                <ul style={{ listStyle: "none" }}>
                    <li><h6>8.NS.i Rational Numbers:</h6>
                        <ul style={{ listStyle: "none" }}>
                            <li><h6>8.NS.i.1 Properties of rational numbers. (including identities). Using general form of expression to describe propertie.</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Identify rational and irrational numbers (VIII-D.1)</li>
                                    <li>Round decimals and mixed numbers (VIII-D.4)</li>
                                    <li>Convert between decimals and fractions or mixed numbers (VIII-D.5)</li>
                                    <li>Reciprocals and multiplicative inverses (VIII-E.1)</li>

                                </ul>
                            </li>
                        </ul>
                    </li>


                    <ul style={{ listStyle: "none" }}>
                        <li><h6>8.NS.i.2 Consolidation of operations on rational numbers.</h6>
                            <ul style={{ color: "green" }}>
                                <li>Add and subtract rational numbers (VIII-E.2)</li>
                                <li>Add and subtract rational numbers: word problems (VIII-E.3)</li>
                                <li>Multiply and divide rational numbers (VIII-E.5)</li>
                                <li>Multiply and divide rational numbers: word problems (VIII-E.6)</li>
                                <li>Evaluate numerical expressions involving rational numbers (VIII-E.9)</li>
                                <li>Evaluate rational expressions (VIII-V.8)</li>

                            </ul>
                        </li>

                        <li><h6>8.NS.i.3 Representation of rational numbers on the number line</h6>

                            <ul style={{ color: "green" }}>
                                <li>Write fractions in lowest terms (VIII-D.2)</li>
                                <li>Lowest common denominator (VIII-D.3)</li>
                                <li>Compare rational numbers (VIII-D.6)</li>
                                <li>Put rational numbers in order (VIII-D.7)</li>


                            </ul>
                        </li>

                        <h6>8.NS.i.4 Between any two rational numbers there lies another rational number (Making children see that if we take two rational numbers then unlike for whole numbers, in this case you can keep finding more and more numbers that lie between them.)</h6>
                        <li><h6>8.NS.i.5 Word problem (higher logic, two operations, including ideas like area)</h6>

                            <ul style={{ color: "green" }}>
                                <li>HCF and LCM: word problems (VIII-A.7)</li>
                                <li>Percents of numbers: word problems (VIII-J.7)</li>
                                <li>Percent of change: word problems (VIII-J.11)</li>
                                <li>Multi-step word problems (VIII-M.1)</li>
                                <li>Guess-and-check word problems (VIII-M.2)</li>
                                <li>Use Venn diagrams to solve problems (VIII-M.3)</li>
                                <li>Elapsed time word problems (VIII-M.4)</li>
                                <li>Area and perimeter: word problems (VIII-T.4)</li>
                                <li>Circles: word problems (VIII-T.6)</li>
                                <li>Perimeter, area and volume: changes in scale (VIII-T.10)</li>

                            </ul>
                        </li>

                        <div>
                            <h6>8.NS.ii Powers</h6>

                            <li><h6>8.NS.ii.1 Integers as exponents..</h6>

                                <ul style={{ color: "green" }}>
                                    <li>Understanding exponents (VIII-F.1)</li>
                                    <li>Evaluate powers (VIII-F.2)</li>
                                    <li>Solve equations with variable exponents (VIII-F.3)</li>
                                    <li>Powers with negative bases (VIII-F.4)</li>
                                    <li>Powers with decimal and fractional bases (VIII-F.5)</li>

                                </ul>
                            </li>
                        </div>




                        <li><h6>8.NS.ii.2 Laws of exponents with integral powers.</h6>

                            <ul style={{ color: "green" }}>
                                <li>Understanding negative exponents (VIII-F.6)</li>
                                <li>Evaluate powers with negative exponents (VIII-F.7)</li>
                                <li>Multiplication with exponents (VIII-F.8)</li>
                                <li>Division with exponents (VIII-F.9)</li>
                                <li>Multiplication and division with exponents (VIII-F.10)</li>
                                <li>Power rule (VIII-F.11)</li>
                                <li>Evaluate expressions using properties of exponents (VIII-F.12)</li>
                                <li>Identify equivalent expressions involving exponents (VIII-F.13)</li>

                            </ul>
                        </li>



                        <h6>8.NS.iii Squares, Square roots, Cubes, Cube roots.</h6>
                        <li><h6>8.NS.iii.1 Square and Square roots</h6>

                            <ul>
                                <li>Square roots of perfect squares (VIII-F.14)</li>
                                <li>Relationship between squares and square roots (VIII-F.17)</li>
                                <li>Evaluate radical expressions (VIII-V.7)</li>

                            </ul>
                        </li>


                        <li><h6>18.NS.iii.2 Square roots using factor method and division method for numbers containing (a) no more than total 4 digits and (b) no more than 2 decimal places</h6>
                            <li><h6>8.NS.iii.3 Cubes and cubes roots (only factor method for numbers containing at most 3 digits)</h6></li>

                            <ul style={{ color: "green" }}>
                                <li>Cube roots of perfect cubes (VIII-F.19)</li>
                                <li>Solve equations involving cubes and cube roots (VIII-F.21)</li>


                                <li><h6>8.NS.iii.4 Estimating square roots and cube roots. Learning the process of moving nearer to the required number.</h6></li>

                                <ul style={{ color: "green" }}>
                                    <li>Estimate positive and negative square roots (VIII-F.16)</li>
                                    <li>Estimate cube roots (VIII-F.20)</li>
                                </ul>

                            </ul>


                            <li><h6>8.NS.iii.4 Estimating square roots and cube roots. Learning the process of moving nearer to the required number.</h6></li>

                            <ul style={{ color: "green" }}>
                                <li>Estimate positive and negative square roots (VIII-F.16)</li>
                                <li>Estimate cube roots (VIII-F.20)</li>
                            </ul>
                        </li>

                        <li><h6>8.NS.iv Playing with numbers</h6></li>
                        <li><h6>8.NS.iv.1 Writing and understanding a 2 and 3 digit number in generalized form (100a + 10b + c, where a, b, c can be only digit 0-9) and engaging with various puzzles concerning this. (Like finding the missing numerals represented by alphabets in sums involving any of the four operations.) Children to solve and create problems and puzzles.</h6></li>

                        <li><h6>8.NS.iv.2 Number puzzles and games</h6></li>
                        <li><h6>8.NS.iv.3 Deducing the divisibility test rules of 2, 3, 5, 9, 10 for a two or three-digit number expressed in the general form.</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Divisibility rules (VIII-A.2)</li>
                        </ul>


                        <h3 style={{ color: "red" }}>8.A Algebra</h3>
                        <li><h6>8.A.A Algebraic Expressions</h6></li>
                        <li><h6>8.A.A.1 Multiplication and division of algebraic exp. (Coefficient should be integers)</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Multiply using the distributive property (VIII-V.12)</li>
                            <li>Add, subtract and multiply linear expressions (VIII-V.15)</li>
                            <li>Identify equivalent linear expressions (VIII-V.17)</li>
                            <li>Multiply monomials (VIII-X.6)</li>
                            <li>Divide monomials (VIII-X.7)</li>
                            <li>Multiply and divide monomials (VIII-X.8)</li>
                            <li>Multiply polynomials using algebra tiles (VIII-X.11)</li>
                            <li>Multiply polynomials (VIII-X.12)</li>
                            <li>Multiply polynomials to find area (VIII-X.13)</li>
                        </ul>


                        <li><h6>8.A.A.2 Some common errors (e.g. 2 + x ≠ 2x, 7x + y ≠ 7xy)</h6></li>
                        <li><h6>8.A.A.3 Identities (a ± b)² = a² ± 2ab + b², a² – b² = (a – b) (a + b) Factorisation (simple cases only) as examples the following types a(x + y), (x ± y)², a² – b², (x + a).(x + b)</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Sort factors of expressions (VIII-V.10)</li>
                            <li>Factors of linear expressions (VIII-V.16)</li>
                            <li>Factorise out a monomial (VIII-Y.2)</li>
                            <li>Factorise quadratics with leading coefficient 1 (VIII-Y.3)</li>
                            <li>Factorise quadratics: special cases (VIII-Y.5)</li>
                            <li>Factorise by grouping (VIII-Y.7)</li>
                            <li>Factorise polynomials (VIII-Y.8)</li>

                        </ul>

                        <li><h6>8.A.A.4 Solving linear equations in one variable in contextual problems involving multiplication and division (word problems) (avoid complex coefficient in the equations)</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Model and solve equations using algebra tiles (VIII-W.3)</li>
                            <li>Write and solve equations that represent diagrams (VIII-W.4)</li>
                            <li>Solve one-step equations (VIII-W.6)</li>
                            <li>Solve two-step equations (VIII-W.7)</li>
                            <li>Solve multi-step equations (VIII-W.8)</li>
                            <li>Solve equations involving like terms (VIII-W.9)</li>
                            <li>Solve equations: complete the solution (VIII-W.10)</li>
                            <li>Solve equations: word problems (VIII-W.11)</li>

                        </ul>

                        <h3 style={{ color: "red" }}>8.RP Ratio and Proportion</h3>
                        <li><h6>8.RP.1 Slightly advanced problems involving applications on percentages, profit & loss, overhead expenses, Discount, tax.</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Convert between percents, fractions and decimals (VIII-J.1)</li>
                            <li>Compare percents to fractions and decimals (VIII-J.2)</li>
                            <li>Identify equivalent linear expressions (VIII-V.17)</li>
                            <li>Find what percent one number is of another (VIII-J.3)</li>
                            <li>Find what percent one number is of another: word problems (VIII-J.4)</li>
                            <li>Estimate percents of numbers (VIII-J.5)</li>
                            <li>Percents of numbers and money amounts (VIII-J.6)</li>
                            <li>Percents of numbers: word problems (VIII-J.7)</li>
                            <li>Compare percents of numbers (VIII-J.8)</li>
                            <li>Solve percent equations (VIII-J.9)</li>
                            <li>Percent of change (VIII-J.10)</li>
                            <li>Percent of change: word problems (VIII-J.11)</li>
                            <li>Price lists (VIII-K.1)</li>
                            <li>Unit prices (VIII-K.2)</li>
                            <li>Unit prices: find the total price (VIII-K.3)</li>
                            <li>Percent of a number: VAT, discount and more (VIII-K.4)</li>
                            <li>Find the percent: discount and mark-up (VIII-K.5)</li>
                            <li>Sale prices: find the original price (VIII-K.6)</li>
                            <li>Multi-step problems with percents (VIII-K.7)</li>
                            <li>Estimate tips (VIII-K.8)</li>
                        </ul>

                        <li><h6>8.RP.2 Difference between simple and compound interest (compounded yearly up to 3 years or half-yearly up to 3 steps only), Arriving at the formula for compound interest through patterns and using it for simple problems.</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Simple interest (VIII-K.9)</li>
                            <li>Compound interest (VIII-K.10)</li>
                        </ul>

                        <li><h6>8.RP.3 Direct variation  Simple and direct word problems</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Graph proportional relationships (VIII-I.7)</li>
                            <li>Interpret graphs of proportional relationships (VIII-I.8)</li>
                            <li>Write and solve equations for proportional relationships (VIII-I.9)</li>
                        </ul>

                        <li><h6>8.RP.4 Inverse variation – Simple and direct word problems</h6></li>
                        <li><h6>8.RP.5 Time & work problems – Simple and direct word problems</h6></li>


                        <h3 style={{ color: "red" }}>8.G Geometry</h3>
                        <li><h6>8.G.i Understanding shapes:</h6></li>
                        <li><h6>8.G.i.1 Properties of quadrilaterals ‐ Sum of angles of a quadrilateral is equal to 3600 (By verification)</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Find missing angles in triangles and quadrilaterals (VIII-O.9)</li>

                        </ul>

                        <li><h6>8.G.i.2 Properties of parallelogram (By verification)</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Properties of parallelograms (VIII-O.6)Find missing angles in triangles and quadrilaterals (VIII-O.9)</li>
                            <li>Properties of rhombuses (VIII-O.7)</li>
                            <li>Properties of squares and rectangles (VIII-O.8)</li>

                        </ul>

                        <li><h6>8.G.i.2.i Opposite sides of a parallelogram are equal,</h6></li>
                        <li><h6>8.G.i.2.ii Opposite angles of a parallelogram are equal,</h6></li>
                        <li><h6>8.G.i.2.iii Diagonals of a parallelogram bisect each other. [Why (iv), (v) and (vi) follow from (ii)]</h6></li>
                        <li><h6>8.G.i.2.iv Diagonals of a rectangle are equal and bisect each other.</h6></li>
                        <li><h6>8.G.i.2.v Diagonals of a rhombus bisect each other at right angles.</h6></li>
                        <li><h6>8.G.i.2.vi Diagonals of a square are equal and bisect each other at right angles.</h6></li>



                        <li><h6>8.G.ii Representing 3-D in 2-D</h6></li>
                        <li><h6>8.G.ii.1 Identify and Match pictures with objects [more complicated e.g. nested, joint 2-D and 3-D shapes (not more than 2)].</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Parts of three-dimensional figures (VIII-S.1)</li>
                            <li>Base plans (VIII-S.4)</li>

                        </ul>

                        <li><h6>8.G.ii.2 Drawing 2-D representation of 3-D objects (Continued and extended)</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Nets of three-dimensional figures (VIII-S.2)</li>

                        </ul>

                        <li><h6>8.G.ii.3 Counting vertices, edges & faces & verifying Euler's relation for 3-D figures with flat faces (cubes, cuboids, tetrahedrons, prisms and pyramids)</h6></li>
                        <li><h6>8.G.iii Construction: Construction of Quadrilaterals:</h6></li>
                        <li><h6>8.G.iii.1 Given four sides and one diagonal</h6></li>
                        <li><h6>8.G.iii.2 Three sides and two diagonals</h6></li>
                        <li><h6>8.G.iii.3 Three sides and two included angles</h6></li>
                        <li><h6>8.G.iii.4 Two adjacent sides and three angles</h6></li>

                        <h3 style={{ color: "red" }}>8.MEN Mensuration</h3>
                        <li><h6>8.MEN.i Area of a trapezium and a polygon.</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Area (VIII-T.2)</li>
                            <li>Area between two shapes (VIII-T.3)</li>
                            <li>Area and perimeter: word problems (VIII-T.4)</li>
                            <li>Area of trapeziums (IX-J.4)</li>

                        </ul>

                        <li><h6>8.MEN.ii Concept of volume, measurement of volume using a basic unit, volume of a cube, cuboid and cylinder</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Area (VIII-T.2)</li>
                            <li>Volume of prisms and cylinders (VIII-T.7)</li>
                        </ul>


                        <li><h6>8.MEN.iii Volume and capacity (measurement of capacity)</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Convert square and cubic units of length (VIII-L.3)</li>
                            <li>Convert between cubic metres and litres (VIII-L.4)</li>
                        </ul>

                        <li><h6>8.MEN.iv Surface area of a cube, cuboid, cylinder.</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Surface area of prisms and cylinders (VIII-T.8)</li>
                        </ul>


                        <h3 style={{ color: "red" }}>8.DH Data handling</h3>
                        <li><h6>8.DH.i Reading bar-graphs, ungrouped data, arranging it into groups, representation of grouped data through bar-graphs, constructing and interpreting bar-graphs.</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Interpret bar graphs (VIII-Z.2)</li>
                            <li>Create bar graphs (VIII-Z.3)</li>
                            <li>Create frequency charts (VIII-Z.12)</li>

                        </ul>



                        <li><h6>8.DH.ii Simple Pie charts with reasonable data numbers</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Interpret pie charts (VIII-Z.13)</li>
                            <li>Pie charts and central angles (VIII-Z.14)</li>
                        </ul>




                        <li><h6>8.DH.iii Consolidating and generalising the notion of chance in events like tossing coins, dice etc. Relating it to chance in life events. Visual representation of frequency outcomes of repeated throws of the same kind of coins or dice. Throwing a large number of identical dice/coins together and aggregating the result of the throws to get large number of individual events. Observing the aggregating numbers over a large number of repeated events. Comparing with the data for a coin. Observing strings of throws, notion of randomness</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Probability of simple events (VIII-BB.1)</li>
                            <li>Probability of opposite, mutually exclusive and overlapping events (VIII-BB.2)</li>
                            <li>Experimental probability (VIII-BB.3)</li>
                            <li>Make predictions (VIII-BB.4)</li>
                            <li>Compound events: find the number of outcomes (VIII-BB.5)</li>
                            <li>Counting principle (VIII-BB.6)</li>
                        </ul>

                        <h3 style={{ color: "red" }}>8.IG Introduction to graphs</h3>
                        <li><h6>8.IG. Preliminaries:</h6></li>
                        <li><h6>8.IG.i Axes (Same units), Cartesian Plane</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Points on a coordinate plane (VIII-N.1)</li>
                            <li>Quadrants and axes (VIII-N.2)</li>
                            <li>Follow directions on a coordinate plane (VIII-N.3)</li>

                        </ul>

                        <li><h6>8.IG.ii Plotting points for different kind of situations (perimeter vs length for squares, area as a function of side of a square, plotting of multiples of different numbers, simple interest vs number of years etc.)</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Create line graphs (VIII-Z.5)</li>

                        </ul>

                        <li><h6>8.IG.iii Reading off from the graphs</h6></li>
                        <li><h6>8.IG.iii.1 Reading of linear graphs</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Identify proportional relationships by graphing (VIII-I.3)</li>
                            <li>Find the constant of proportionality from a graph (VIII-I.4)</li>
                            <li>Identify proportional relationships (VIII-I.6)</li>
                            <li>Graph proportional relationships (VIII-I.7)</li>

                        </ul>

                        <li><h6>8.IG.iii.2 Reading of distance vs time graph</h6></li>
                        <ul style={{ color: "green" }}>
                            <li>Rate of change (VIII-H.13)</li>
                            <li>Constant rate of change (VIII-H.14)</li>


                        </ul>



                    </ul>
                </ul>
            </div>

        </div>
    )
}

export default Class8









