import React from 'react'
import Header_ixl from '../Header_IXL/header_ixl'
import '../Class-1/class-1.css';

function Class12() {
    return (
        <div className='class_1'>
            <div className="container">

                <Header_ixl />

                <div className="main-content">
                    <div className="left-content">
                        <h3>India</h3>
                        <h4>Skills available for India class XII maths curriculum</h4>
                        <div className="description">
                            Objectives are in black and STEM WORLD maths skills are in dark green. Hold your mouse over the name of a skill to view a sample question. Click on the name of a skill to practise that skill.

                        </div>
                    </div>

                    <div className="right-content">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
                            alt="India Flag"
                            className="india-flag"
                        />
                    </div>
                </div>

                <div style={{ display: "flex", width: "100%", marginTop: "20px", padding: "15px" }}>
                    <div style={{ width: "75%", background: "#f65314", padding: "20px" }}>
                        <h6>Showing alignment for: CISCE Class XII</h6>
                        <p>National Council of Education Research and Training Syllabus :Class XII</p>
                    </div>
                    <div style={{ width: "25%", background: "gray", padding: "20px" }}>
                        <h6>Actions</h6>
                        <p>Print curriculum</p>
                    </div>
                </div>
            </div>

            <div style={{ marginLeft: "110px" }}>
                <h3 style={{ color: "red" }}>12.I Relations and Functions</h3>
                <ul style={{ listStyle: "none" }}>
                    <li><h6>12.I.1 Relations and Functions</h6>
                        <ul style={{ listStyle: "none" }}>
                            <li><h6>12.I.1.1 Types of relations: Reflexive, symmetric, transitive and equivalence relations. One to one and onto functions, composite functions, inverse of a function. Binary operations.</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Domain and range (XII-A.1)</li>
                                    <li>Identify functions (XII-A.2)</li>
                                    <li>Find the slope of a linear function (XII-A.3)</li>
                                    <li>Graph a linear function (XII-A.4)</li>
                                    <li>Write the equation of a linear function (XII-A.5)</li>
                                    <li>Evaluate functions (XII-A.6)</li>
                                    <li>Find values using function graphs (XII-A.7)</li>
                                    <li>Complete a table for a function graph (XII-A.8)</li>
                                    <li>Add, subtract, multiply and divide functions (XII-A.9)</li>
                                    <li>Composition of functions (XII-A.10)</li>
                                    <li>Identify inverse functions (XII-A.11)</li>
                                    <li>Find values of inverse functions from tables (XII-A.12)</li>
                                    <li>Find values of inverse functions from graphs (XII-A.13)</li>
                                    <li>Find inverse functions and relations (XII-A.14)</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li><h6>12.I.2 Inverse Trigonometric Functions</h6>

                        <ul style={{ listStyle: "none" }}>
                            <li><h6>12.I.2.1 Definition, range, domain, principal value branches. Graphs of inverse trigonometric functions. Elementary properties of inverse trigonometric functions.</h6></li>
                            <li>Inverses of trigonometric functions (XII-G.8)</li>

                            <h3 style={{ color: "red" }}>12.II Algebra</h3>

                            <h6>12.II.1 Matrices</h6>
                            <li><h6>12.II.1.1 Concept, notation, order, equality, types of matrices, zero matrix, transpose of a matrix, symmetric and skew symmetric matrices. Addition, multiplication and scalar multiplication of matrices, simple properties of addition, multiplication and scalar multiplication. Noncommutativity of multiplication of matrices and existence of non-zero matrices whose product is the zero matrix (restrict to square matrices of order 2). Concept of elementary row and column operations. Invertible matrices and proof of the uniqueness of inverse, if it exists; (Here all matrices will have real entries).</h6>

                                <ul style={{ color: "green" }}>
                                    <li>Matrix vocabulary (XII-F.1)</li>
                                    <li>Matrix operation rules (XII-F.2)</li>
                                    <li>Add and subtract matrices (XII-F.3)</li>
                                    <li>Multiply a matrix by a scalar (XII-F.4)</li>
                                    <li>Linear combinations of matrices (XII-F.5)</li>
                                    <li>Multiply two matrices (XII-F.6)</li>
                                    <li>Simplify matrix expressions (XII-F.7)</li>
                                    <li>Solve matrix equations (XII-F.8)</li>
                                </ul>
                            </li>

                            <h6>12.II.2 Determinants</h6>
                            <li><h6>12.II.2.1 Determinant of a square matrix (up to 3 × 3 matrices), properties of determinants, minors, cofactors and applications of determinants in finding the area of a triangle. Adjoint and inverse of a square matrix. Consistency, inconsistency and number of solutions of system of linear equations by examples, solving system of linear equations in two or three variables (having unique solution) using inverse of a matrix..</h6>

                                <ul style={{ color: "green" }}>
                                    <li>Determinant of a matrix (XII-F.9)</li>
                                    <li>Is a matrix invertible? (XII-F.10)</li>
                                    <li>Inverse of a 2 x 2 matrix (XII-F.11)</li>
                                    <li>Inverse of a 3 x 3 matrix (XII-F.12)</li>
                                    <li>Identify inverse matrices (XII-F.13)</li>
                                    <li>Solve matrix equations using inverses (XII-F.14)</li>

                                </ul>
                            </li>
                            <h3 style={{ color: "red" }}>12.III Calculus</h3>

                            <h6>12.III.1 Continuity and Differentiability</h6>
                            <li><h6>12.III.1.1 Continuity and differentiability, derivative of composite functions, chain rule, derivatives of inverse trigonometric functions, derivative of implicit function. Concept of exponential and logarithmic functions and their derivatives. Logarithmic differentiation. Derivative of functions expressed in parametric forms. Second order derivatives. Rolle's and Lagrange's Mean Value Theorems (without proof) and their geometric interpretations.</h6>

                                <ul style={{ color: "green" }}>
                                    <li>Identify graphs of continuous functions (XII-Q.1)</li>
                                    <li>Determine continuity using graphs (XII-Q.2)</li>
                                    <li>Determine one-sided continuity using graphs (XII-Q.3)</li>
                                    <li>Find and analyse points of discontinuity using graphs (XII-Q.4)</li>
                                    <li>Determine continuity on an interval using graphs (XII-Q.5)</li>
                                    <li>Determine the continuity of a piecewise function at a point (XII-Q.6)</li>
                                    <li>Make a piecewise function continuous (XII-Q.7)</li>
                                    <li>Intermediate Value Theorem (XII-Q.8)</li>
                                    <li>Chain rule (XII-S.6)</li>
                                    <li>Find derivatives of exponential functions (XII-T.5)</li>
                                    <li>Find derivatives of logarithmic functions (XII-T.6)</li>
                                    <li>Find derivatives of inverse trigonometric functions (XII-T.7)</li>
                                    <li>Find derivatives using the chain rule I (XII-T.13)</li>
                                    <li>Find derivatives using the chain rule II (XII-T.14)</li>
                                    <li>Find derivatives using implicit differentiation (XII-U.1)</li>
                                    <li>Find derivatives using logarithmic differentiation (XII-U.3)</li>
                                    <li>Find second derivatives of trigonometric, exponential and logarithmic functions (XII-V.3)</li>
                                </ul>
                            </li>

                            <div>
                                <h6>12.III.2 Applications of Derivatives</h6>

                                <li><h6>12.III.2.1 Applications of derivatives: Rate of change, increasing/decreasing functions, tangents and normals, approximation, maxima and minima (first derivative test motivated geometrically and second derivative test given as a provable tool). Simple problems (that illustrate basic principles and understanding of the subject as well as real-life situations).</h6>

                                    <ul style={{ color: "green" }}>
                                        <li>Average rate of change I (XII-R.1)</li>
                                        <li>Average rate of change I (XII-R.1)</li>
                                        <li>Find instantaneous rates of change (XII-R.3)</li>
                                        <li>Velocity as a rate of change (XII-R.4)</li>
                                        <li>Find the slope of a tangent line using limits (XII-R.6)</li>
                                        <li>Find equations of tangent lines using limits (XII-R.7)</li>

                                    </ul>
                                </li>
                            </div>


                            <h6>12.III.3 Integrals</h6>
                            <li><h6>12.III.3.1 Integration as inverse process of differentiation. Integration of a variety of functions by substitution, by partial fractions and by parts, only simple integrals of the type Γdx/(x² ± a²), Γdx/(√x² ± a²), Γdx/(√a² + bx + c), Γdx/(√ax² + bx + c), Γ(px + q)/(ax² + bx + c) dx, Γ(px + q)/(√ax² + bx + c) dx, Γ(√a² ± x²) dx and Γ(√x² - a²) dx, Γ√(ax² + bx + c)dx and Γ(px + q)√(ax² + bx + c)dx to be evaluated..</h6></li>
                            <li><h6>12.III.3.2 Definite integrals as a limit of a sum. Fundamental Theorem of Calculus (without proof). Basic properties of definite integrals and evaluation of definite integrals.</h6></li>

                            <h6>12.III.4 Applications of the Integrals</h6>
                            <li><h6>12.III.4.1 Applications in finding the area under simple curves, especially lines, arcs of circles/parabolas/ ellipses (in standard form only), area between the two above said curves (the region should be clearly identifiable).</h6></li>


                            <h6>12.III.5 Differential Equations</h6>
                            <li><h6>12.III.5.1 Definition, order and degree, general and particular solutions of a differential equation. Formation of differential equation whose general solution is given. Solution of differential equations by method of separation of variables, homogeneous differential equations of first order and first degree. Solutions of linear differential equation of the type: dy/dx + Py = Q, where P and Q are functions of x or constant, dx/dy + Px = Q, where P and Q are functions of y or constant..</h6></li>




                            <h3 style={{ color: "red" }}>12.IV Vectors and Three-Dimensional Geometry</h3>

                            <h6>12.IV.1 Vectors</h6>
                            <li><h6>12.IV.1.1 Vectors and scalars, magnitude and direction of a vector. Direction cosines/ratios of vectors. Types of vectors (equal, unit, zero, parallel and collinear vectors), position vector of a point, negative of a vector, components of a vector, addition of vectors, multiplication of a vector by a scalar, position vector of a point dividing a line segment in a given ratio. Scalar (dot) product of vectors, projection of a vector on a line. Vector (cross) product of vectors.</h6>

                                <ul style={{ color: "green" }}>
                                    <li>Find the magnitude of a vector (XII-N.1)</li>
                                    <li>Find the direction angle of a vector (XII-N.2)</li>
                                    <li>Find the component form of a vector (XII-N.3)</li>
                                    <li>Find the component form of a vector from its magnitude and direction angle (XII-N.4)</li>
                                    <li>Find a unit vector (XII-N.5)</li>
                                    <li>Add and subtract vectors (XII-N.6)</li>
                                    <li>Multiply a vector by a scalar (XII-N.7)</li>
                                    <li>Find the magnitude or direction of a vector scalar multiple (XII-N.8)</li>
                                    <li>Find the magnitude and direction of a vector sum (XII-N.9)</li>
                                    <li>Linear combinations of vectors (XII-N.10)</li>
                                    <li>Graph a resultant vector using the triangle method (XII-N.11)</li>
                                    <li>Graph a resultant vector using the parallelogram method (XII-N.12)</li>
                                    <li>Find the magnitude of a three-dimensional vector (XII-O.1)</li>
                                    <li>Find the component form of a three-dimensional vector (XII-O.2)</li>
                                    <li>Find a three-dimensional unit vector (XII-O.3)</li>
                                    <li>Add and subtract three-dimensional vectors (XII-O.4)</li>
                                    <li>Scalar multiples of three-dimensional vectors (XII-O.5)</li>
                                    <li>Linear combinations of three-dimensional vectors (XII-O.6)</li>
                                </ul>
                            </li>



                            <h6>12.IV.2 Three-dimensional Geometry</h6>
                            <li><h6>12.IV.2.1 Direction cosines/ratios of a line joining two points. Cartesian and vector equation of a line, coplanar and skew lines, shortest distance between two lines. Cartesian and vector equation of a plane. Angle between (i) two lines, (ii) two planes, (iii) a line and a plane. Distance of a point from a plane.</h6></li>




                            <h3 style={{ color: "red" }}>12.V Linear Programming</h3>

                            <li><h6>12.V.1 Introduction, related terminology such as constraints, objective function, optimization, different types of linear programming (L.P.) problems, mathematical formulation of L.P. problems, graphical method of solution for problems in two variables, feasible and infeasible regions, feasible and infeasible solutions, optimal feasible solutions (up to three non-trivial constraints).</h6>

                                <ul>
                                    <li>Linear programming (XII-D.4)</li>

                                </ul>
                            </li>

                            <h3 style={{ color: "red" }}>12.VI Probability</h3>

                            <li><h6>12.VI.1 Multiplication theorem on probability. Conditional probability, independent events, total probability, Bayes' theorem. Random variable and its probability distribution, mean and variance of haphazard variable. Repeated independent (Bernoulli) trials and Binomial distribution.</h6>

                                <ul style={{ color: "green" }}>
                                    <li>Introduction to probability (XII-W.1)</li>
                                    <li>Calculate probabilities of events (XII-W.2)</li>
                                    <li>Combinations and permutations (XII-W.3)</li>
                                    <li>Find probabilities using combinations and permutations (XII-W.4)</li>
                                    <li>Find probabilities using two-way frequency tables (XII-W.5)</li>
                                    <li>Identify independent events (XII-W.6)</li>
                                    <li>Find conditional probabilities (XII-W.7)</li>

                                    <li>Independence and conditional probability (XII-W.8)</li>
                                    <li>Find conditional probabilities using two-way frequency tables (XII-W.9)</li>
                                    <li>Find probabilities using the addition rule (XII-W.10)</li>
                                    <li>Identify discrete and continuous random variables (XII-X.1)</li>
                                    <li>Write a discrete probability distribution (XII-X.2)</li>
                                    <li>Graph a discrete probability distribution (XII-X.3)</li>
                                    <li>Expected values of random variables (XII-X.4)</li>
                                    <li>Variance of random variables (XII-X.5)</li>
                                    <li>Write the probability distribution for a game of chance (XII-X.7)</li>
                                    <li>Expected values for a game of chance (XII-X.8)</li>
                                    <li>Choose the better bet (XII-X.9)</li>
                                    <li>Find probabilities using the binomial distribution (XII-X.10)</li>
                                    <li>Mean, variance and standard deviation of binomial distributions (XII-X.11)</li>
                                    <li>Find probabilities using the normal distribution I (XII-X.12)</li>
                                    <li>Use normal distributions to approximate binomial distributions (XII-X.18)</li>
                                </ul>
                            </li>

                        </ul>
                    </li>
                </ul>
            </div>

        </div>
    )
}

export default Class12









