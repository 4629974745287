
import React from 'react';
//  import './class.css';

const Classvi = () => {
    const categories = [
        {
            title: "Whole numbers",
            items: [
                { label: "A.1", description: "Place values in whole numbers" },
                { label: "A.2", description: "Word names for numbers" },
                { label: "A.3", description: "Roman numerals" },
                { label: "A.4", description: "Add and subtract whole numbers" },
                { label: "A.5", description: "Add and subtract whole numbers: word " }
            ]
        },
        {
            title: "Integers",
            items: [
                { label: "I.1", description: "Understanding integers" },
                { label: "I.2", description: "Integers on number lines" },
                { label: "I.3", description: "Graph integers on horizontal and vertical number " },
                { label: "I.4", description: "Compare and order integers" }
            ]
        },
        {
            title: "Expressions and Properties",
            items: [
                { label: "Q.1", description: "Write variable expressions" },
                { label: "Q.2", description: "Evaluate variable expressions" },
                { label: "Q.3", description: "Properties of addition and multiplication" },
                { label: "Q.4", description: "Multiply using the distributive property" },
                { label: "Q.5", description: "Write equivalent expressions using properties" },
                { label: "Q.6", description: "Properties of addition" },
                { label: "Q.7", description: "Properties of multiplication" },
                { label: "Q.8", description: "Solve for a variable using properties of " },
                { label: "Q.9", description: "Identify equivalent expressions" }
            ]
        },
        {
            title: "Multiplication",
            items: [
                { label: "B.1", description: "Multiply whole numbers" },
                { label: "B.2", description: "Multiply whole numbers: word problems" },
                { label: "B.3", description: "Multiply numbers ending in zeroes" },
                { label: "B.4", description: "Multiply numbers ending in zeroes: word " },
                { label: "B.5", description: "Multiply three or more numbers" },
                { label: "B.6", description: "Multiply three or more numbers: word problems" },
                { label: "B.7", description: "Estimate products" }
            ]
        },
        {
            title: "Operations with Integers",
            items: [
                { label: "J.1", description: "Add integers using counters" },
                { label: "J.2", description: "Add integers" },
                { label: "J.3", description: "Subtract integers using counters" },
                { label: "J.4", description: "Subtract integers" },
                { label: "J.5", description: "Add and subtract integers: find the sign" },
                { label: "J.6", description: "Add and subtract integers: input/output tables" },
                { label: "J.7", description: "Add three or more integers" }
            ]
        },
        {
            title: "One-variable equations",
            items: [
                { label: "R.1", description: "Write variable equations: word problems" },
                { label: "R.2", description: "Solve equations using properties" },
                { label: "R.3", description: "Solve equations" }
            ]
        },
        {
            title: "One-variable equations",
            items: [
                { label: "R.1", description: "Write variable equations: word problems" },
                { label: "R.2", description: "Solve equations using properties" },
                { label: "R.3", description: "Solve equations" }
            ]
        },
        {
            title: "Division",
            items: [
                { label: "C.1", description: "Divisibility rules" },
                { label: "C.2", description: "Division patterns with zeroes" },
                { label: "C.3", description: "Divide numbers ending in zeroes: word problems" },
                { label: "C.4", description: "Estimate quotients" },
                { label: "C.5", description: "Divide whole numbers - two-digit divisors" }
            ]
        },
        {
            title: "Measurement",
            items: [
                { label: "J.1", description: "Coin values" },
                { label: "J.2", description: "Count 1-rupee coins" }
            ]
        },
        {
            title: "Mixed Operations",
            items: [
                { label: "K.1", description: "Add, subtract, multiply or divide two whole " },
                { label: "K.2", description: "Add, subtract, multiply or divide two whole " },
                { label: "K.3", description: "Evaluate numerical expressions" },
                { label: "K.4", description: "Add and subtract decimals or fractions" },
                { label: "K.5", description: "Add and subtract decimals or fractions: word " },
                { label: "K.6", description: "Add, subtract, multiply or divide two integers" }
            ]
        },
        {
            title: "Problem Solving and Estimation",
            items: [
                { label: "L.1", description: "Estimate to solve word problems" },
                { label: "L.2", description: "Multi-step word problems" },
                { label: "L.3", description: "Word problems with extra or missing information" },
                { label: "L.4", description: "Guess-and-check word problems" },
                { label: "L.5", description: "Distance/direction to starting point" },
                { label: "L.6", description: "Use logical reasoning to find the order" }
            ]
        },
        {
            title: "Two-dimensional Figures",
            items: [
                { label: "S.1", description: "Is it a polygon?" },
                { label: "S.2", description: "Types of angles" },
                { label: "S.3", description: "Measure angles with a protractor" },
                { label: "S.4", description: "Regular and irregular polygons" },
                { label: "S.5", description: "Number of sides in polygons" },
                { label: "S.6", description: "Classify triangles" },
                { label: "S.7", description: "Identify trapeziums" },
                { label: "S.8", description: "Classify quadrilaterals" },
                { label: "S.9", description: "Graph triangles and quadrilaterals" },
                { label: "S.10", description: "Find the unknown angle in triangles and " },
                { label: "S.11", description: "Lines, line segments and rays" },
                { label: "S.12", description: "Parallel, perpendicular and intersecting lines" },
                { label: "S.13", description: "Parts of a circle" }
            ]
        },
        {
            title: "Symmetry and Transformations",
            items: [
                { label: "T.1", description: "Lines of symmetry" },
                { label: "T.2", description: "Rotational symmetry" },
                { label: "T.3", description: "Reflection, rotation and translation" },
                { label: "T.4", description: "Identify congruent and similar figures" }
            ]
        },
        {
            title: "Add and Subtract Decimals",
            items: [
                { label: "F.1", description: "Add and subtract decimal numbers" },
                { label: "F.2", description: "Add and subtract decimals: word problems" },
                { label: "F.3", description: "Estimate sums and differences of decimals" },
                { label: "F.4", description: "Maps with decimal distances" }
            ]
        },
        {
            title: "Fractions and Mixed Numbers",
            items: [
                { label: "G.1", description: "Fractions and mixed numbers" },
                { label: "G.2", description: "Understanding fractions: word problems" },
                { label: "G.3", description: "Equivalent fractions" },
                { label: "G.4", description: "Write fractions in lowest terms" },
                { label: "G.5", description: "Fractions: word problems" },
                { label: "G.6", description: "Lowest common denominator" },
                { label: "G.7", description: "Compare fractions with like and unlike " },
                { label: "G.8", description: "Compare fractions: word problems" },
                { label: "G.9", description: "Convert between improper fractions and mixed " },
                { label: "G.10", description: "Convert between decimals and fractions or  " },
                { label: "G.11", description: "Put a mix of decimals, fractions and mixed   " }
            ]
        },
        {
            title: "Add and Subtract Fractions",
            items: [
                { label: "H.1", description: "Add and subtract fractions with like " },
                { label: "H.2", description: "Add and subtract fractions with like :  " },
                { label: "H.3", description: "Add and subtract fractions with unlike " },
                { label: "H.4", description: "Add and subtract fractions with unlike :  " },
                { label: "H.5", description: "Inequalities with addition and  of like and  " },
                { label: "H.6", description: "Add and subtract mixed numbers" },
                { label: "H.7", description: "Add and subtract mixed numbers: word " },
                { label: "H.8", description: "Estimate sums and differences of mixed " }
            ]
        },
        {
            title: "Units of Measurement",
            items: [
                { label: "N.1", description: "Estimate metric measurements" },
                { label: "N.2", description: "Convert and compare metric units" },
                { label: "N.3", description: "Metric mixed units" },
                { label: "N.4", description: "Convert square and cubic units of length" },
                { label: "N.5", description: "Convert between cubic metres and litres" },
                { label: "N.6", description: "Compare temperatures above and below zero" }
            ]
        },
        {
            title: "Money",
            items: [
                { label: "O.1", description: "Find the number of each type of coin" },
                { label: "O.2", description: "Add and subtract money amounts" },
                { label: "O.3", description: "Add and subtract money amounts: word " },
                { label: "O.4", description: "Multiply money: word problems" },
                { label: "O.5", description: "Divide money amounts" },
                { label: "O.6", description: "Divide money amounts: word problems" }
            ]
        },
        {
            title: "Time",
            items: [
                { label: "P.1", description: "Elapsed time" },
                { label: "P.2", description: "Time units" },
                { label: "P.3", description: "Find start and end times" },
                { label: "P.4", description: "Convert between 12-hour and 24-hour time" },
                { label: "P.5", description: "Transportation schedules" }
            ]
        },
        {
            title: "Geometric Measurement",
            items: [
                { label: "W.1", description: "Perimeter" },
                { label: "W.2", description: "Area of squares and rectangles" },
                { label: "W.3", description: "Area of compound figures" },
                { label: "W.4", description: "Area between two rectangles" },
                { label: "W.5", description: "Area and perimeter of figures on grids" },
                { label: "W.6", description: "Area and perimeter: word problems" },
                { label: "W.7", description: "Use area and perimeter to determine cost" },
                { label: "W.8", description: "Volume of figures made of unit cubes" }
            ]
        },
        {
            title: "Data and Graphs",
            items: [
                { label: "X.1", description: "Interpret pictographs" },
                { label: "X.2", description: "Create pictographs" },
                { label: "X.3", description: "Interpret stem-and-leaf plots" },
                { label: "X.4", description: "Create stem-and-leaf plots" },
                { label: "X.5", description: "Interpret line plots" },
                { label: "X.6", description: "Create line plots" },
                { label: "X.7", description: "Create frequency tables" },
                { label: "X.8", description: "Interpret bar graphs" },
                { label: "X.9", description: "Create bar graphs" },
                { label: "X.10", description: "Interpret histograms" },
                { label: "X.11", description: "Create histograms" },
                { label: "X.12", description: "Interpret line graphs" },
                { label: "X.13", description: "Create line graphs" }
            ]
        },
        {
            title: "Probability",
            items: [
                { label: "Y.1", description: "Combinations" },
                { label: "Y.2", description: "Make predictions" },
                { label: "Y.3", description: "Compound events - find the number of " }
            ]
        }
    ];

    const menuItems = [
        { label: 'L', text: 'Lower kindergarten' },
        { label: 'U', text: 'Upper kindergarten' },
        { label: 'I', text: 'Level I' },
        { label: 'II', text: 'Level II' },
        { label: 'III', text: 'Level III' },
        { label: 'IV', text: 'Level IV' },
        { label: 'V', text: 'Level V' },
        { label: 'VI', text: 'Level VI' },
        { label: 'VII', text: 'Level VII' },
        { label: 'VIII', text: 'Level VIII' },
    ];

    return (
        <div className="maths-container">
            <aside className="menu">
                {menuItems.map((item, index) => (
                    <div
                        key={index}
                        className={`menu-item menu-item-${item.label}`}
                        data-text={item.text}
                    >
                        {item.label}
                    </div>
                ))}
            </aside>
            <div className="content-maths">
                {/* ... (keep the rest of the content as is) */}

                <div className="category intro">
                    <h3>Class-vI maths</h3>
                    <p>Here is a list of all of the maths skills students learn in UKG! These skills are organised into categories, and you can move your mouse over any skill name to preview the skill. To start practising, just click on any link. STEM WORLD will track your score, and the questions will automatically increase in difficulty as you improve!</p>
                </div>
                <div className="category-list">
                    {categories.map((category, index) => (
                        <div key={index} className="category">
                            <h3>{category.title}</h3>
                            <ul>
                                {category.items.map((item, i) => (
                                    <li key={i}>
                                        <span className="item-label">{item.label}</span>
                                        <span className="item-description">{item.description}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Classvi;