
import React from 'react';
import './KindergartenMaths.css';

const LowerKindergartenMaths = () => {
    const categories = [
        {
            title: "Shapes",
            items: [
                { label: "A.1", description: "Name the shape" },
                { label: "A.2", description: "Circles" },
                { label: "A.3", description: "Squares" },
                { label: "A.4", description: "Triangles" },
                { label: "A.5", description: "Rectangles" },
                { label: "A.6", description: "Circles, squares and triangles" }
            ]
        },
        {
            title: "Count to 3",
            items: [
                { label: "B.1", description: "Learn to count - up to 3" },
                { label: "B.2", description: "Count objects - up to 3" },
                { label: "B.3", description: "Count dots - up to 3" },
                { label: "B.4", description: "Count shapes - up to 3" },
                { label: "B.5", description: "Count on ten frames - up to 3" },
                { label: "B.6", description: "Show numbers on ten frames - up to 3" },
                { label: "B.7", description: "Represent numbers - up to 3" }
            ]
        },
        {
            title: "Count to 10",
            items: [
                { label: "D.1", description: "Learn to count - up to 10" },
                { label: "D.2", description: "Count objects - up to 10" },
                { label: "D.3", description: "Count dots - up to 10" },
                { label: "D.4", description: "Count shapes - up to 10" },
                { label: "D.5", description: "Count on ten frames - up to 10" },
                { label: "D.6", description: "Show numbers on ten frames - up to 10" },
                { label: "D.7", description: "Represent numbers - up to 10" }
            ]
        },
        {
            title: "Classify",
            items: [
                { label: "G.1", description: "Same" },
                { label: "G.2", description: "Different" },
                { label: "G.3", description: "Same and different" },
                { label: "G.4", description: "Classify shapes by colour" },
                { label: "G.5", description: "Classify and sort by colour" },
                { label: "G.6", description: "Classify and sort by shape" }
            ]
        },
        {
            title: "Comparing",
            items: [
                { label: "E.1", description: "Are there enough?" },
                { label: "E.2", description: "More" },
                { label: "E.3", description: "Fewer" },
                { label: "E.4", description: "Fewer and more - compare by counting" },
                { label: "E.5", description: "Compare in a mixed group" }
            ]
        },
        {
            title: "Patterns",
            items: [
                { label: "H.1", description: "Colour patterns" },
                { label: "H.2", description: "Size patterns" },
                { label: "H.3", description: "Shape patterns" },
                { label: "H.4", description: "What comes next?" }
            ]
        },
        {
            title: "Positions",
            items: [
                { label: "F.1", description: "Inside and outside" },
                { label: "F.2", description: "Above and below" },
                { label: "F.3", description: "Beside and next to" },
                { label: "F.4", description: "Left and right" },
                { label: "F.5", description: "Left, middle and right" },
                { label: "F.6", description: "Top and bottom" },
                { label: "F.7", description: "Top, middle and bottom" }
            ]
        },
        {
            title: "Size",
            items: [
                { label: "I.1", description: "Long and short" },
                { label: "I.2", description: "Tall and short" },
                { label: "I.3", description: "Wide and narrow" },
                { label: "I.4", description: "Light and heavy" }
            ]
        },
        {
            title: "Money",
            items: [
                { label: "J.1", description: "Coin values" },
                { label: "J.2", description: "Count 1-rupee coins" }
            ]
        }
    ];

    const menuItems = [
        { label: 'L', text: 'Lower kindergarten' },
        { label: 'U', text: 'Upper kindergarten' },
        { label: 'I', text: 'Level I' },
        { label: 'II', text: 'Level II' },
        { label: 'III', text: 'Level III' },
        { label: 'IV', text: 'Level IV' },
        { label: 'V', text: 'Level V' },
        { label: 'VI', text: 'Level VI' },
        { label: 'VII', text: 'Level VII' },
        { label: 'VIII', text: 'Level VIII' },
    ];

    return (
        <div className="maths-container">
            <aside className="menu">
                {menuItems.map((item, index) => (
                    <div
                        key={index}
                        className={`menu-item menu-item-${item.label}`}
                        data-text={item.text}
                    >
                        {item.label}
                    </div>
                ))}
            </aside>
            <div className="content-maths">
                {/* ... (keep the rest of the content as is) */}

                <div className="category intro">
                    <h3>Lower Kindergarten Maths</h3>
                    <p>Here is a list of all of the maths skills students learn in LKG! These skills are organised into categories, and you can move your mouse over any skill name to preview the skill. To start practising, just click on any link. STEM WORLD will track your score, and the questions will automatically increase in difficulty as you improve!</p>
                </div>
                <div className="category-list">
                    {categories.map((category, index) => (
                        <div key={index} className="category">
                            <h3>{category.title}</h3>
                            <ul>
                                {category.items.map((item, i) => (
                                    <li key={i}>
                                        <span className="item-label">{item.label}</span>
                                        <span className="item-description">{item.description}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LowerKindergartenMaths;