import React from 'react';
import { Link } from 'react-router-dom';
import './CategoryCard.css'; // Import your CSS file

const CategoryCard = ({ title, description, skillsCount, color }) => {
  const getPath = (skillsCount) => {
    const paths = {
      403: '/LowerKindergartenMaths',
      144: '/UpperKindergartenMaths',
      164: '/UpperKindergartenMaths',
      207: '/UpperKindergartenMaths',
      // Add more mappings as needed
    };

    return paths[skillsCount] || `/${skillsCount}`; // Fallback path if no specific mapping matches
  };

  const path = getPath(skillsCount);

  return (
    <div className="category-card" style={{ borderLeftColor: color }}>
      <div className="category-content">
        <h2 className="category-title" style={{ color }}>{title}</h2>
        <p className="category-description">{description}</p>
      </div>
      <Link to={path} className="see-all-skills" style={{ backgroundColor: color }}>
        See all {skillsCount} skills ›
      </Link>
    </div>
  );
};

export default CategoryCard;
