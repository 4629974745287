import React from 'react'
import Header_ixl from '../Header_IXL/header_ixl'
// import './class-2.css';

function Class6() {
    return (
        <div>

            <div className="container">

                <Header_ixl />
                <div className="main-content">
                    <div className="left-content">
                        <h3>India</h3>
                        <h4>Skills available for India class VI maths curriculum</h4>
                        <div className="description">
                            Objectives are in black and STEM WORLD maths skills are in dark green. Hold your mouse over the name of a skill to view a sample question. Click on the name of a skill to practise that skill.</div>
                    </div>

                    <div className="right-content">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
                            alt="India Flag"
                            className="india-flag"
                        />
                    </div>
                </div>

                <div style={{ display: "flex", width: "100%", marginTop: "20px", padding: "15px" }}>
                    <div style={{ width: "75%", background: "#f65314", padding: "20px" }}>
                        <h6>Showing alignments for:</h6>
                        <p>National Council of Education Research and Training Syllabus</p>
                    </div>
                    <div style={{ width: "25%", background: "gray", padding: "20px" }}>
                        <h6>Actions</h6>
                        <p>Print curriculum</p>
                    </div>
                </div>
            </div>

            <div style={{ marginLeft: "110px" }}>
                <h3 style={{ color: "red" }}>6.NS Number System</h3>
                <ul style={{ listStyle: "none" }}>
                    <li><h6>6.NS.i Knowing our Numbers</h6>
                        <ul style={{ listStyle: "none" }}>
                            <li><h6>6.NS.i.1 Consolidating the sense of numberness up to 5 digits, Size, estimation of numbers, identifying smaller, larger, etc. Place value (recapitulation and extension), connectives: use of symbols  and use of brackets, word problems on number operations involving large numbers up to a maximum of 5 digits in the answer after all operations. This would include conversions of units of length & mass (from the larger to the smaller units), estimation of outcome of number operations. Introduction to a sense of the largeness of, and initial familiarity with, large numbers up to 8 digits and approximation of large numbers.</h6>
                                <ul style={{ color: "green" }}>
                                    <li>Place values in whole numbers (VI-A.1)</li>
                                    <li>Word names for numbers (VI-A.2)</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <h6>6.NS.ii Playing with Numbers</h6>
                    <li><h6>6.NS.ii.1 Simplification of brackets, Multiples and factors, divisibility rule of 2, 3, 4, 5, 6, 8, 9, 10, 11. (All these through observing patterns. Children would be helped in deducing some and then asked to derive some that are a combination of the basic patterns of divisibility.) Even/odd and prime/composite numbers, Co-prime numbers, prime factorisation, every number can be written as products of prime factors. HCF and LCM, prime factorization and division method for HCF and LCM, the property LCM × HCF = product of two numbers. All this is to be embedded in contexts that bring out the significance and provide motivation to the child for learning these ideas.</h6>
                        <ul style={{ color: "green" }}>
                            <li>Divisibility rules (VI-C.1)</li>
                            <li>Division patterns with zeroes (VI-C.2)</li>
                            <li>Divide numbers ending in zeroes: word problems (VI-C.3)</li>
                            <li>Estimate quotients (VI-C.4)</li>
                            <li>Prime or composite (VI-D.1)</li>
                            <li>Identify factors (VI-D.2)</li>
                            <li>Prime factorisation (VI-D.3)</li>
                            <li>Prime factorisation with exponents (VI-D.4)</li>
                            <li>Highest common factor (VI-D.5)</li>
                            <li>Lowest common multiple (VI-D.6)</li>
                            <li>HCF and LCM: word problems (VI-D.7)</li>
                        </ul>
                    </li>

                    <h6>6.NS.iii Whole numbers</h6>
                    <li><h6>6.NS.iii.1 Natural numbers, whole numbers, properties of numbers (commutative, associative, distributive, additive identity, multiplicative identity), number line. Seeing patterns, identifying and formulating rules to be done by children. (As familiarity with algebra grows, the child can express the generic pattern.).</h6>

                        <ul style={{ color: "green" }}>
                            <li>Properties of addition and multiplication (VI-Q.3)</li>
                            <li>Multiply using the distributive property (VI-Q.4)</li>
                            <li>Properties of addition (VI-Q.6)</li>
                            <li>Properties of multiplication (VI-Q.7)</li>

                        </ul>
                    </li>

                    <h6>6.NS.iv Negative Numbers and Integers</h6>
                    <li><h6>6.NS.iv.1 How negative numbers arise, models of negative numbers, connection to daily life, ordering of negative numbers, representation of negative numbers on number line. Children to see patterns, identify and formulate rules. What are integers, identification of integers on the number line, operation of addition and subtraction of integers, showing the operations on the number line (addition of negative integer reduces the value of the number) comparison of integers, ordering of integers..</h6>

                        <ul style={{ color: "green" }}>
                            <li>Understanding integers (VI-I.1)</li>
                            <li>Integers on number lines (VI-I.2)</li>
                            <li>Compare and order integers (VI-I.4)</li>
                            <li>Add integers using counters (VI-J.1)</li>
                            <li>Add integers (VI-J.2)</li>
                            <li>Subtract integers using counters (VI-J.3)</li>
                            <li>Subtract integers (VI-J.4)</li>
                            <li>Add and subtract integers: find the sign (VI-J.5)</li>
                            <li>Add and subtract integers: input/output tables (VI-J.6)</li>
                            <li>Add three or more integers (VI-J.7)</li>
                        </ul>
                    </li>

                    <div>
                        <h6>6.NS.v Fractions</h6>
                        <li><h6>6.NS.v.1 Revision of what a fraction is, Fraction as a part of whole, Representation of fractions (pictorially and on number line), fraction as a division, proper, improper & mixed fractions, equivalent fractions, comparison of fractions, addition and subtraction of fractions (Avoid large and complicated unnecessary tasks). (Moving towards abstraction in fractions). Review of the idea of a decimal fraction, place value in the context of decimal fraction, inter conversion of fractions and decimal fractions (avoid recurring decimals at this stage), word problems involving addition and subtraction of decimals (two operations together on money, mass, length and temperature)</h6>

                            <ul style={{ color: "green" }}>
                                <li>Fractions of a whole: modelling word problems (V-F.4)</li>
                                <li>What decimal number is illustrated? (VI-E.1)</li>
                                <li>Decimal place values (VI-E.2)</li>
                                <li>Word names for decimal numbers (VI-E.3)</li>
                                <li>Convert decimals to mixed numbers (VI-E.4)</li>
                                <li>Decimal number lines (VI-E.9)</li>
                                <li>Add and subtract decimal numbers (VI-F.1)</li>
                                <li>Add and subtract decimals: word problems (VI-F.2)</li>
                                <li>Estimate sums and differences of decimals (VI-F.3)</li>
                                <li>Maps with decimal distances (VI-F.4)</li>
                                <li>Fractions and mixed numbers (VI-G.1)</li>
                                <li>Understanding fractions: word problems (VI-G.2)</li>
                                <li>Equivalent fractions (VI-G.3)</li>
                                <li>Write fractions in lowest terms (VI-G.4)</li>
                                <li>Fractions: word problems (VI-G.5)</li>
                                <li>Lowest common denominator (VI-G.6)</li>
                                <li>Compare fractions with like and unlike denominators (VI-G.7)</li>
                                <li>Compare fractions: word problems (VI-G.8)</li>
                                <li>Convert between improper fractions and mixed numbers (VI-G.9)</li>
                                <li>Convert between decimals and fractions or mixed numbers (VI-G.10)</li>
                                <li>Put a mix of decimals, fractions and mixed numbers in order (VI-G.11)</li>
                                <li>Add and subtract fractions with like denominators (VI-H.1)</li>
                                <li>Add and subtract fractions with like denominators: word problems (VI-H.2)</li>
                                <li>Add and subtract fractions with unlike denominators (VI-H.3)</li>
                                <li>Add and subtract fractions with unlike denominators: word problems (VI-H.4)</li>
                                <li>Inequalities with addition and subtraction of like and unlike fractions (VI-H.5)</li>
                                <li>Add and subtract mixed numbers (VI-H.6)</li>
                                <li>Add and subtract mixed numbers: word problems (VI-H.7)</li>
                                <li>Estimate sums and differences of mixed numbers (VI-H.8)</li>
                                <li>Add and subtract decimals or fractions (VI-K.4)</li>
                                <li>Add and subtract decimals or fractions: word problems (VI-K.5)</li>
                                <li>Add and subtract money amounts (VI-O.2)</li>
                                <li>Add and subtract money amounts: word problems (VI-O.3)</li>

                            </ul>
                        </li>
                    </div>



                    <h3 style={{ color: "red" }}>6.A Algebra</h3>

                    <h6>6.A.A Introduction to Algebra</h6>
                    <li><h6>6.A.A.1 Introduction to variable through patterns and through appropriate word problems and generalisations (example 5 × 1 = 5 etc.)</h6>

                        <ul style={{ color: "green" }}>
                            <li>Add and subtract integers: input/output tables (VI-J.6)</li>
                            <li>Solve for a variable using properties of multiplication (VI-Q.8)</li>
                        </ul>
                    </li>

                    <li><h6>6.A.A.2 Generate such patterns with more examples.</h6></li>
                    <ul style={{ color: "green" }}>
                        <li>Arithmetic sequences (VII-O.2)</li>
                        <li>Geometric sequences (VII-O.3)</li>
                        <li>Number sequences: mixed review (VII-O.4)</li>
                        <li>Number sequences: word problems (VII-O.5)</li>
                    </ul>

                    <li><h6>6.A.A.3 Introduction to unknowns through examples with simple contexts (single operations).</h6></li>
                    <ul style={{ color: "green" }}>
                        <li>Write variable equations: word problems (VI-R.1)</li>

                    </ul>


                    <h3 style={{ color: "red" }}>6.RP Ratio and Proportion</h3>
                    <li><h6>6.RP.1 Concept of Ratio</h6>
                        <ul style={{ color: 'green' }}>
                            <li>Write a ratio (VI-M.1)</li>
                            <li>Ratio tables (VI-M.5)</li>
                        </ul>
                    </li>

                    <li><h6>6.RP.2 Proportion as equality of two ratios.</h6>
                        <ul style={{ color: "green" }}>
                            <li>Identify equivalent ratios (VI-M.3)</li>
                            <li>Write an equivalent ratio (VI-M.4)</li>
                            <li>Do the ratios form a proportion? (VI-M.8)</li>
                            <li>Solve the proportion (VI-M.9)</li>

                        </ul>
                    </li>

                    <li><h6>6.RP.3 Unitary method (with only direct variation implied)</h6>
                        <ul style={{ color: "green" }}>
                            <li>Unit rates and equivalent rates (VI-M.6)</li>
                        </ul>
                    </li>

                    <li><h6>6.RP.4 Word problems</h6>
                        <ul style={{ color: "green" }}>
                            <li>Write a ratio: word problems (VI-M.2)</li>
                            <li>Compare ratios: word problems (VI-M.7)</li>
                            <li>Scale drawings: word problems (VI-M.10)</li>
                        </ul>
                    </li>

                    <h3 style={{ color: "red" }}>6.G Geometry</h3>
                    <h6>6.G.i Introduction to geometry. Its linkage with and reflection in everyday experience.</h6>
                    <li><h6>6.G.i.1 Line, line segment, ray.</h6>
                        <ul style={{ color: "green" }}>
                            <li>Lines, line segments and rays (VI-S.11)</li>
                        </ul>
                    </li>

                    <li><h6>6.G.i.2 Open and closed figures.</h6>
                        <ul style={{ color: "green" }}>
                            <li>Is it a polygon? (VI-S.1)</li>
                        </ul>
                    </li>

                    <h6>6.G.i.3 Interior and exterior of closed figures.</h6>
                    <h6>6.G.i.4 Curvilinear and linear boundaries</h6>
                    <h6>6.G.i.5 Angle — Vertex, arm, interior and exterior,</h6>
                    <h6>6.G.i.6 Triangle — vertices, sides, angles, interior and exterior, altitude and median</h6>
                    <h6>6.G.i.7 Quadrilateral — Sides, vertices, angles, diagonals, adjacent sides and opposite sides (only convex quadrilateral are to be discussed), interior and exterior of a quadrilateral.</h6>
                    <h6>6.G.i.7 Quadrilateral — Sides, vertices, angles, diagonals, adjacent sides and opposite sides (only convex quadrilateral are to be discussed), interior and exterior of a quadrilateral.</h6>
                    <ul style={{ color: "green" }}>
                        <li>Parts of a circle (VI-S.13)</li>
                    </ul>


                    <h6>6.G.ii Understanding Elementary Shapes (2-D and 3-D):.</h6>
                    <h6>6.G.ii.1 Measure of Line segment</h6>
                    <h6>6.G.ii.2 Measure of angles</h6>
                    <ul style={{ color: "green" }}>
                        <li>Measure angles with a protractor (VI-S.3)</li>
                    </ul>


                    <h6>6.G.ii.3 Pair of lines</h6>
                    <h6>6.G.ii.3.a Intersecting and perpendicular lines</h6>
                    <ul style={{ color: "green" }}>
                        <li>Parallel, perpendicular and intersecting lines (VI-S.12)</li>
                    </ul>

                    <h6>6.G.ii.3.b Parallel lines</h6>
                    <ul style={{ color: "green" }}>
                        <li>Parallel, perpendicular and intersecting lines (VI-S.12)</li>
                    </ul>

                    <h6>6.G.ii.4 Types of angles  acute, obtuse, right, straight, reflex, complete and zero angle</h6>
                    <ul style={{ color: "green" }}>
                        <li>Types of angles (VI-S.2)</li>
                    </ul>

                    <h6>6.G.ii.5 Classification of triangles (on the basis of sides, and of angles)</h6>
                    <ul style={{ color: "green" }}>
                        <li>Classify triangles (VI-S.6)</li>
                    </ul>

                    <h6>6.G.ii.6 Types of quadrilaterals Trapezium, parallelogram, rectangle, square, rhombus.</h6>
                    <ul style={{ color: "green" }}>
                        <li>Identify trapeziums (VI-S.7)</li>
                        <li>Classify quadrilaterals (VI-S.8)</li>
                    </ul>

                    <h6>6.G.ii.7 Simple polygons (introduction) (Upto octagons regulars as well as non regular).</h6>
                    <ul style={{ color: "green" }}>
                        <li>Is it a polygon? (VI-S.1)</li>
                        <li>Regular and irregular polygons (VI-S.4)</li>
                        <li>Number of sides in polygons (VI-S.5)</li>
                        <li>Which figure is being described? (VI-V.3)</li>
                    </ul>


                    <h6>6.G.ii.8 Identification of 3-D shapes: Cubes, Cuboids, cylinder, sphere, cone, prism (triangular), pyramid (triangular and square) Identification and locating in the surroundings6.G.ii.7 Simple polygons (introduction) (Upto octagons regulars as well as non regular).</h6>
                    <ul style={{ color: "green" }}>
                        <li>Identify three-dimensional figures (VI-V.1)</li>
                        <li>Three-dimensional figures viewed from different perspectives (VI-V.5)</li>
                    </ul>

                    <h6>6.G.ii.9 Elements of 3-D figures. (Faces, Edges and vertices)</h6>
                    <ul style={{ color: "green" }}>
                        <li>Count vertices, edges and faces (VI-V.2)</li>
                    </ul>

                    <h6>6.G.ii.10 Nets for cube, cuboids, cylinders, cones and tetrahedrons.</h6>
                    <ul style={{ color: "green" }}>
                        <li>Nets of three-dimensional figures (VI-V.4)</li>
                    </ul>


                    <h6>6.G.iii Symmetry: (reflection)</h6>
                    <h6>6.G.iii.1 Observation and identification of 2-D symmetrical objects for reflection symmetrys</h6>
                    <ul style={{ color: "green" }}>
                        <li>Lines of symmetry (VI-T.1)</li>
                    </ul>

                    <h6>6.G.iii.2 Operation of reflection (taking mirror images) of simple 2-D objects</h6>
                    <h6>6.G.iii.3 Recognising reflection symmetry (identifying axes)</h6>
                    <ul style={{ color: "green" }}>
                        <li>Lines of symmetry (VI-T.1)</li>
                    </ul>


                    <h6>6.G.iv Constructions (using Straight edge Scale, protractor, compasses)</h6>
                    <h6>6.G.iv.1 Drawing of a line segment</h6>
                    <h6>6.G.iv.2 Construction of circle</h6>
                    <h6>6.G.iv.3 Perpendicular bisector</h6>
                    <ul style={{ color: "green" }}>
                        <li>Construct the midpoint or perpendicular bisector of a segment (VI-U.1)</li>
                    </ul>

                    <h6>6.G.iv.4 Construction of angles (using protractor)</h6>
                    <ul style={{ color: "green" }}>
                        <li>Measure angles with a protractor (VI-S.3)</li>
                    </ul>


                    <h6>6.G.iv.5 Angle 60°, 120° (Using Compasses)</h6>
                    <h6>6.G.iv.6 Angle bisector – making angles of 30°, 45°, 90° etc. (using compasses)</h6>
                    <ul style={{ color: "green" }}>
                        <li>Construct an angle bisector (VI-U.2)</li>
                    </ul>

                    <h6>6.G.iv.7 Angle equal to a given angle (using compass)</h6>
                    <h6>6.G.iv.8 Drawing a line perpendicular to a given line from a point a) on the line b) outside the line.</h6>
                    <ul style={{ color: "green" }}>
                        <li>Construct a perpendicular line (VI-U.3)</li>
                    </ul>


                    <h3 style={{ color: "red" }}>6.MEN Mensuration</h3>
                    <h6>6.MEN. Concept of Perimeter and Introduction to Area</h6>
                    <li><h6>6.MEN.1 Introduction and general understanding of perimeter using many shapes. Shapes of different kinds with the same perimeter. Concept of area, Area of a rectangle and a square Counter examples to different misconcepts related to perimeter and area.</h6>
                        <ul style={{ color: "green" }}>
                            <li>Perimeter: find the missing side lengths (V-P.2)</li>
                            <li>Perimeter (VI-W.1)</li>
                            <li>Area of squares and rectangles (VI-W.2)</li>
                            <li>Area of compound figures (VI-W.3)</li>
                            <li>Area between two rectangles (VI-W.4)</li>
                            <li>Area and perimeter of figures on grids (VI-W.5)</li>
                        </ul>
                    </li>

                    <h6>6.MEN.2 Perimeter of a rectangle – and its special case – a square. Deducing the formula of the perimeter for a rectangle and then a square through pattern and generalisation.</h6>
                    <ul style={{ color: "green" }}>
                        <li>Perimeter (VI-W.1)</li>
                        <li>Area and perimeter: word problems (VI-W.6)</li>
                        <li>Use area and perimeter to determine cost (VI-W.7)</li>
                    </ul>

                    <h3 style={{ color: "red" }}>6.DH Data handling</h3>
                    <h6>6.DH.i What is data – choosing data to examine a hypothesis?</h6>
                    <li><h6>6.DH.ii Collection and organisation of data – examples of organising it in tally bars and a table.</h6>
                        <ul style={{ color: "green" }}>
                            <li>Create frequency tables (VI-X.7)</li>
                        </ul>
                    </li>

                    <h6>6.DH.iii Pictograph – Need for scaling in pictographs interpretation & construction.</h6>
                    <ul style={{ color: "green" }}>
                        <li>Perimeter (VI-W.1)</li>
                        <li>Interpret pictographs (VI-X.1)</li>
                        <li>Create pictographs (VI-X.2)</li>
                    </ul>


                    <h6>6.DH.iv Making bar graphs for given data interpreting bar graphs.</h6>
                    <ul style={{ color: "green" }}>
                        <li>Interpret bar graphs (VI-X.8)</li>
                        <li>Create bar graphs (VI-X.9)</li>
                        <li>Create histograms (VI-X.11)</li>
                    </ul>


                </ul>
            </div>

        </div>
    )
}

export default Class6
